import { UserRoleName } from 'gen/graphql-type'
import {
  User_ExaminationPolicy_ShowFragment,
  User_ExaminationPolicy_ShowMedicalRecordFragment,
  User_ExaminationPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canShow: Authorize<User_ExaminationPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Pharmacist,
    UserRoleName.SystemOperator,
    UserRoleName.Operator,
    UserRoleName.DmhOperator,
  ])
}

export const canShowSidebar: Authorize<User_ExaminationPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Pharmacist,
    UserRoleName.SystemOperator,
    UserRoleName.Operator,
  ])
}

export const canShowMedicalRecord: Authorize<
  User_ExaminationPolicy_ShowMedicalRecordFragment
> = user => {
  return authorize(user, [UserRoleName.Pharmacist])
}
