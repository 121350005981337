import React from 'react'
import { INVOICE_SHIPMENT_LIST } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebarShipmentList } from 'policies/InvoicePolicy'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'
import { useRouter } from 'next/router'

type Props = ReturnType<typeof useQuery>

export const InvoiceShipmentMenuItem: React.FC<Props> = ({ viewer }) => {
  const { asPath } = useRouter()
  const isActive = asPath === INVOICE_SHIPMENT_LIST

  return (
    <SectionGrant authorized={canShowSidebarShipmentList(viewer)}>
      <MenuItem href={INVOICE_SHIPMENT_LIST} isActive={isActive}>
        配送管理
      </MenuItem>
    </SectionGrant>
  )
}
