import { UserRoleName, RoleGroup } from 'gen/graphql-type'
import {
  User_AdminPolicy_CreateFragment,
  User_AdminPolicy_UpdateFragment,
  User_AdminPolicy_DestroyFragment,
  User_AdminPolicy_DetachRoleFragment,
  Admin_AdminPolicy_DetachRole_DataFragment,
  User_AdminPolicy_ShowFragment,
  User_AdminPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canCreate: Authorize<User_AdminPolicy_CreateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator])
}

export const canUpdate: Authorize<User_AdminPolicy_UpdateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator])
}

export const canDestroy: Authorize<User_AdminPolicy_DestroyFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator])
}

export const canShow: Authorize<User_AdminPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowSidebar: Authorize<User_AdminPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canDetachRole: Authorize<
  User_AdminPolicy_DetachRoleFragment,
  Admin_AdminPolicy_DetachRole_DataFragment | undefined
> = (user, admin) => {
  if (!user || !admin) return false

  const isUserSystemOperator = user.allRoles
    .map(item => item.name)
    .includes(UserRoleName.SystemOperator)
  const hasAdminRoles =
    admin.allRoles.filter(item => item.role.group === RoleGroup.Admin).length > 1

  return isUserSystemOperator && hasAdminRoles
}
