import React from 'react'
import { Box, Stack, Heading, Divider } from '@chakra-ui/react'
import { HOME } from 'constants/path'
import { canShow as canShowInquiryCategory } from 'policies/InquiryCategoryPolicy'
import { canShow as canShowZendeskTicket } from 'policies/ZendeskTicket'
import MenuItem from './MenuItem'
import SignOutButton from './SignOutButton'
import { useQuery } from './useQuery'
import { AdminMenuItem } from './AdminMenuItem/AdminMenuItem'
import { CouponMenuItem } from './CouponMenuItem/CouponMenuItem'
import { DoctorMenuItem } from './DoctorMenuItem/DoctorMenuItem'
import { CustomerMenuItem } from './CustomerMenuItem/CustomerMenuItem'
import { InvoiceMenuItem } from './InvoiceMenuItem/InvoiceMenuItem'
import { ExaminationMenuItem } from './ExaminationMenuItem/ExaminationMenuItem'
import { ExaminationTerminationReasonMenuItem } from './ExaminationTerminationReasonMenuItem/ExaminationTerminatingReasonMenuItem'
import { MedicineCategoryMenuItem } from './MedicineCategoryMenuItem/MedicineCategoryMenuItem'
import { InterviewSheetMenuItem } from './InterviewSheetMenuItem/InterviewSheetMenuItem'
import { InquiryCategoryMenuItem } from './InquiryCategoryMenuItem/InquiryCategoryMenuItem'
import { TicketsCreationMenuItem } from './TicketsCreationMenuItem/TicketsCreationMenuItem'
import { ExaminationAppointmentMenuItem } from './ExaminationAppointmentMenuItem/ExaminationAppointmentMenuItem'
import { ExaminationAppointmentSearchMenuItem } from './ExaminationAppointmentSearchMenuItem/ExaminationAppointmentSearchMenuItem'
import { InvoiceShipmentCsvItem } from './InvoiceShipmentCsvItem/InvoiceShipmentCsvItem'
import { InvoiceShipmentMenuItem } from './InvoiceShipmentMenuItem/InvoiceShipmentMenuItem'
import { DOCWmsOrderErrorCsvFileMenuItem } from './DOCWmsOrderErrorCsvFileMenuItem/DOCWmsOrderErrorCsvFileMenuItem'

const Component = () => {
  const { viewer } = useQuery()

  return (
    <Box
      position="sticky"
      top="0"
      height="100vh"
      width="15%"
      py={8}
      px={5}
      bg="blue.600"
      overflowY="auto"
    >
      <Heading as="h1" textAlign="center" size="md" fontWeight="extrabold" color="white" mb="10">
        Smaluna for Admin
      </Heading>
      <Stack spacing={3}>
        <MenuItem href={HOME}>ホーム</MenuItem>
        <AdminMenuItem viewer={viewer} />
        <DoctorMenuItem viewer={viewer} />
        <CustomerMenuItem viewer={viewer} />
        <InvoiceMenuItem viewer={viewer} />
        <InvoiceShipmentMenuItem viewer={viewer} />
        <ExaminationMenuItem viewer={viewer} />
        <MedicineCategoryMenuItem viewer={viewer} />
        <InterviewSheetMenuItem viewer={viewer} />
        <CouponMenuItem viewer={viewer} />
        <ExaminationTerminationReasonMenuItem viewer={viewer} />
      </Stack>
      <Divider my={6} />
      {/* MEMO: お問い合わせカテゴリ一覧の閲覧とZendeskのチケット作成ができるアカウントのみ以下のエリアを表示する */}
      {(canShowInquiryCategory(viewer) || canShowZendeskTicket(viewer)) && (
        <>
          <Stack spacing={3}>
            <InquiryCategoryMenuItem viewer={viewer} />
            <TicketsCreationMenuItem viewer={viewer} />
          </Stack>
          <Divider my={6} />
        </>
      )}
      <Stack spacing={3}>
        <ExaminationAppointmentMenuItem viewer={viewer} />
        <ExaminationAppointmentSearchMenuItem viewer={viewer} />
        <InvoiceShipmentCsvItem viewer={viewer} />
        <DOCWmsOrderErrorCsvFileMenuItem viewer={viewer} />
      </Stack>
      <Divider my={6} />
      <SignOutButton />
    </Box>
  )
}

export default Component
