import { UserRoleName } from 'gen/graphql-type'
import {
  User_InterviewSheetPolicy_ShowFragment,
  User_InterviewSheetPolicy_ShowSidebarFragment,
} from './fragment.gen-graphql'
import { authorize, Authorize } from '../authorize'

export const canShow: Authorize<User_InterviewSheetPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowSidebar: Authorize<User_InterviewSheetPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}
