import React from 'react'
import { TICKETS_CREATION } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebar } from 'policies/ZendeskTicket'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'

type Props = ReturnType<typeof useQuery>

export const TicketsCreationMenuItem: React.FC<Props> = ({ viewer }) => {
  return (
    <SectionGrant authorized={canShowSidebar(viewer)}>
      <MenuItem href={TICKETS_CREATION}>Zendeskチケット作成</MenuItem>
    </SectionGrant>
  )
}
