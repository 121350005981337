import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_ExaminationPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_ExaminationPolicy_ShowMedicalRecordFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_ExaminationPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_ExaminationPolicy_ShowFragmentDoc = gql`
    fragment User_ExaminationPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_ExaminationPolicy_ShowMedicalRecordFragmentDoc = gql`
    fragment User_ExaminationPolicy_ShowMedicalRecord on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_ExaminationPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_ExaminationPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;