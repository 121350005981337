import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_ExaminationAppointmentPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_ExaminationAppointmentPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_ExaminationAppointmentPolicy_ShowFragmentDoc = gql`
    fragment User_ExaminationAppointmentPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_ExaminationAppointmentPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_ExaminationAppointmentPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;