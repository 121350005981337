import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_ExaminationTerminationReasonPolicy_CreateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_ExaminationTerminationReasonPolicy_UpdateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_ExaminationTerminationReasonPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_ExaminationTerminationReasonPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_ExaminationTerminationReasonPolicy_CreateFragmentDoc = gql`
    fragment User_ExaminationTerminationReasonPolicy_Create on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_ExaminationTerminationReasonPolicy_UpdateFragmentDoc = gql`
    fragment User_ExaminationTerminationReasonPolicy_Update on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_ExaminationTerminationReasonPolicy_ShowFragmentDoc = gql`
    fragment User_ExaminationTerminationReasonPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_ExaminationTerminationReasonPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_ExaminationTerminationReasonPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;