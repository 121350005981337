export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A valid email, transported as a string */
  Email: { input: string; output: string; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
  /** Represents untyped JSON */
  JSON: { input: unknown; output: unknown; }
  /** A valid phone number, transported as a string */
  PhoneNumber: { input: string; output: string; }
  /** A valid postal code, transported as a string */
  PostalCode: { input: string; output: string; }
  /** A valid time, transported as a string. ex: 10:00 */
  TimeString: { input: string; output: string; }
  Upload: { input: File; output: File; }
  /** A valid URL, transported as a string */
  Url: { input: string; output: string; }
};

export type AccountDeletionSurvey = {
  /** 回答に従い分岐を考慮した質問リスト */
  allInteractiveQuestions: Array<QuestionnaireQuestion>;
  allQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  questions: QuestionnaireQuestionConnection;
  responder: Customer;
  state: QuestionnaireRespondentState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type AccountDeletionSurveyAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountDeletionSurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum AccountDeletionSurveyProblemType {
  AvoidSideEffects = 'AVOID_SIDE_EFFECTS',
  ChangeOfflineService = 'CHANGE_OFFLINE_SERVICE',
  ChangeOnlineService = 'CHANGE_ONLINE_SERVICE',
  Contraindications = 'CONTRAINDICATIONS',
  Dissatisfied = 'DISSATISFIED',
  DoctorUnsuitable = 'DOCTOR_UNSUITABLE',
  ForgetToTakeMedicine = 'FORGET_TO_TAKE_MEDICINE',
  Ineffective = 'INEFFECTIVE',
  IneffectiveMenstrualSymptoms = 'INEFFECTIVE_MENSTRUAL_SYMPTOMS',
  IneffectiveSkinProblems = 'INEFFECTIVE_SKIN_PROBLEMS',
  LeftoverMedicine = 'LEFTOVER_MEDICINE',
  MismatchBetweenSupplyAndDemand = 'MISMATCH_BETWEEN_SUPPLY_AND_DEMAND',
  MonetaryBurden = 'MONETARY_BURDEN',
  NoLongerNeed = 'NO_LONGER_NEED',
  OtherReason = 'OTHER_REASON',
  Stabilized = 'STABILIZED',
  TemporaryUse = 'TEMPORARY_USE',
  UseOfflineServiceTemporarily = 'USE_OFFLINE_SERVICE_TEMPORARILY'
}

export type ActiveStorageFile = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['Url']['output'];
};

/** The connection type for ActiveStorageFile. */
export type ActiveStorageFileConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ActiveStorageFileEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ActiveStorageFile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActiveStorageFileEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ActiveStorageFile>;
};

export type ActiveStorageImage = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  url: ImageUrlVariants;
};

/** The connection type for ActiveStorageImage. */
export type ActiveStorageImageConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ActiveStorageImageEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ActiveStorageImage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActiveStorageImageEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ActiveStorageImage>;
};

export type Address = {
  building: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 住所クレンジング結果レベル */
  geocodingLevel: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  postalCode: Scalars['PostalCode']['output'];
  prefecture: Scalars['String']['output'];
  town: Scalars['String']['output'];
  /** 住所クレンジング容認期限 */
  verificationIgnoreUntilAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 住所クレンジングをスキップするかどうか */
  verificationIgnored: Scalars['Boolean']['output'];
};

export type AddressVerificationFeature = {
  geometry: Maybe<AddressVerificationGeometry>;
  properties: AddressVerificationProperty;
  /** Feature */
  type: Scalars['String']['output'];
};

export type AddressVerificationGeometry = {
  /** [経度,緯度] */
  coordinates: Array<Scalars['Float']['output']>;
  /** Point */
  type: Scalars['String']['output'];
};

export type AddressVerificationProperty = {
  /** 町域名 */
  area: Scalars['String']['output'];
  /** 町域名読み */
  areaKana: Scalars['String']['output'];
  /** 番地・号 */
  banchiGo: Scalars['String']['output'];
  /** 建物名称部 */
  building: Scalars['String']['output'];
  /** 建物数字部 */
  buildingNumber: Scalars['String']['output'];
  /** 市区町村名 */
  city: Scalars['String']['output'];
  /** 市区町村名読み */
  cityKana: Scalars['String']['output'];
  /** 解析レベル */
  geocodingLevel: Scalars['Int']['output'];
  /** 解析レベル説明 */
  geocodingLevelDesc: Scalars['String']['output'];
  /** 小字・丁目 */
  koazaChome: Scalars['String']['output'];
  /** 小字・丁目読み */
  koazaChomeKana: Scalars['String']['output'];
  /** 解析ログメッセージ */
  log: Scalars['String']['output'];
  /** 正規化できなかった文字列 */
  notNormalized: Scalars['String']['output'];
  /** 正規化後の住所文字列 */
  placeName: Scalars['String']['output'];
  /** 都道府県名 */
  pref: Scalars['String']['output'];
  /** 都道府県読み */
  prefKana: Scalars['String']['output'];
  /** 入力住所文字列 */
  query: Scalars['String']['output'];
  /** 郵便番号 */
  zipcode: Scalars['String']['output'];
};

export type Admin = Userable & {
  accessTokens: UserAccessTokenConnection;
  agreedServiceContracts: ServiceContractConnection;
  allRoles: Array<UserRole>;
  annotation: Maybe<Scalars['String']['output']>;
  appsflyerIds: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentIdentification: Maybe<Identification>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['Email']['output'];
  emailVerifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 認証情報としてのパスワードを保持している場合 true */
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identificationState: UserIdentificationState;
  /** 招待コード */
  invitationCode: Maybe<Scalars['String']['output']>;
  isAgreedLatestServiceContracts: UserLatestServiceContractAgreementMap;
  kyc: Maybe<UserKyc>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  profile: Maybe<Profile>;
  pushNotifications: PushNotificationConnection;
  roles: UserRoleConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  utmParameter: Maybe<UserUtmParameter>;
  zendeskUser: Maybe<ZendeskUser>;
  zoomAuthCredential: Maybe<UserZoomAuthCredential>;
  zoomSecret: Maybe<UserZoomSecret>;
};


export type AdminAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminAgreedServiceContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminIsAgreedLatestServiceContractsArgs = {
  service: ServiceContractService;
};


export type AdminPushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AdminRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Admin. */
export type AdminConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AdminEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Admin>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AdminEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Admin>;
};

/** Autogenerated input type of AgreeAllServiceContracts */
export type AgreeAllServiceContractsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  service: ServiceContractService;
  /** 同意ユーザーID */
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of AgreeServiceContract */
export type AgreeServiceContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractType: ServiceContractType;
  service: ServiceContractService;
  /** 同意ユーザーID */
  userId: Scalars['ID']['input'];
};

export type AnnouncementBanner = {
  alt: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  image: MicroCmsImage;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  revisedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['Url']['output'];
};

export type AnnouncementBannersPayload = {
  contents: Array<AnnouncementBanner>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of ApprovePrescription */
export type ApprovePrescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Prescription.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of AssignDoctorMonitoringTime */
export type AssignDoctorMonitoringTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  doctorMonitoringTimeId: Scalars['ID']['input'];
};

export type AtoneCustomer = {
  /** 決済認証トークン */
  authenticationToken: Scalars['String']['output'];
  customer: Customer;
  id: Scalars['ID']['output'];
  /** サービス種別 */
  serviceType: Scalars['String']['output'];
};

/** The connection type for AtoneCustomer. */
export type AtoneCustomerConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AtoneCustomerEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AtoneCustomer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AtoneCustomerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AtoneCustomer>;
};

export type AtoneTransaction = {
  /** 請求金額 */
  amount: Scalars['Int']['output'];
  /** 決済認証トークン */
  authenticationToken: Maybe<Scalars['String']['output']>;
  /** 与信結果 */
  authorizationResult: Maybe<Scalars['String']['output']>;
  /** UUID */
  id: Scalars['String']['output'];
  invoice: Invoice;
  /** 親取引かどうか */
  isParent: Scalars['Boolean']['output'];
  /** 与信結果NG理由 */
  ngReason: Maybe<Scalars['String']['output']>;
  /** 親取引ID */
  parentRawId: Maybe<Scalars['String']['output']>;
  /** atone取引ID */
  rawId: Maybe<Scalars['String']['output']>;
  /** サービス種別 */
  serviceType: Scalars['String']['output'];
  state: AtoneTransactionState;
  /** 取引オプション */
  transactionOptions: Maybe<Scalars['String']['output']>;
};

/** The connection type for AtoneTransaction. */
export type AtoneTransactionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AtoneTransactionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<AtoneTransaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AtoneTransactionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<AtoneTransaction>;
};

export enum AtoneTransactionState {
  Authorized = 'AUTHORIZED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Settled = 'SETTLED'
}

/** Autogenerated input type of AttachAdminRole */
export type AttachAdminRoleInput = {
  adminId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleName: UserRoleName;
};

/** Autogenerated input type of AttachAppsflyerIdToUser */
export type AttachAppsflyerIdToUserInput = {
  appsflyerId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of AttachAppsflyerIdToUser. */
export type AttachAppsflyerIdToUserPayload = {
  appsflyerId: Scalars['String']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  user: User;
};

/** Autogenerated input type of AttachCertificateOfTelemedTraining */
export type AttachCertificateOfTelemedTrainingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};

/** Autogenerated input type of AttachDoctorRole */
export type AttachDoctorRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  roleName: UserRoleName;
};

/** Autogenerated input type of AttachInquiryCategoryImage */
export type AttachInquiryCategoryImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image: Scalars['Upload']['input'];
};

/** Autogenerated input type of AttachMedicineImage */
export type AttachMedicineImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image: Scalars['Upload']['input'];
};

/** Autogenerated input type of AttachProfileImage */
export type AttachProfileImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Profile.id */
  id: Scalars['ID']['input'];
  image: Scalars['Upload']['input'];
};

/** Autogenerated input type of AttachUserZoomAuthCredential */
export type AttachUserZoomAuthCredentialInput = {
  accountId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clientSecret: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of AttachUserZoomAuthCredential. */
export type AttachUserZoomAuthCredentialPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  user: User;
};

/** Autogenerated input type of AttachUserZoomSecret */
export type AttachUserZoomSecretInput = {
  apiKey: Scalars['String']['input'];
  apiSecret: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of AttachZendeskUserTags */
export type AttachZendeskUserTagsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Zendesk user tags */
  tags: Array<Scalars['String']['input']>;
  /** Userable.id */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of AttachZendeskUserTags. */
export type AttachZendeskUserTagsPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
};

export enum AttendanceState {
  Absent = 'ABSENT',
  Attended = 'ATTENDED',
  Joined = 'JOINED',
  Waiting = 'WAITING'
}

export type Audit = {
  /** 営業所住所 */
  agencyAddress: Scalars['String']['output'];
  /** 営業所名 */
  agencyName: Scalars['String']['output'];
  /** 営業所郵便番号 */
  agencyPostalCode: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 薬剤(製品)の有効期限 */
  expirationDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  lineItem: LineItem;
  /** 薬剤(製品)のロット番号 */
  lotNumber: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Audit. */
export type AuditConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AuditEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Audit>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AuditEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Audit>;
};

/** Autogenerated input type of AuditInvoice */
export type AuditInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

export type AuthToken = {
  refreshToken: Scalars['String']['output'];
  refreshTokenExp: Scalars['Int']['output'];
  token: Scalars['String']['output'];
  tokenExp: Scalars['Int']['output'];
  user: Userable;
  userId: Scalars['ID']['output'];
};

/** Autogenerated input type of AuthenticateUserWithEmailPassword */
export type AuthenticateUserWithEmailPasswordInput = {
  clientIdentificationKey?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  refreshTokenExp?: InputMaybe<Scalars['Int']['input']>;
  /** ユーザータイプ */
  role: UserType;
  tokenExp?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of BuildCouponConditionDescription */
export type BuildCouponConditionDescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  couponConditions: Array<CouponConditionInput>;
};

/** Autogenerated return type of BuildCouponConditionDescription. */
export type BuildCouponConditionDescriptionPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  conditionDescription: Scalars['String']['output'];
};

/** Autogenerated input type of BulkCreateBlockedWord */
export type BulkCreateBlockedWordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  words: Array<Scalars['String']['input']>;
};

/** Autogenerated input type of BulkShipInvoiceByCsv */
export type BulkShipInvoiceByCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CSVファイル（ヤマトスマートキャット、ヤマトB2） */
  csv: Scalars['Upload']['input'];
  /** CSVの種類 */
  csvType: InvoiceBulkShipByCsvJobCsvType;
};

/** Autogenerated return type of BulkShipInvoiceByCsv. */
export type BulkShipInvoiceByCsvPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  job: Maybe<InvoiceBulkShipByCsvJob>;
};

/** Autogenerated input type of CancelExaminationAppointment */
export type CancelExaminationAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelExaminationAppointment. */
export type CancelExaminationAppointmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
};

/** Autogenerated input type of CancelInvoice */
export type CancelInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelInvoice. */
export type CancelInvoicePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
  medicineSubscription: Maybe<MedicineSubscription>;
};

/** Autogenerated input type of CancelMedicineSubscription */
export type CancelMedicineSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 定期便ID（MedicineSubscription.id） */
  id: Scalars['ID']['input'];
};

export type CardContent = MediaPostContent & {
  body: Scalars['String']['output'];
  color: Array<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
};

/** Autogenerated input type of ChangeExaminationAppointmentSchedule */
export type ChangeExaminationAppointmentScheduleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentId: Scalars['ID']['input'];
  medicineCategoryKey?: InputMaybe<MedicineCategoryKey>;
  startsAt: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of ChangeExaminationAppointmentSchedule. */
export type ChangeExaminationAppointmentSchedulePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  interview: Maybe<Interview>;
};

/** Autogenerated input type of ChangeFrequentlyAskedQuestionPosition */
export type ChangeFrequentlyAskedQuestionPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  moveTo: PositionOperator;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of ChangeInquiryCategoryPosition */
export type ChangeInquiryCategoryPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  moveTo: PositionOperator;
  position?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of ChangeUserEmail */
export type ChangeUserEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ChangeUserEmail. */
export type ChangeUserEmailPayload = {
  beforeEmail: Scalars['Email']['output'];
  changedAt: Scalars['ISO8601DateTime']['output'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  /** User.id */
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of ChangeUserPassword */
export type ChangeUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User.id */
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of ChangeUserPassword. */
export type ChangeUserPasswordPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** User.id */
  id: Scalars['ID']['output'];
};

export type Clinic = {
  address: Maybe<ClinicAddress>;
  corporateName: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  doctors: DoctorConnection;
  fax: Maybe<ClinicPhone>;
  id: Scalars['ID']['output'];
  introduction: Maybe<Scalars['String']['output']>;
  legalName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Maybe<ClinicPhone>;
  representativeName: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ClinicDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ClinicAddress = Address & {
  building: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 住所クレンジング結果レベル */
  geocodingLevel: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  postalCode: Scalars['PostalCode']['output'];
  prefecture: Scalars['String']['output'];
  town: Scalars['String']['output'];
  /** 住所クレンジング容認期限 */
  verificationIgnoreUntilAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 住所クレンジングをスキップするかどうか */
  verificationIgnored: Scalars['Boolean']['output'];
};

/** The connection type for Clinic. */
export type ClinicConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ClinicEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Clinic>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClinicEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Clinic>;
};

export type ClinicPhone = Phone & {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['PhoneNumber']['output'];
};

/** Autogenerated input type of CloseMedicationDiary */
export type CloseMedicationDiaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type CommunityCategory = {
  /** UUID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CommunityQuestion = {
  answersCount: Scalars['Int']['output'];
  body: Scalars['String']['output'];
  category: CommunityCategory;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  tags: Array<CommunityTag>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: CommunityUser;
};

/** The connection type for CommunityQuestion. */
export type CommunityQuestionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CommunityQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CommunityQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommunityQuestionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CommunityQuestion>;
};

export type CommunityTag = {
  /** UUID */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CommunityUser = {
  iconUrl: Maybe<Scalars['Url']['output']>;
  /** UUID */
  id: Scalars['ID']['output'];
  nickname: Scalars['String']['output'];
};

export type Company = {
  /** 企業コード */
  code: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  /** 企業名 */
  name: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CompleteInterview */
export type CompleteInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Interview.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of CompleteReviewExaminationAppointmentOperation */
export type CompleteReviewExaminationAppointmentOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteReviewExaminationAppointmentOperation. */
export type CompleteReviewExaminationAppointmentOperationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

/** Autogenerated input type of CompleteRoutineCheckupInterview */
export type CompleteRoutineCheckupInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** RoutineCheckup::Interview.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteRoutineCheckupInterview. */
export type CompleteRoutineCheckupInterviewPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  routineCheckupInterview: RoutineCheckupInterview;
};

export type Coupon = {
  activationCodes: CouponActivationCodeConnection;
  /** クーポンの利用条件 */
  allCouponConditions: Array<CouponCondition>;
  /** クーポンの値引き対象 */
  allCouponDiscountTargets: Maybe<Array<CouponDiscountTarget>>;
  availableFrom: Scalars['ISO8601DateTime']['output'];
  /** 利用条件テキスト */
  conditionDescription: Maybe<Scalars['String']['output']>;
  /** クーポンの利用条件 */
  couponConditions: CouponConditionConnection;
  /** クーポンの値引き対象 */
  couponDiscountTargets: Maybe<CouponDiscountTargetConnection>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  discountType: CouponDiscountType;
  discountValue: Scalars['Int']['output'];
  expiresAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** クーポンが有効期限内かどうか */
  isAvailable: Scalars['Boolean']['output'];
  /** クーポンの識別キー。管理者のみ取得可能 */
  key: Maybe<CouponKey>;
  /** ユーザーが重複して取得できるクーポンの最大数（NULLで無制限） */
  limitPerUser: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  note: Maybe<Scalars['String']['output']>;
  /** クーポンの取得数。管理者のみ取得可能 */
  receivedCount: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** クーポンの使用回数。管理者のみ取得可能 */
  usedCount: Maybe<Scalars['Int']['output']>;
  /** クーポンを保有しているユーザー数。管理者のみ取得可能 */
  userCount: Maybe<Scalars['Int']['output']>;
};


export type CouponActivationCodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CouponCouponConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CouponCouponDiscountTargetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CouponActivationCode = {
  code: Maybe<Scalars['String']['output']>;
  coupon: Coupon;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  onlyOnce: Scalars['Boolean']['output'];
  /** 公開状態（PUBLIC：取得リンク生成可能、PRIVATE：取得リンク生成不可） */
  publicity: CouponActivationCodePublicity;
  publisher: User;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CouponActivationCode. */
export type CouponActivationCodeConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CouponActivationCodeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CouponActivationCode>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CouponActivationCodeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CouponActivationCode>;
};

export enum CouponActivationCodePublicity {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CouponCondition = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 利用条件キー */
  key: CouponConditionDefinitionKey;
  /** 利用条件演算子 */
  operator: CouponConditionDefinitionOperator;
  /** 要件（all/any） */
  requirements: CouponConditionRequirements;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** 利用条件値 */
  value: Scalars['String']['output'];
};

/** The connection type for CouponCondition. */
export type CouponConditionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CouponConditionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CouponCondition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CouponConditionDefinition = {
  /** 条件グループ */
  group: CouponConditionDefinitionGroup;
  /** 条件グループ名 */
  groupName: Scalars['String']['output'];
  /** 利用条件キー */
  key: CouponConditionDefinitionKey;
  /** 利用条件キー名 */
  keyName: Scalars['String']['output'];
  /** 利用可能な利用条件演算子のリスト */
  operators: Array<CouponConditionOperator>;
  /** 利用条件値の型（整数/単一選択） */
  valueInputType: CouponConditionDefinitionValueInputType;
  /** 利用条件値のリスト */
  values: Maybe<Array<CouponConditionValueObject>>;
};

/** The connection type for CouponConditionDefinition. */
export type CouponConditionDefinitionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CouponConditionDefinitionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CouponConditionDefinition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CouponConditionDefinitionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CouponConditionDefinition>;
};

export enum CouponConditionDefinitionGroup {
  /** 薬剤 */
  Medicine = 'MEDICINE',
  /** 薬剤カテゴリ */
  MedicineCategory = 'MEDICINE_CATEGORY',
  /** 定期便プラン */
  PaymentPlan = 'PAYMENT_PLAN',
  /** ユーザー条件 */
  UserStatus = 'USER_STATUS'
}

export enum CouponConditionDefinitionKey {
  /** 薬剤カテゴリ */
  MedicineCategoryId = 'MEDICINE_CATEGORY_ID',
  /** 薬剤 */
  MedicineId = 'MEDICINE_ID',
  /** 決済回数 */
  PaymentCount = 'PAYMENT_COUNT',
  /** 定期便プラン */
  PaymentPlanLotVolume = 'PAYMENT_PLAN_LOT_VOLUME'
}

export enum CouponConditionDefinitionOperator {
  /** = */
  Is = 'IS',
  /** ≠ */
  IsNot = 'IS_NOT'
}

export enum CouponConditionDefinitionValueInputType {
  /** 整数 */
  Integer = 'INTEGER',
  /** 単一選択 */
  SingleSelect = 'SINGLE_SELECT'
}

/** An edge in a connection. */
export type CouponConditionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CouponCondition>;
};

export type CouponConditionInput = {
  key: CouponConditionDefinitionKey;
  operator: CouponConditionDefinitionOperator;
  requirements: CouponConditionRequirements;
  value: Scalars['String']['input'];
};

export type CouponConditionOperator = {
  name: Scalars['String']['output'];
  value: CouponConditionDefinitionOperator;
};

export enum CouponConditionRequirements {
  /** 条件をすべて満たす */
  All = 'ALL',
  /** 条件をいずれか満たす */
  Any = 'ANY'
}

export type CouponConditionValueObject = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** The connection type for Coupon. */
export type CouponConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CouponEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Coupon>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CouponDiscountTarget = {
  /** クーポン */
  coupon: Maybe<Coupon>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 値引き対象グループ’ */
  group: CouponDiscountTargetGroup;
  id: Scalars['ID']['output'];
  /** 値引き対象キー */
  key: CouponDiscountTargetKey;
  /** 対象名 */
  keyName: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for CouponDiscountTarget. */
export type CouponDiscountTargetConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CouponDiscountTargetEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CouponDiscountTarget>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CouponDiscountTargetEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CouponDiscountTarget>;
};

export enum CouponDiscountTargetGroup {
  Category = 'CATEGORY',
  Fee = 'FEE'
}

export enum CouponDiscountTargetKey {
  EmergencyContraceptiveCategory = 'EMERGENCY_CONTRACEPTIVE_CATEGORY',
  LowDoseOralContraceptiveCategory = 'LOW_DOSE_ORAL_CONTRACEPTIVE_CATEGORY',
  MedicalDietCategory = 'MEDICAL_DIET_CATEGORY',
  MediumDoseOralContraceptiveCategory = 'MEDIUM_DOSE_ORAL_CONTRACEPTIVE_CATEGORY',
  NoCategory = 'NO_CATEGORY',
  PrescriptionFee = 'PRESCRIPTION_FEE',
  ShippingFee = 'SHIPPING_FEE'
}

export enum CouponDiscountType {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE'
}

/** An edge in a connection. */
export type CouponEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Coupon>;
};

export enum CouponKey {
  FirstPurchase = 'FIRST_PURCHASE',
  Invitee = 'INVITEE',
  InviteeMedicalDietCampaign_2024 = 'INVITEE_MEDICAL_DIET_CAMPAIGN_2024',
  Inviter = 'INVITER',
  InviterMedicalDietCampaign_2024 = 'INVITER_MEDICAL_DIET_CAMPAIGN_2024',
  RepeatPurchase = 'REPEAT_PURCHASE'
}

export type CouponSearch = {
  /** 指定したコードに紐づくクーポンを検索 */
  activationCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 指定日時以降の開始日時のクーポンを検索 */
  availableFromStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** 指定日時以前の終了日時のクーポンを検索 */
  expiresAtEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** 有効なクーポンのみを取得するかどうか */
  isAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** 無期限のクーポンのみを取得するかどうか */
  isUnlimitedExpiration?: InputMaybe<Scalars['Boolean']['input']>;
  /** 名前の部分一致検索 */
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of CreateAccountDeletionSurveyAnswer */
export type CreateAccountDeletionSurveyAnswerInput = {
  /** 退会アンケートID */
  accountDeletionSurveyId: Scalars['ID']['input'];
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAccountDeletionSurveyAnswer. */
export type CreateAccountDeletionSurveyAnswerPayload = {
  answer: QuestionnaireAnswer;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<QuestionnaireQuestion>;
  survey: AccountDeletionSurvey;
};

/** Autogenerated input type of CreateAccountDeletionSurvey */
export type CreateAccountDeletionSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 退会ユーザーID */
  customerId: Scalars['ID']['input'];
  /** @deprecated 新アンケートでは指定しないでください。 */
  problemType?: InputMaybe<AccountDeletionSurveyProblemType>;
};

/** Autogenerated input type of CreateAdmin */
export type CreateAdminInput = {
  /** true にするとAPI連携用のアカウントになります。 */
  apiOnly?: InputMaybe<Scalars['Boolean']['input']>;
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  familyNameKana: Scalars['String']['input'];
  gender: Gender;
  givenName: Scalars['String']['input'];
  givenNameKana: Scalars['String']['input'];
  redirectUrl: Scalars['Url']['input'];
  roles: Array<UserRoleName>;
};

/** Autogenerated input type of CreateAtoneTransaction */
export type CreateAtoneTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateAtoneTransaction. */
export type CreateAtoneTransactionPayload = {
  atoneTransaction: AtoneTransaction;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  paramsForModule: Scalars['JSON']['output'];
};

/** Autogenerated input type of CreateClinicDoctor */
export type CreateClinicDoctorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clinicId: Scalars['ID']['input'];
  doctorId: Scalars['ID']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateClinicDoctor. */
export type CreateClinicDoctorPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
};

/** Autogenerated input type of CreateClinic */
export type CreateClinicInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  corporateName: Scalars['String']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  prefecture: Scalars['String']['input'];
  representativeName: Scalars['String']['input'];
  town: Scalars['String']['input'];
};

/** Autogenerated input type of CreateCouponActivationCode */
export type CreateCouponActivationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  couponId: Scalars['ID']['input'];
  onlyOnce?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CreateCoupon */
export type CreateCouponInput = {
  availableFrom: Scalars['ISO8601DateTime']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  conditionDescription?: InputMaybe<Scalars['String']['input']>;
  couponConditions?: InputMaybe<Array<CouponConditionInput>>;
  discountType: CouponDiscountType;
  discountValue: Scalars['Int']['input'];
  expiresAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  isUnlimitedPerUser?: InputMaybe<Scalars['Boolean']['input']>;
  limitPerUser?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /** 管理者のみ閲覧可能な備考テキスト */
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateCustomerNote */
export type CreateCustomerNoteInput = {
  allergyMedicine?: InputMaybe<Scalars['Boolean']['input']>;
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  writerRole: UserNoteWriterRole;
};

/** Autogenerated input type of CreateCustomerNoteViaExamination */
export type CreateCustomerNoteViaExaminationInput = {
  allergyMedicine?: InputMaybe<Scalars['Boolean']['input']>;
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateCustomerNoteViaExamination. */
export type CreateCustomerNoteViaExaminationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customerNote: Maybe<CustomerNote>;
};

/** Autogenerated input type of CreateCustomerSupportFileMessage */
export type CreateCustomerSupportFileMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** customerSupportId == customerId */
  customerSupportId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};

/** Autogenerated input type of CreateCustomerSupportMessage */
export type CreateCustomerSupportMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** customerSupportId == customerId */
  customerSupportId: Scalars['ID']['input'];
  message: CustomerSupportMessageInput;
  senderType: CustomerSupportMessageSenderType;
};

/** Autogenerated input type of CreateCustomerSupportTextMessage */
export type CreateCustomerSupportTextMessageInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** customerSupportId == customerId */
  customerSupportId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateDeliveryTracking */
export type CreateDeliveryTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deliveryServiceId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  trackingNumber: Scalars['String']['input'];
};

/** Autogenerated input type of CreateDisease */
export type CreateDiseaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated input type of CreateDoctorHoliday */
export type CreateDoctorHolidayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  endsAt: Scalars['ISO8601DateTime']['input'];
  forced?: InputMaybe<Scalars['Boolean']['input']>;
  startsAt: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated input type of CreateDoctor */
export type CreateDoctorInput = {
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clinicId: Scalars['ID']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  familyNameKana: Scalars['String']['input'];
  gender: Gender;
  givenName: Scalars['String']['input'];
  givenNameKana: Scalars['String']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/** Autogenerated input type of CreateDoctorOperatingTime */
export type CreateDoctorOperatingTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  endsAt: Scalars['ISO8601DateTime']['input'];
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated return type of CreateDoctorOperatingTime. */
export type CreateDoctorOperatingTimePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
  doctorOperatingTime: DoctorOperatingTime;
};

/** Autogenerated input type of CreateDoctorWithClinic */
export type CreateDoctorWithClinicInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clinic: CreateClinicInput;
  doctor: CreateDoctorInput;
};

/** Autogenerated input type of CreateExaminationAppointment */
export type CreateExaminationAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 初診の予約枠を利用するかどうか */
  first?: InputMaybe<Scalars['Boolean']['input']>;
  medicineCategoryKey: MedicineCategoryKey;
  startsAt: Scalars['ISO8601DateTime']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateExaminationAppointment. */
export type CreateExaminationAppointmentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  interview: Interview;
};

/** Autogenerated input type of CreateExaminationAppointmentVideoCall */
export type CreateExaminationAppointmentVideoCallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentId: Scalars['ID']['input'];
  prescriptionInquiryId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateExaminationAppointmentVideoCall. */
export type CreateExaminationAppointmentVideoCallPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  videoCall: ExaminationAppointmentVideoCall;
};

/** Autogenerated input type of CreateExaminationFileMessage */
export type CreateExaminationFileMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
};

/** Autogenerated input type of CreateExaminationGuidance */
export type CreateExaminationGuidanceInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateExamination */
export type CreateExaminationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateExaminationMessage */
export type CreateExaminationMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
  message: ExaminationMessageInput;
  senderType: ExaminationMessageSelectableSenderType;
};

/** Autogenerated input type of CreateExaminationReviewAnswer */
export type CreateExaminationReviewAnswerInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationReviewId: Scalars['ID']['input'];
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateExaminationTerminationReason */
export type CreateExaminationTerminationReasonInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateExaminationTerminationReason. */
export type CreateExaminationTerminationReasonPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationTerminationReason: ExaminationTerminationReason;
};

/** Autogenerated input type of CreateExaminationTextMessage */
export type CreateExaminationTextMessageInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateExaminationWithMonitoringDoctor */
export type CreateExaminationWithMonitoringDoctorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateFrequentlyAskedQuestion */
export type CreateFrequentlyAskedQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inquiryCategoryId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  url: Scalars['Url']['input'];
};

/** Autogenerated input type of CreateInquiryCategory */
export type CreateInquiryCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  consultantType: InquiryCategoryConsultantType;
  explanation?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of CreateInterviewAddressAnswer */
export type CreateInterviewAddressAnswerInput = {
  address: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInterviewAddressAnswer. */
export type CreateInterviewAddressAnswerPayload = {
  answer: Maybe<InterviewAddressAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterviewAnswer */
export type CreateInterviewAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  selectionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateInterviewAnswer. */
export type CreateInterviewAnswerPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterviewBmiAnswer */
export type CreateInterviewBmiAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['String']['input'];
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  weight: Scalars['String']['input'];
};

/** Autogenerated return type of CreateInterviewBmiAnswer. */
export type CreateInterviewBmiAnswerPayload = {
  answer: Maybe<InterviewBmiAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterviewDateAnswer */
export type CreateInterviewDateAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['String']['input'];
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInterviewDateAnswer. */
export type CreateInterviewDateAnswerPayload = {
  answer: Maybe<InterviewDateAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterviewDateTimeAnswer */
export type CreateInterviewDateTimeAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dateTime: Scalars['String']['input'];
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInterviewDateTimeAnswer. */
export type CreateInterviewDateTimeAnswerPayload = {
  answer: Maybe<InterviewDateTimeAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterview */
export type CreateInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** medicineCategoryId or medicineCategoryKey is required. If both are passed, medicineCategoryId will take precedence. */
  medicineCategoryId?: InputMaybe<Scalars['ID']['input']>;
  /** medicineCategoryId or medicineCategoryKey is required. If both are passed, medicineCategoryId will take precedence. */
  medicineCategoryKey?: InputMaybe<MedicineCategoryKey>;
  medicineSubscriptionRenewalRecommendationId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateInterviewMultipleSelectAnswer */
export type CreateInterviewMultipleSelectAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  selections: Array<InterviewSelectAnswerSelection>;
};

/** Autogenerated return type of CreateInterviewMultipleSelectAnswer. */
export type CreateInterviewMultipleSelectAnswerPayload = {
  answer: Maybe<InterviewSelectAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterviewSingleSelectAnswer */
export type CreateInterviewSingleSelectAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  selection?: InputMaybe<InterviewSelectAnswerSelection>;
};

/** Autogenerated return type of CreateInterviewSingleSelectAnswer. */
export type CreateInterviewSingleSelectAnswerPayload = {
  /** 問診が中断されたかどうか */
  aborted: Maybe<Scalars['Boolean']['output']>;
  answer: Maybe<InterviewSelectAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInterviewTextAnswer */
export type CreateInterviewTextAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  interviewId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

/** Autogenerated return type of CreateInterviewTextAnswer. */
export type CreateInterviewTextAnswerPayload = {
  answer: Maybe<InterviewTextAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateInvoiceUserCoupon */
export type CreateInvoiceUserCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
  userCouponId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInvoiceUserCoupon. */
export type CreateInvoiceUserCouponPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
};

/** Autogenerated input type of CreateManyDeliveryTracking */
export type CreateManyDeliveryTrackingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: Array<DeliveryTrackingInput>;
};

/** Autogenerated input type of CreateManyZendeskTicketsByCsv */
export type CreateManyZendeskTicketsByCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** CSVファイル(user_id,subject,comment,tags) */
  csv: Scalars['Upload']['input'];
  /** ZendeskGroup.id */
  groupId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicalConsultationFileMessage */
export type CreateMedicalConsultationFileMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  /** medicalConsultationId == customerId */
  medicalConsultationId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicalConsultationMessage */
export type CreateMedicalConsultationMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** medicalConsultationId == customerId */
  medicalConsultationId: Scalars['ID']['input'];
  message: MedicalConsultationMessageInput;
  senderType: MedicalConsultationMessageSenderType;
};

/** Autogenerated input type of CreateMedicalConsultationTextMessage */
export type CreateMedicalConsultationTextMessageInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** medicalConsultationId == customerId */
  medicalConsultationId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicationDiary */
export type CreateMedicationDiaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  startsOn: Scalars['ISO8601Date']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicationDiaryRecord */
export type CreateMedicationDiaryRecordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosedOn: Scalars['ISO8601Date']['input'];
  feeling: MedicationDiaryRecordFeeling;
  medicationDiaryId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicineDescription */
export type CreateMedicineDescriptionInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  medicineId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated input type of CreateMedicineDocument */
export type CreateMedicineDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentUrl: Scalars['String']['input'];
  medicineId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicineRecommendationSurveyAnswer */
export type CreateMedicineRecommendationSurveyAnswerInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  medicineRecommendationSurveyId: Scalars['ID']['input'];
  /** [deprecated] Use `optionIds` instead. */
  optionId?: InputMaybe<Scalars['ID']['input']>;
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMedicineRecommendationSurveyAnswer. */
export type CreateMedicineRecommendationSurveyAnswerPayload = {
  answer: QuestionnaireAnswer;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  survey: MedicineRecommendationSurvey;
};

/** Autogenerated input type of CreateMedicineRecommendationSurvey */
export type CreateMedicineRecommendationSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  problemType: MedicineRecommendationSurveyProblemType;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateMedicineSubscriptionCancelSurveyAnswer */
export type CreateMedicineSubscriptionCancelSurveyAnswerInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  medicineSubscriptionCancelSurveyId: Scalars['ID']['input'];
  /** [deprecated] Use `optionIds` instead. */
  optionId?: InputMaybe<Scalars['ID']['input']>;
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMedicineSubscriptionCancelSurveyAnswer. */
export type CreateMedicineSubscriptionCancelSurveyAnswerPayload = {
  answer: QuestionnaireAnswer;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<QuestionnaireQuestion>;
  survey: MedicineSubscriptionCancelSurvey;
};

/** Autogenerated input type of CreateMedicineSubscriptionCancelSurvey */
export type CreateMedicineSubscriptionCancelSurveyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 解約対象の定期便ID */
  medicineSubscriptionId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated 新アンケートは定期便IDを指定してください。 */
  problemType?: InputMaybe<MedicineSubscriptionCancelSurveyProblemType>;
  /** 回答者（Userable.id） */
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreatePaymentSource */
export type CreatePaymentSourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Stripeが発行するカードトークンを指定します。 https://stripe.com/docs/api/tokens/create_card */
  sourceToken: Scalars['String']['input'];
};

/** Autogenerated input type of CreatePrescription */
export type CreatePrescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
  medicineVariantIds: Array<Scalars['ID']['input']>;
};

/** Autogenerated input type of CreatePrescriptionInquiry */
export type CreatePrescriptionInquiryInput = {
  body: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  prescriptionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePrescriptionInquiry. */
export type CreatePrescriptionInquiryPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  prescriptionInquiry: PrescriptionInquiry;
};

/** Autogenerated input type of CreateProfile */
export type CreateProfileInput = {
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyName: Scalars['String']['input'];
  familyNameKana: Scalars['String']['input'];
  gender: Gender;
  givenName: Scalars['String']['input'];
  givenNameKana: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateReminder */
export type CreateReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  remindsAt: Scalars['TimeString']['input'];
  type: ReminderType;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateRoutineCheckup */
export type CreateRoutineCheckupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  routineCheckupScheduleId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateRoutineCheckupInterviewBmiAnswer */
export type CreateRoutineCheckupInterviewBmiAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['String']['input'];
  questionId: Scalars['ID']['input'];
  routineCheckupInterviewId: Scalars['ID']['input'];
  weight: Scalars['String']['input'];
};

/** Autogenerated return type of CreateRoutineCheckupInterviewBmiAnswer. */
export type CreateRoutineCheckupInterviewBmiAnswerPayload = {
  answer: Maybe<RoutineCheckupInterviewBmiAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateRoutineCheckupInterviewMultipleSelectAnswer */
export type CreateRoutineCheckupInterviewMultipleSelectAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
  routineCheckupInterviewId: Scalars['ID']['input'];
  selections: Array<RoutineCheckupInterviewSelectAnswerSelection>;
};

/** Autogenerated return type of CreateRoutineCheckupInterviewMultipleSelectAnswer. */
export type CreateRoutineCheckupInterviewMultipleSelectAnswerPayload = {
  answer: Maybe<RoutineCheckupInterviewSelectAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated input type of CreateRoutineCheckupInterviewSingleSelectAnswer */
export type CreateRoutineCheckupInterviewSingleSelectAnswerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
  routineCheckupInterviewId: Scalars['ID']['input'];
  selection?: InputMaybe<RoutineCheckupInterviewSelectAnswerSelection>;
};

/** Autogenerated return type of CreateRoutineCheckupInterviewSingleSelectAnswer. */
export type CreateRoutineCheckupInterviewSingleSelectAnswerPayload = {
  answer: Maybe<RoutineCheckupInterviewSelectAnswer>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<InterviewQuestion>;
};

/** Autogenerated return type of CreateRoutineCheckup. */
export type CreateRoutineCheckupPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  routineCheckup: RoutineCheckup;
};

/** Autogenerated input type of CreateServiceContract */
export type CreateServiceContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contractType: ServiceContractType;
  /** 改定日 */
  revision: Scalars['ISO8601Date']['input'];
  service: ServiceContractService;
  url: Scalars['Url']['input'];
};

/** Autogenerated input type of CreateSignUpSurveyAnswer */
export type CreateSignUpSurveyAnswerInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionId: Scalars['ID']['input'];
  /** 新規登録アンケートID */
  signUpSurveyId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSignUpSurveyAnswer. */
export type CreateSignUpSurveyAnswerPayload = {
  answer: QuestionnaireAnswer;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  nextQuestion: Maybe<QuestionnaireQuestion>;
  survey: SignUpSurvey;
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User.id */
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateStripeSetupIntent. */
export type CreateStripeSetupIntentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  clientSecret: Scalars['String']['output'];
  setupIntentId: Scalars['String']['output'];
};

/** Autogenerated input type of CreateUserAccessToken */
export type CreateUserAccessTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateUserAddress */
export type CreateUserAddressInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['PostalCode']['input'];
  prefecture: Scalars['String']['input'];
  /** この住所をすべての定期便の配送先住所に設定するかどうか。 */
  setMedicineSubscriptionAddress?: InputMaybe<Scalars['Boolean']['input']>;
  town: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateUserCoupon */
export type CreateUserCouponInput = {
  activationCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateUserPhone */
export type CreateUserPhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateUserProblemReportAnswer */
export type CreateUserProblemReportAnswerInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  optionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionId: Scalars['ID']['input'];
  /** 問題のある顧客報告アンケートID */
  userProblemReportId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateUserProblemReport */
export type CreateUserProblemReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User.id */
  reportedUserId: Scalars['ID']['input'];
  reportedUserType: UserProblemReportUserType;
};

/** Autogenerated input type of CreateUserZoomMeeting */
export type CreateUserZoomMeetingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateUserZoomMeeting. */
export type CreateUserZoomMeetingPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  user: User;
  zoomMeeting: ZoomMeeting;
};

/** Autogenerated input type of CreateWmsOrder */
export type CreateWmsOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  invoiceId: Scalars['ID']['input'];
  /** WMS APIのバージョン ['v2'] */
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateZendeskTicketComment */
export type CreateZendeskTicketCommentInput = {
  /** body or file が必須です */
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** body or file が必須です */
  file?: InputMaybe<Scalars['Upload']['input']>;
  /** ZendeskTicket.id */
  ticketId: Scalars['ID']['input'];
  /** Userable.id */
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateZendeskTicket */
export type CreateZendeskTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 初回コメント本文（リクエスターとして投稿されます） */
  commentBody?: InputMaybe<Scalars['String']['input']>;
  /** InquiryCategory.id */
  inquiryCategoryId: Scalars['ID']['input'];
  /** Userable.id */
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateZendeskUser */
export type CreateZendeskUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<ZendeskUserRole>;
  /** Zendesk user tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Userable.id */
  userId: Scalars['ID']['input'];
};

export type Customer = Userable & {
  accessTokens: UserAccessTokenConnection;
  achievement: CustomerAchievement;
  activeExamination: Maybe<Examination>;
  activeInterview: Maybe<Interview>;
  activeMedicineSubscriptionRenewalRecommendation: Maybe<MedicineSubscriptionRenewalRecommendation>;
  /** 直近で回答が必要な定期問診スケジュール */
  activeRoutineCheckupSchedule: Maybe<RoutineCheckupSchedule>;
  addresses: UserAddressConnection;
  agreedServiceContracts: ServiceContractConnection;
  allAddresses: Array<UserAddress>;
  allInvoices: Array<Invoice>;
  allMedicationReminders: Array<Reminder>;
  allPaymentSources: Array<PaymentSource>;
  allRoles: Array<UserRole>;
  allUserCoupons: Array<UserCoupon>;
  annotation: Maybe<Scalars['String']['output']>;
  appsflyerIds: Maybe<Array<Scalars['String']['output']>>;
  atoneCustomers: AtoneCustomerConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentIdentification: Maybe<Identification>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  devices: DeviceConnection;
  email: Scalars['Email']['output'];
  emailVerifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  examinationAppointments: ExaminationAppointmentConnection;
  examinations: ExaminationConnection;
  /** 指定した診療科目の確定した処方を持つかどうか */
  hasDecidedPrescription: Maybe<Scalars['Boolean']['output']>;
  /** 認証情報としてのパスワードを保持している場合 true */
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identificationState: UserIdentificationState;
  interviews: InterviewConnection;
  /** 招待コード */
  invitationCode: Maybe<Scalars['String']['output']>;
  invoices: InvoiceConnection;
  /** 診察履歴のある医師 */
  involvedDoctors: DoctorConnection;
  isAgreedLatestServiceContracts: UserLatestServiceContractAgreementMap;
  kyc: Maybe<UserKyc>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** @deprecated Customer.latestMedicineSubscriptionsPerCategory を利用してください。 */
  latestMedicineSubscription: Maybe<MedicineSubscription>;
  latestMedicineSubscriptionsPerCategory: Array<MedicineSubscription>;
  medicationDiaries: MedicationDiaryConnection;
  medicationDiaryRecordsForCalendar: Array<MedicationDiaryRecord>;
  /** @deprecated Customer.allMedicationReminders を利用してください。 */
  medicationReminder: Maybe<Reminder>;
  medicineSubscriptions: MedicineSubscriptionConnection;
  nickname: Maybe<Scalars['String']['output']>;
  notes: CustomerNoteConnection;
  /** Smaluna for Biz アカウントのユーザー情報 */
  organizationUser: Maybe<SmalunaForBizUser>;
  /** atone会員登録・認証モジュールに渡すパラメータ。指定した支払い方法がatoneでない場合 NULL */
  paramsForAtoneAuthenticationModule: Maybe<Scalars['JSON']['output']>;
  payableInvoice: Maybe<Invoice>;
  paymentSources: PaymentSourceConnection;
  phone: Maybe<UserPhone>;
  profile: Maybe<Profile>;
  pushNotifications: PushNotificationConnection;
  roles: UserRoleConnection;
  routineCheckups: RoutineCheckupConnection;
  /** 新規登録アンケート */
  signUpSurvey: Maybe<SignUpSurvey>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userCoupons: UserCouponConnection;
  utmParameter: Maybe<UserUtmParameter>;
  /** 未読 Zendesk チケットコメント数 */
  zendeskTicketTotalUnreadCount: Scalars['Int']['output'];
  zendeskUser: Maybe<ZendeskUser>;
  zoomAuthCredential: Maybe<UserZoomAuthCredential>;
  zoomSecret: Maybe<UserZoomSecret>;
};


export type CustomerAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerAgreedServiceContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerAllInvoicesArgs = {
  orderBy?: InputMaybe<InvoiceOrder>;
  search?: InputMaybe<InvoiceSearch>;
};


export type CustomerAtoneCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerExaminationAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationAppointmentOrder>;
  search?: InputMaybe<ExaminationAppointmentSearch>;
};


export type CustomerExaminationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationOrder>;
  search?: InputMaybe<ExaminationSearch>;
};


export type CustomerHasDecidedPrescriptionArgs = {
  diseaseKey: DiseaseKey;
};


export type CustomerInterviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceOrder>;
  search?: InputMaybe<InvoiceSearch>;
};


export type CustomerInvolvedDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DoctorOrder>;
  search?: InputMaybe<DoctorSearch>;
};


export type CustomerIsAgreedLatestServiceContractsArgs = {
  service: ServiceContractService;
};


export type CustomerMedicationDiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MedicationDiaryOrder>;
  search?: InputMaybe<MedicationDiarySearch>;
};


export type CustomerMedicationDiaryRecordsForCalendarArgs = {
  from: Scalars['ISO8601Date']['input'];
  search?: InputMaybe<MedicationDiaryRecordsForCalendarSearch>;
  to: Scalars['ISO8601Date']['input'];
};


export type CustomerMedicineSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MedicineSubscriptionOrder>;
  search?: InputMaybe<MedicineSubscriptionSearch>;
};


export type CustomerNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerParamsForAtoneAuthenticationModuleArgs = {
  paymentMethodId: Scalars['ID']['input'];
};


export type CustomerPaymentSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerPushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerRoutineCheckupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerUserCouponsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomerAchievement = {
  customer: Customer;
  /** 診察を受けたことがある場合はtrueになります。 */
  examinationStarted: Scalars['Boolean']['output'];
  /** DOC定義の「初回予約」対象の顧客であるか。アカウント登録日から7日以内 & 診察予約歴なしの場合はtrueになります。 */
  firstReservable: Scalars['Boolean']['output'];
  /** 本人確認情報を提出したことがある場合はtrue、提出歴が無い場合はfalse、何らかの問題が発生し、情報が取得できなかった場合はnullが返ります。 */
  identificationSubmitted: Maybe<Scalars['Boolean']['output']>;
  /** 本人確認情報を提出した数を取得することができます。 */
  identificationSubmittedTimes: Scalars['Int']['output'];
  /** 問診を完了させたことがある場合はtrueになります。 */
  interviewCompleted: Scalars['Boolean']['output'];
  /** 招待コードを登録したことがある場合はtrueになります。 */
  invitationCodeRegistered: Scalars['Boolean']['output'];
  /** 決済をしたことがある場合はtrueになります。 */
  paid: Scalars['Boolean']['output'];
  /** 決済を申請したことがある場合はtrueになります。 */
  paymentRequested: Scalars['Boolean']['output'];
  /** プロフィール作成したことがある場合はtrueになります。 */
  profileRegistered: Scalars['Boolean']['output'];
};

/** The connection type for Customer. */
export type CustomerConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomerEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Customer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Customer>;
};

export enum CustomerMedicineSubscriptionState {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  None = 'NONE'
}

export type CustomerNote = {
  allergyMedicine: Scalars['Boolean']['output'];
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  examination: Maybe<Examination>;
  id: Scalars['ID']['output'];
  isEdited: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  writer: Maybe<User>;
  writerName: Maybe<Scalars['String']['output']>;
  writerRole: UserNoteWriterRole;
};

/** The connection type for CustomerNote. */
export type CustomerNoteConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<CustomerNoteEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<CustomerNote>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustomerNoteEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<CustomerNote>;
};

export type CustomerNoteOrder = {
  direction: OrderDirection;
  field: CustomerNoteOrderField;
};

export enum CustomerNoteOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID'
}

export type CustomerNoteSearch = {
  customerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  writerRoles?: InputMaybe<Array<UserNoteWriterRole>>;
};

export type CustomerOrder = {
  direction: OrderDirection;
  field: CustomerOrderField;
};

export enum CustomerOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastActivityAt = 'LAST_ACTIVITY_AT'
}

export type CustomerSearch = {
  /** Prefix match. */
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  familyNameKanas?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  familyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  givenNameKanas?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  givenNames?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  medicineSubscriptionState?: InputMaybe<CustomerMedicineSubscriptionState>;
  /** Exact match. Hyphens are ignored. */
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  roleGroup?: InputMaybe<RoleGroup>;
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomerSupportFileMessage = CustomerSupportMessage & {
  body: Scalars['Url']['output'];
  customerSupportId: Maybe<Scalars['ID']['output']>;
  sender: Userable;
  senderType: CustomerSupportMessageSenderType;
  type: CustomerSupportMessageType;
};

export type CustomerSupportMessage = {
  customerSupportId: Maybe<Scalars['ID']['output']>;
  sender: Userable;
  senderType: CustomerSupportMessageSenderType;
  type: CustomerSupportMessageType;
};

export type CustomerSupportMessageInput = {
  /** Text or URL */
  body: Scalars['String']['input'];
  type: CustomerSupportMessageType;
};

export enum CustomerSupportMessageSenderType {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum CustomerSupportMessageType {
  File = 'FILE',
  Text = 'TEXT'
}

export type CustomerSupportTextMessage = CustomerSupportMessage & {
  body: Scalars['String']['output'];
  customerSupportId: Maybe<Scalars['ID']['output']>;
  sender: Userable;
  senderType: CustomerSupportMessageSenderType;
  type: CustomerSupportMessageType;
};

export type DocWmsOrderCsvFile = {
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DeliveryService = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shippingCompany: ShippingCompany;
};

/** The connection type for DeliveryService. */
export type DeliveryServiceConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DeliveryServiceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DeliveryService>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DeliveryServiceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DeliveryService>;
};

export type DeliveryTracking = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  deliveryService: DeliveryService;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  trackingNumber: Scalars['String']['output'];
  trackingSearchUrl: Maybe<Scalars['Url']['output']>;
};

/** The connection type for DeliveryTracking. */
export type DeliveryTrackingConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DeliveryTrackingEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DeliveryTracking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DeliveryTrackingEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DeliveryTracking>;
};

export type DeliveryTrackingInput = {
  deliveryServiceId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  trackingNumber: Scalars['String']['input'];
};

export type DeliveryTrackingOrder = {
  direction: OrderDirection;
  field: DeliveryTrackingOrderField;
};

export enum DeliveryTrackingOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID'
}

/** Autogenerated input type of DenyInvoice */
export type DenyInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DenyPrescription */
export type DenyPrescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Prescription.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyAdmin */
export type DestroyAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyCustomer */
export type DestroyCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyCustomerNote */
export type DestroyCustomerNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyDoctorHoliday */
export type DestroyDoctorHolidayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** DoctorHoliday.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyDoctorOperatingTime */
export type DestroyDoctorOperatingTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorOperatingTimeId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyDoctorOperatingTime. */
export type DestroyDoctorOperatingTimePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
  doctorOperatingTime: DoctorOperatingTime;
};

/** Autogenerated input type of DestroyExaminationReview */
export type DestroyExaminationReviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ExaminationReview.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyFrequentlyAskedQuestion */
export type DestroyFrequentlyAskedQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyInquiryCategory */
export type DestroyInquiryCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyInvoiceUserCoupon */
export type DestroyInvoiceUserCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  invoiceId: Scalars['ID']['input'];
};

/** Autogenerated return type of DestroyInvoiceUserCoupon. */
export type DestroyInvoiceUserCouponPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
};

/** Autogenerated input type of DestroyMedicineDescription */
export type DestroyMedicineDescriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** MedicineDescription.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyPaymentSource */
export type DestroyPaymentSourceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** PaymentSource.id or Stripe PaymentMethod.id */
  id: Scalars['String']['input'];
};

/** Autogenerated return type of DestroyPaymentSource. */
export type DestroyPaymentSourcePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of DestroyReminder */
export type DestroyReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Reminder.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyUserAccessToken */
export type DestroyUserAccessTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UserAccessToken.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyUserAddress */
export type DestroyUserAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UserAddress.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DestroyUserCoupon */
export type DestroyUserCouponInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UserCoupon.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DetachAdminRole */
export type DetachAdminRoleInput = {
  adminId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleName: UserRoleName;
};

/** Autogenerated input type of DetachDoctorRole */
export type DetachDoctorRoleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  roleName: UserRoleName;
};

/** Autogenerated input type of DetachMedicineDocument */
export type DetachMedicineDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  medicineId: Scalars['ID']['input'];
};

/** Autogenerated input type of DetachMedicineImage */
export type DetachMedicineImageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  imageId: Scalars['ID']['input'];
  medicineId: Scalars['ID']['input'];
};

export type Device = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  pushNotification: Maybe<PushNotification>;
  token: Scalars['String']['output'];
};

/** The connection type for Device. */
export type DeviceConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DeviceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Device>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DeviceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Device>;
};

/** Autogenerated input type of DisableCustomerInvitation */
export type DisableCustomerInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of DisableInvoiceCustomerPaymentBlock */
export type DisableInvoiceCustomerPaymentBlockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DisableInvoiceCustomerPaymentBlock. */
export type DisableInvoiceCustomerPaymentBlockPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
};

export type Disease = {
  allAttentionNotes: Array<DiseaseAttentionNote>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  doctors: DoctorConnection;
  id: Scalars['ID']['output'];
  isSuspended: Scalars['Boolean']['output'];
  key: DiseaseKey;
  medicines: MedicineConnection;
  name: Scalars['String']['output'];
  suspendedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};


export type DiseaseDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DiseaseMedicinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiseaseAttentionNote = {
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  disease: Disease;
  id: Scalars['ID']['output'];
  position: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Disease. */
export type DiseaseConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DiseaseEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Disease>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DiseaseDoctor = {
  /** 慎重投与担当フラグ */
  carefulAdministration: Scalars['Boolean']['output'];
  disease: Disease;
  doctor: Doctor;
};

/** The connection type for DiseaseDoctor. */
export type DiseaseDoctorConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DiseaseDoctorEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DiseaseDoctor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DiseaseDoctorEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DiseaseDoctor>;
};

/** An edge in a connection. */
export type DiseaseEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Disease>;
};

export enum DiseaseKey {
  ColdnessSwellingKampo = 'COLDNESS_SWELLING_KAMPO',
  EmergencyContraceptive = 'EMERGENCY_CONTRACEPTIVE',
  FemaleAga = 'FEMALE_AGA',
  Laxatives = 'LAXATIVES',
  LaxativesObesityKampo = 'LAXATIVES_OBESITY_KAMPO',
  MedicalDiet = 'MEDICAL_DIET',
  MedicalSkinCare = 'MEDICAL_SKIN_CARE',
  MenstrualSymptomsKampo = 'MENSTRUAL_SYMPTOMS_KAMPO',
  OralContraceptive = 'ORAL_CONTRACEPTIVE',
  SexuallyTransmittedInfection = 'SEXUALLY_TRANSMITTED_INFECTION',
  SleepDisorder = 'SLEEP_DISORDER'
}

export type DiseaseSearch = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  keys?: InputMaybe<Array<DiseaseKey>>;
};

export type Doctor = Userable & {
  accessTokens: UserAccessTokenConnection;
  agreedServiceContracts: ServiceContractConnection;
  allAssignedMonitoringTimes: Array<DoctorAssignedMonitoringTime>;
  allDiseaseDoctors: Array<DiseaseDoctor>;
  allDiseases: Array<Disease>;
  allRoles: Array<UserRole>;
  annotation: Maybe<Scalars['String']['output']>;
  appsflyerIds: Maybe<Array<Scalars['String']['output']>>;
  certificateOfTelemedTraining: Maybe<ActiveStorageImage>;
  clinic: Maybe<Clinic>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 現在休診中である場合、その休診スケジュールが返却されます。 */
  currentHoliday: Maybe<DoctorHoliday>;
  currentIdentification: Maybe<Identification>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  diseaseDoctors: DiseaseDoctorConnection;
  diseases: DiseaseConnection;
  email: Scalars['Email']['output'];
  emailVerifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  examinationPlan: Maybe<ExaminationPlan>;
  examinations: ExaminationConnection;
  /** 現在時刻と比較して未来に予定している休診スケジュールのConnectionを返却します。 */
  futureHolidays: DoctorHolidayConnection;
  /** 認証情報としてのパスワードを保持している場合 true */
  hasPassword: Scalars['Boolean']['output'];
  /** 休診スケジュールのConnectionを返却します。 */
  holidays: DoctorHolidayConnection;
  id: Scalars['ID']['output'];
  identificationState: UserIdentificationState;
  /** 招待コード */
  invitationCode: Maybe<Scalars['String']['output']>;
  isAgreedLatestServiceContracts: UserLatestServiceContractAgreementMap;
  isPublished: Scalars['Boolean']['output'];
  isVideoCallReservable: Scalars['Boolean']['output'];
  /** 医師の稼働状態 */
  isWorking: Scalars['Boolean']['output'];
  kyc: Maybe<UserKyc>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  nowMonitoring: Scalars['Boolean']['output'];
  operatingTimes: DoctorOperatingTimeConnection;
  /** 現在時刻と比較して過去に完了している休診スケジュールのConnectionを返却します。 */
  pastHolidays: DoctorHolidayConnection;
  profile: Maybe<Profile>;
  pushNotifications: PushNotificationConnection;
  roles: UserRoleConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  utmParameter: Maybe<UserUtmParameter>;
  zendeskUser: Maybe<ZendeskUser>;
  zoomAuthCredential: Maybe<UserZoomAuthCredential>;
  zoomSecret: Maybe<UserZoomSecret>;
};


export type DoctorAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorAgreedServiceContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorDiseaseDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorDiseasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorExaminationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationOrder>;
  search?: InputMaybe<ExaminationSearch>;
};


export type DoctorFutureHolidaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorHolidaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DoctorHolidayOrder>;
  search?: InputMaybe<DoctorHolidaySearch>;
};


export type DoctorIsAgreedLatestServiceContractsArgs = {
  service: ServiceContractService;
};


export type DoctorOperatingTimesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DoctorOperatingTimeOrder>;
  search?: InputMaybe<DoctorOperatingTimeSearch>;
};


export type DoctorPastHolidaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorPushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DoctorAppointmentManagerClinicalSubject = {
  /** サイトコントローラー 診療科目ID */
  clinicalSubjectId: Scalars['ID']['output'];
  /** 初診フラグ */
  firstExamination: Scalars['Boolean']['output'];
  /** サイトコントローラー 診療科目名 */
  name: Scalars['String']['output'];
};

export type DoctorAppointmentSlot = {
  /** 予約枠 残数 (休診枠の場合はNULL) */
  availableSlotCount: Maybe<Scalars['Int']['output']>;
  /** 予約枠 終了日時 */
  endsAt: Scalars['ISO8601DateTime']['output'];
  /** 薬剤カテゴリ(=診療科目) */
  medicineCategory: Maybe<MedicineCategory>;
  /** 予約枠 開始日時 */
  startsAt: Scalars['ISO8601DateTime']['output'];
  /** 予約枠 日付 */
  targetDate: Scalars['ISO8601Date']['output'];
};

export type DoctorAssignedMonitoringTime = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  doctor: Doctor;
  doctorMonitoringTime: DoctorMonitoringTime;
  endsAt: Maybe<Scalars['TimeString']['output']>;
  id: Scalars['ID']['output'];
  isAvailableStartingTime: Scalars['Boolean']['output'];
  isStarted: Scalars['Boolean']['output'];
  startsAt: Scalars['TimeString']['output'];
};

/** The connection type for Doctor. */
export type DoctorConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DoctorEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Doctor>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DoctorDiseaseDoctorInput = {
  /** 慎重投与担当可能かどうか */
  carefulAdministration?: InputMaybe<Scalars['Boolean']['input']>;
  /** 疾患ID */
  diseaseId: Scalars['ID']['input'];
};

/** An edge in a connection. */
export type DoctorEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Doctor>;
};

export type DoctorHoliday = UserSchedule & {
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  doctor: Doctor;
  endsAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  onTime: Scalars['Boolean']['output'];
  startsAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

/** The connection type for DoctorHoliday. */
export type DoctorHolidayConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DoctorHolidayEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DoctorHoliday>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DoctorHolidayEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DoctorHoliday>;
};

export type DoctorHolidayOrder = {
  direction: OrderDirection;
  field: DoctorHolidayOrderField;
};

export enum DoctorHolidayOrderField {
  EndsAt = 'ENDS_AT',
  Id = 'ID',
  StartsAt = 'STARTS_AT'
}

export type DoctorHolidaySearch = {
  doctorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endsAtEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  endsAtStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  forced?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  startsAtEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startsAtStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type DoctorMonitoringTime = {
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  endsAt: Maybe<Scalars['TimeString']['output']>;
  id: Scalars['ID']['output'];
  startsAt: Scalars['TimeString']['output'];
};

export type DoctorOperatingTime = UserSchedule & {
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  doctor: Doctor;
  endsAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  onTime: Scalars['Boolean']['output'];
  startsAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

/** The connection type for DoctorOperatingTime. */
export type DoctorOperatingTimeConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DoctorOperatingTimeEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<DoctorOperatingTime>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DoctorOperatingTimeEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<DoctorOperatingTime>;
};

export type DoctorOperatingTimeOrder = {
  direction: OrderDirection;
  field: DoctorOperatingTimeOrderField;
};

export enum DoctorOperatingTimeOrderField {
  EndsAt = 'ENDS_AT',
  Id = 'ID',
  StartsAt = 'STARTS_AT'
}

export type DoctorOperatingTimeSearch = {
  doctorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endsAtEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  endsAtStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  startsAtEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  startsAtStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type DoctorOrder = {
  direction: OrderDirection;
  field: DoctorOrderField;
};

export enum DoctorOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastActivityAt = 'LAST_ACTIVITY_AT'
}

export type DoctorSearch = {
  clinicIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  clinicNames?: InputMaybe<Array<Scalars['String']['input']>>;
  diseaseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  familyNameKanas?: InputMaybe<Array<Scalars['String']['input']>>;
  familyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  givenNameKanas?: InputMaybe<Array<Scalars['String']['input']>>;
  givenNames?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  inPublish?: InputMaybe<Scalars['Boolean']['input']>;
  inWorking?: InputMaybe<Scalars['Boolean']['input']>;
  isVideoCallReservable?: InputMaybe<Scalars['Boolean']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 慎重投与担当可能な医師のみを取得するかどうか。疾患ID指定時のみ有効 */
  onlyCarefulAdministration?: InputMaybe<Scalars['Boolean']['input']>;
  prefectureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleGroup?: InputMaybe<RoleGroup>;
  roles?: InputMaybe<Array<UserRoleName>>;
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  workingState?: InputMaybe<DoctorWorkingState>;
};

export enum DoctorWorkingState {
  Monitoring = 'MONITORING',
  Resting = 'RESTING',
  Working = 'WORKING'
}

export type Document = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  prohibitsBackPhoto: Scalars['Boolean']['output'];
  type: IdentificationDocumentType;
};

/** Autogenerated input type of EmulateExaminationAppointmentVideoCallEvent */
export type EmulateExaminationAppointmentVideoCallEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
  videoCallId: Scalars['ID']['input'];
};

/** Autogenerated return type of EmulateExaminationAppointmentVideoCallEvent. */
export type EmulateExaminationAppointmentVideoCallEventPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  videoCall: ExaminationAppointmentVideoCall;
};

/** Autogenerated input type of EnableInvoiceCustomerPaymentBlock */
export type EnableInvoiceCustomerPaymentBlockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of EnableInvoiceCustomerPaymentBlock. */
export type EnableInvoiceCustomerPaymentBlockPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
};

export type Examination = {
  allMessages: Maybe<Array<ExaminationMessage>>;
  closedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  customerUnreadCount: Scalars['Int']['output'];
  disease: Disease;
  doctor: Doctor;
  /** 医師が診察時にアクセスするURL */
  doctorAccessUrl: Maybe<Scalars['String']['output']>;
  doctorSelectionMethod: Maybe<InterviewDoctorSelectionMethod>;
  examinationAppointment: Maybe<ExaminationAppointment>;
  guidance: Maybe<ExaminationGuidance>;
  /** 服薬指導があるかどうか */
  hasGuidance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  lastResponderType: ExaminationLastResponder;
  latestPrescription: Maybe<Prescription>;
  messages: Maybe<ExaminationMessageConnection>;
  prescriptions: PrescriptionConnection;
  review: Maybe<ExaminationReview>;
  terminationReason: Maybe<ExaminationTerminationReason>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ExaminationAllMessagesArgs = {
  orderBy?: InputMaybe<ExaminationMessageOrder>;
};


export type ExaminationMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationMessageOrder>;
};


export type ExaminationPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ExaminationAppointment = {
  /** アクティブなビデオ通話 */
  activeVideoCall: Maybe<ExaminationAppointmentVideoCall>;
  /** 診察予約に紐づく処方の請求データリスト */
  allInvoices: Maybe<Array<Invoice>>;
  /** 予約キャンセル日時 */
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  doctor: Maybe<Doctor>;
  /** 予約枠 終了日時 */
  endsAt: Scalars['ISO8601DateTime']['output'];
  examination: Maybe<Examination>;
  /** 診察完了日時 */
  examinationClosedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 診察開始日時 */
  examinationStartedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 診察待ち開始日時 */
  examinationWaitingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 予約期限切れ日時 */
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 予約期限日時 */
  expiresAt: Scalars['ISO8601DateTime']['output'];
  /** サイトコントローラー 診療科目情報 */
  externalClinicalSubject: Maybe<DoctorAppointmentManagerClinicalSubject>;
  /** サイトコントローラー 医師ID */
  externalDoctorId: Maybe<Scalars['String']['output']>;
  /** サイトコントローラー 医師氏名 */
  externalDoctorName: Maybe<Scalars['String']['output']>;
  /** サイトコントローラー 予約ID */
  externalId: Maybe<Scalars['String']['output']>;
  /** 初診フラグ */
  firstExamination: Scalars['Boolean']['output'];
  /** 初回ビデオ通話 */
  firstVideoCall: Maybe<ExaminationAppointmentVideoCall>;
  /** 初回ビデオ通話が作成可能かどうか */
  firstVideoCallReady: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  /** 薬剤カテゴリー(≒診療科目) */
  medicineCategory: MedicineCategory;
  operation: Maybe<ExaminationAppointmentOperation>;
  /** 支払い完了日時 */
  paymentCompletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 支払い待ち開始日時 */
  paymentWaitingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 予約日 */
  scheduledDate: Scalars['ISO8601Date']['output'];
  /** 予約枠 開始日時 */
  startsAt: Scalars['ISO8601DateTime']['output'];
  /** 予約ステータス */
  state: ExaminationAppointmentState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: Customer;
  /** ユーザー生年月日 (予約時点の情報として保持) */
  userBirthday: Maybe<Scalars['ISO8601Date']['output']>;
  /** ユーザー性別 (予約時点の情報として保持) */
  userGender: Maybe<Gender>;
  /** ユーザー氏名 (予約時点の情報として保持) */
  userName: Maybe<Scalars['String']['output']>;
  /** UUID (サイトコントローラー側に連携する予約IDとして利用) */
  uuid: Scalars['String']['output'];
  videoCalls: Maybe<ExaminationAppointmentVideoCallConnection>;
};


export type ExaminationAppointmentVideoCallsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ExaminationAppointment. */
export type ExaminationAppointmentConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationAppointmentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationAppointment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationAppointmentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationAppointment>;
};

export type ExaminationAppointmentOperation = {
  allStateTransitions: Array<ExaminationAppointmentOperationStateTransition>;
  /** 診察予約終了日時 */
  appointmentEndsAt: Scalars['ISO8601DateTime']['output'];
  /** 診察予約日 */
  appointmentScheduledDate: Scalars['ISO8601Date']['output'];
  /** 診察予約開始日時 */
  appointmentStartsAt: Scalars['ISO8601DateTime']['output'];
  /** 手続きキャンセル日時 */
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 担当オペレーター */
  chargeOperator: Maybe<Admin>;
  /** 手続き完了日時 */
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 医師アサイン日時 */
  doctorAssignedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 医師アサイン待ち日時 */
  doctorAssignmentWaitingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 医師からオペレーターへの申し送り事項 */
  doctorNote: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  id: Scalars['ID']['output'];
  /** 申し送り・備考欄 */
  note: Maybe<Scalars['String']['output']>;
  /** オペレーターから医師への申し送り事項 */
  operatorNote: Maybe<Scalars['String']['output']>;
  /** オペレーターによって次に遷移可能な予約管理ステータス */
  operatorPermittedStates: Array<ExaminationAppointmentOperationState>;
  /** 支払い待ち日時 */
  paymentWaitingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 予約確認待ち日時 */
  reviewWaitingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 予約確認開始日時 */
  reviewingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 現在の予約管理ステータス */
  state: ExaminationAppointmentOperationState;
  stateTransitions: ExaminationAppointmentOperationStateTransitionConnection;
  /** 保留・一時停止日時 */
  suspendedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** ユーザー連絡対応開始日時 */
  userContactStartedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** ユーザー連絡待機日時 */
  userContactWaitingAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};


export type ExaminationAppointmentOperationStateTransitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ExaminationAppointmentOperation. */
export type ExaminationAppointmentOperationConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationAppointmentOperationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationAppointmentOperation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationAppointmentOperationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationAppointmentOperation>;
};

export type ExaminationAppointmentOperationOrder = {
  direction: OrderDirection;
  field: ExaminationAppointmentOperationOrderField;
};

export enum ExaminationAppointmentOperationOrderField {
  AppointmentEndsAt = 'APPOINTMENT_ENDS_AT',
  AppointmentStartsAt = 'APPOINTMENT_STARTS_AT',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type ExaminationAppointmentOperationSearch = {
  examinationAppointmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  states?: InputMaybe<Array<ExaminationAppointmentOperationState>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userNames?: InputMaybe<Array<Scalars['String']['input']>>;
  userPhoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ExaminationAppointmentOperationState {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  DoctorAssigned = 'DOCTOR_ASSIGNED',
  DoctorAssignmentWaiting = 'DOCTOR_ASSIGNMENT_WAITING',
  PaymentWaiting = 'PAYMENT_WAITING',
  Pending = 'PENDING',
  Reviewing = 'REVIEWING',
  ReviewWaiting = 'REVIEW_WAITING',
  Suspended = 'SUSPENDED',
  UserContactInProgress = 'USER_CONTACT_IN_PROGRESS',
  UserContactWaiting = 'USER_CONTACT_WAITING'
}

export type ExaminationAppointmentOperationStateTransition = {
  /** 遷移前のステータス */
  beforeState: Maybe<ExaminationAppointmentOperationState>;
  /** 状態遷移日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 状態遷移を行ったアカウント */
  operator: Maybe<Admin>;
  /** 状態遷移を行ったアカウントのメールアドレス */
  operatorEmail: Maybe<Scalars['String']['output']>;
  /** 状態遷移を行ったアカウントの名前 */
  operatorName: Maybe<Scalars['String']['output']>;
  /** 診察予約管理ステータス */
  state: ExaminationAppointmentOperationState;
};

/** The connection type for ExaminationAppointmentOperationStateTransition. */
export type ExaminationAppointmentOperationStateTransitionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationAppointmentOperationStateTransitionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationAppointmentOperationStateTransition>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationAppointmentOperationStateTransitionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationAppointmentOperationStateTransition>;
};

export type ExaminationAppointmentOrder = {
  direction: OrderDirection;
  field: ExaminationAppointmentOrderField;
};

export enum ExaminationAppointmentOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  StartsAt = 'STARTS_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type ExaminationAppointmentSearch = {
  states?: InputMaybe<Array<ExaminationAppointmentState>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum ExaminationAppointmentState {
  Canceled = 'CANCELED',
  ExaminationClosed = 'EXAMINATION_CLOSED',
  ExaminationStarted = 'EXAMINATION_STARTED',
  ExaminationWaiting = 'EXAMINATION_WAITING',
  Expired = 'EXPIRED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentWaiting = 'PAYMENT_WAITING',
  Pending = 'PENDING'
}

export type ExaminationAppointmentVideoCall = {
  /** BellBird 管理画面URL */
  adminUrl: Maybe<Scalars['String']['output']>;
  /** ユーザーがビデオ通話に入室した日時 */
  calledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 診察が完了した日時 */
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 医師がビデオ通話に入室し通話が成立した日時 */
  connectedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  examinationAppointment: ExaminationAppointment;
  /** ホスト（医師・オペレーター）の出席状態 */
  hostAttendanceState: Maybe<AttendanceState>;
  /** ホスト（医師・オペレーター）用ビデオ診察URL */
  hostCallUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** 診察が未完了となった日時 */
  incompletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  prescriptionInquiry: Maybe<PrescriptionInquiry>;
  state: ExaminationAppointmentVideoCallState;
  /** ユーザーの出席状態 */
  userAttendanceState: Maybe<AttendanceState>;
  /** ユーザー用ビデオ診察URL */
  userCallUrl: Maybe<Scalars['String']['output']>;
};

/** The connection type for ExaminationAppointmentVideoCall. */
export type ExaminationAppointmentVideoCallConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationAppointmentVideoCallEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationAppointmentVideoCall>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationAppointmentVideoCallEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationAppointmentVideoCall>;
};

export enum ExaminationAppointmentVideoCallState {
  Calling = 'CALLING',
  Completed = 'COMPLETED',
  Ended = 'ENDED',
  OnCall = 'ON_CALL',
  Pending = 'PENDING',
  WaitingForUser = 'WAITING_FOR_USER'
}

/** The connection type for Examination. */
export type ExaminationConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Examination>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Examination>;
};

export type ExaminationFileMessage = ExaminationMessage & {
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['Url']['output'];
  /** Become a Doctor, Customer or null depending by senderType. */
  sender: Maybe<Userable>;
  senderType: ExaminationMessageSenderType;
  type: ExaminationMessageType;
};

export type ExaminationGuidance = {
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
};

export enum ExaminationLastResponder {
  Doctor = 'DOCTOR',
  User = 'USER'
}

export type ExaminationMessage = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
  /** Become a Doctor, Customer or null depending by senderType. */
  sender: Maybe<Userable>;
  senderType: ExaminationMessageSenderType;
  type: ExaminationMessageType;
};

/** The connection type for ExaminationMessage. */
export type ExaminationMessageConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationMessageEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationMessageEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationMessage>;
};

export type ExaminationMessageInput = {
  /** Text or URL */
  body: Scalars['String']['input'];
  type: ExaminationMessageSelectableType;
};

export type ExaminationMessageOrder = {
  direction: OrderDirection;
  field: ExaminationMessageOrderField;
};

export enum ExaminationMessageOrderField {
  CreatedAt = 'CREATED_AT'
}

export enum ExaminationMessageSelectableSenderType {
  Doctor = 'DOCTOR',
  User = 'USER'
}

export enum ExaminationMessageSelectableType {
  File = 'FILE',
  Text = 'TEXT'
}

export enum ExaminationMessageSenderType {
  Doctor = 'DOCTOR',
  System = 'SYSTEM',
  User = 'USER'
}

export enum ExaminationMessageType {
  File = 'FILE',
  Prescription = 'PRESCRIPTION',
  Text = 'TEXT'
}

export type ExaminationOrder = {
  direction: OrderDirection;
  field: ExaminationOrderField;
};

export enum ExaminationOrderField {
  ClosedAt = 'CLOSED_AT',
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type ExaminationPlan = {
  allContents: Array<ExaminationPlanContent>;
  contents: ExaminationPlanContentConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  doctor: Doctor;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ExaminationPlanContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ExaminationPlanContent = {
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  examinationPlan: ExaminationPlan;
  id: Scalars['ID']['output'];
  sectionNumber: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ExaminationPlanContent. */
export type ExaminationPlanContentConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationPlanContentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationPlanContent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationPlanContentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationPlanContent>;
};

export type ExaminationPrescriptionMessage = ExaminationMessage & {
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
  prescription: Maybe<Prescription>;
  /** Become a Doctor, Customer or null depending by senderType. */
  sender: Maybe<Userable>;
  senderType: ExaminationMessageSenderType;
  type: ExaminationMessageType;
};

export type ExaminationReview = {
  allQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
  questions: QuestionnaireQuestionConnection;
  rate: Scalars['Int']['output'];
  reviewer: Customer;
  state: QuestionnaireRespondentState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type ExaminationReviewAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ExaminationReviewQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ExaminationReview. */
export type ExaminationReviewConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationReviewEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationReview>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationReviewEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationReview>;
};

export type ExaminationSearch = {
  disease?: InputMaybe<DiseaseSearch>;
  doctorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  interviewIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  lastResponderType?: InputMaybe<ExaminationLastResponder>;
  user?: InputMaybe<UserSearch>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ExaminationTerminationReason = {
  /** 診察終了理由 */
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 公開日時 */
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for ExaminationTerminationReason. */
export type ExaminationTerminationReasonConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ExaminationTerminationReasonEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ExaminationTerminationReason>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ExaminationTerminationReasonEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ExaminationTerminationReason>;
};

export type ExaminationTextMessage = ExaminationMessage & {
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
  /** Become a Doctor, Customer or null depending by senderType. */
  sender: Maybe<Userable>;
  senderType: ExaminationMessageSenderType;
  type: ExaminationMessageType;
};

/** Autogenerated input type of FetchKarakuriChatbotClient */
export type FetchKarakuriChatbotClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
};

/** Autogenerated return type of FetchKarakuriChatbotClient. */
export type FetchKarakuriChatbotClientPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type FrequentlyAskedQuestion = {
  id: Scalars['ID']['output'];
  inquiryCategory: InquiryCategory;
  position: Maybe<Scalars['Int']['output']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['Url']['output'];
};

/** The connection type for FrequentlyAskedQuestion. */
export type FrequentlyAskedQuestionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<FrequentlyAskedQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<FrequentlyAskedQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FrequentlyAskedQuestionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<FrequentlyAskedQuestion>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

/** Autogenerated input type of GenerateReceipt */
export type GenerateReceiptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of HideDoctor */
export type HideDoctorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of HideFrequentlyAskedQuestion */
export type HideFrequentlyAskedQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of HideInquiryCategory */
export type HideInquiryCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type Identification = {
  approvedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  birthday: Scalars['ISO8601Date']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deniedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  documentType: Maybe<IdentificationDocumentType>;
  familyName: Scalars['String']['output'];
  familyNameKana: Maybe<Scalars['String']['output']>;
  gender: Gender;
  givenName: Scalars['String']['output'];
  givenNameKana: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reasons: Array<Scalars['String']['output']>;
  state: IdentificationState;
  suspendedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum IdentificationDocumentType {
  DriversLicense = 'DRIVERS_LICENSE',
  HealthInsuranceCard = 'HEALTH_INSURANCE_CARD',
  IndividualNumberCard = 'INDIVIDUAL_NUMBER_CARD',
  Passport = 'PASSPORT',
  ResidenceCard = 'RESIDENCE_CARD'
}

export type IdentificationPhotos = {
  back?: InputMaybe<Scalars['Upload']['input']>;
  front: Scalars['Upload']['input'];
  /** @deprecated 顔写真のアップロードは廃止されました */
  selfie?: InputMaybe<Scalars['Upload']['input']>;
};

export enum IdentificationState {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Suspended = 'SUSPENDED',
  Unconfirmed = 'UNCONFIRMED'
}

/** Autogenerated input type of IgnoreAddressVerificationDenied */
export type IgnoreAddressVerificationDeniedInput = {
  addressId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ImageUrlVariants = {
  /** Resized to 1280 */
  large: Scalars['Url']['output'];
  /** Resized to 640 */
  medium: Scalars['Url']['output'];
  /** Original size */
  original: Scalars['Url']['output'];
  /** Resized to 320 */
  small: Scalars['Url']['output'];
  /** Cropped to 320x320 */
  thumbnail: Scalars['Url']['output'];
};

export type InquiryCategory = {
  /** オブジェクトの子カテゴリのリストを返却します。 */
  allChildren: Array<InquiryCategory>;
  /** よくある質問のリストを返却します。 */
  allFrequentlyAskedQuestions: Array<FrequentlyAskedQuestion>;
  /** オブジェクトの子カテゴリのリストを返却します。 */
  children: InquiryCategoryConnection;
  consultantType: InquiryCategoryConsultantType;
  /** オブジェクトが属する階層を返却します。現在の仕様では [0:大カテゴリ, 1:中カテゴリ] です。 */
  depth: Scalars['Int']['output'];
  explanation: Maybe<Scalars['String']['output']>;
  /** よくある質問のリストを返却します。 */
  frequentlyAskedQuestions: FrequentlyAskedQuestionConnection;
  id: Scalars['ID']['output'];
  image: Maybe<ActiveStorageImage>;
  /** オブジェクトに親カテゴリが存在する場合はtrueを返却します。 */
  isChild: Scalars['Boolean']['output'];
  /** オブジェクトがルートカテゴリである場合はtrueを返却します。 */
  isRoot: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** オブジェクトの親カテゴリを返します。親カテゴリが無い場合はNULLを返却します。 */
  parent: Maybe<InquiryCategory>;
  position: Maybe<Scalars['Int']['output']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** オブジェクトが属するルートカテゴリを返却します。オブジェクト自身がルートカテゴリである場合は自身を返却します。 */
  root: InquiryCategory;
};


export type InquiryCategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InquiryCategoryFrequentlyAskedQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for InquiryCategory. */
export type InquiryCategoryConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InquiryCategoryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InquiryCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum InquiryCategoryConsultantType {
  CustomerSupport = 'CUSTOMER_SUPPORT',
  MedicalConsultation = 'MEDICAL_CONSULTATION'
}

/** An edge in a connection. */
export type InquiryCategoryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InquiryCategory>;
};

export type InquiryCategorySearch = {
  consultantType?: InputMaybe<InquiryCategoryConsultantType>;
  depth?: InputMaybe<Scalars['Int']['input']>;
};

export type Interview = {
  allAnswers: Maybe<Array<InterviewAnswer>>;
  allQuestions: Array<InterviewQuestion>;
  answers: Maybe<InterviewAnswerConnection>;
  /** 慎重投与フラグ */
  carefulAdministration: Scalars['Boolean']['output'];
  communicationMethod: InterviewCommunicationMethod;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  disease: Maybe<Disease>;
  doctor: Maybe<Doctor>;
  doctorSelectionMethod: Maybe<InterviewDoctorSelectionMethod>;
  /** 診察予約 */
  examinationAppointment: Maybe<ExaminationAppointment>;
  expiresAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isExpired: Scalars['Boolean']['output'];
  isSubmissionExpired: Scalars['Boolean']['output'];
  medicineCategory: Maybe<MedicineCategory>;
  medicineSubscriptionRenewalRecommendation: Maybe<MedicineSubscriptionRenewalRecommendation>;
  questions: InterviewQuestionConnection;
  state: InterviewState;
  submissionExpiresAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type InterviewAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InterviewQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type InterviewAddressAnswer = InterviewAnswerable & {
  body: Scalars['String']['output'];
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InterviewAnswer = {
  contentType: InterviewAnswerContentType;
  contents: Array<InterviewAnswerContent>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for InterviewAnswer. */
export type InterviewAnswerConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InterviewAnswerEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InterviewAnswer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InterviewAnswerContent = {
  attention: Maybe<Scalars['Int']['output']>;
  body: Maybe<Scalars['String']['output']>;
  key: Maybe<Scalars['String']['output']>;
  shortBody: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export enum InterviewAnswerContentType {
  Bmi = 'BMI',
  Text = 'TEXT'
}

/** An edge in a connection. */
export type InterviewAnswerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InterviewAnswer>;
};

export type InterviewAnswerable = {
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InterviewBmiAnswer = InterviewAnswerable & {
  bmi: Scalars['String']['output'];
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  height: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  weight: Scalars['String']['output'];
};

export enum InterviewCommunicationMethod {
  TextChat = 'TEXT_CHAT',
  Undefined = 'UNDEFINED',
  VideoCall = 'VIDEO_CALL'
}

/** The connection type for Interview. */
export type InterviewConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InterviewEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Interview>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type InterviewDateAnswer = InterviewAnswerable & {
  body: Maybe<Scalars['ISO8601Date']['output']>;
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InterviewDateTimeAnswer = InterviewAnswerable & {
  body: Maybe<Scalars['ISO8601DateTime']['output']>;
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum InterviewDoctorSelectionMethod {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/** An edge in a connection. */
export type InterviewEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Interview>;
};

export type InterviewOrder = {
  direction: OrderDirection;
  field: InterviewOrderField;
};

export enum InterviewOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export type InterviewQuestion = {
  allSelections: Array<InterviewQuestionSelection>;
  annotation: Maybe<Scalars['String']['output']>;
  answerType: QuestionAnswerType;
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isForkedQuestion: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  /** @deprecated Use `isForkedQuestion` instead. */
  isSubQuestion: Scalars['Boolean']['output'];
  parentSelection: Maybe<InterviewQuestionSelection>;
  selections: InterviewQuestionSelectionConnection;
  shortBody: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type InterviewQuestionSelectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for InterviewQuestion. */
export type InterviewQuestionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InterviewQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InterviewQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InterviewQuestionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InterviewQuestion>;
};

export type InterviewQuestionSelection = {
  additionalQuestion: Maybe<InterviewQuestion>;
  allSubQuestions: Array<InterviewQuestion>;
  attention: Scalars['Int']['output'];
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: InterviewQuestion;
  subQuestions: InterviewQuestionConnection;
};


export type InterviewQuestionSelectionSubQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for InterviewQuestionSelection. */
export type InterviewQuestionSelectionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InterviewQuestionSelectionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InterviewQuestionSelection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InterviewQuestionSelectionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InterviewQuestionSelection>;
};

export type InterviewSearch = {
  diseaseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  doctorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  states?: InputMaybe<Array<InterviewState>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type InterviewSelectAnswer = InterviewAnswerable & {
  contentType: InterviewAnswerContentType;
  contents: Array<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InterviewSelectAnswerSelection = {
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type InterviewSheet = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  disease: Maybe<Disease>;
  id: Scalars['ID']['output'];
  interviewSheetQuestions: InterviewSheetQuestionConnection;
  interviewType: InterviewSheetInterviewType;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  questions: InterviewQuestionConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  version: Scalars['Int']['output'];
};


export type InterviewSheetInterviewSheetQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InterviewSheetQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for InterviewSheet. */
export type InterviewSheetConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InterviewSheetEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InterviewSheet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InterviewSheetEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InterviewSheet>;
};

export enum InterviewSheetInterviewType {
  CommonInterview = 'COMMON_INTERVIEW',
  FirstInterview = 'FIRST_INTERVIEW',
  IntegratedInterview = 'INTEGRATED_INTERVIEW',
  NestedInterview = 'NESTED_INTERVIEW',
  ReInterview = 'RE_INTERVIEW',
  RoutineCheckupInterview = 'ROUTINE_CHECKUP_INTERVIEW'
}

export type InterviewSheetQuestion = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  interviewSheet: InterviewSheet;
  position: Maybe<Scalars['Int']['output']>;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for InterviewSheetQuestion. */
export type InterviewSheetQuestionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InterviewSheetQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InterviewSheetQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InterviewSheetQuestionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InterviewSheetQuestion>;
};

export type InterviewSheetSearch = {
  diseaseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  interviewType?: InputMaybe<InterviewSheetInterviewType>;
};

export enum InterviewState {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Submitted = 'SUBMITTED'
}

export type InterviewTextAnswer = InterviewAnswerable & {
  body: Scalars['String']['output'];
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  interview: Interview;
  question: InterviewQuestion;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Invoice = {
  allAtoneTransactions: Array<AtoneTransaction>;
  allAvailablePaymentMethods: Array<PaymentMethod>;
  /** 請求に適用することができる UserCoupon のリスト */
  allAvailableUserCoupons: Array<UserCoupon>;
  allDeliveryTrackings: Array<DeliveryTracking>;
  allLineItems: Array<LineItem>;
  allStripePaymentIntents: Array<StripePaymentIntent>;
  atoneTransactions: AtoneTransactionConnection;
  auditedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 請求に適用することができる UserCoupon のリスト */
  availableUserCoupons: UserCouponConnection;
  billingAddress: Maybe<UserAddress>;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 会計用 */
  chargeDoctorId: Scalars['Int']['output'];
  /** クーポンによる割引額。クーポン未設定であったり、定期便プラン未選択などにより総額が未確定の場合は 0 */
  couponDiscountAmount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  delayed: Scalars['Boolean']['output'];
  deliveryTrackings: DeliveryTrackingConnection;
  deniedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  isCustomerPaymentBlocked: Scalars['Boolean']['output'];
  isPaymentPlanSelected: Scalars['Boolean']['output'];
  isRestPaymentMethodRequired: Scalars['Boolean']['output'];
  /** 有効かつ最新のStripePaymentIntent */
  latestStripePaymentIntent: Maybe<StripePaymentIntent>;
  lineItems: LineItemConnection;
  npTransactionId: Maybe<Scalars['String']['output']>;
  /** forBiz企業負担による値引き額。forBiz企業負担が適用されていない場合や、定期便プラン未選択などにより総額が未確定の場合は 0 */
  organizationBurdenDiscountAmount: Scalars['Int']['output'];
  /** forBiz企業負担率。請求にforBiz企業負担が適用されていない場合 NULL */
  organizationBurdenRatio: Maybe<Scalars['Int']['output']>;
  paidAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * 支払い総額(割引適用後)。定期便プラン未選択など、総額が未確定の場合 NULL
   * @deprecated total_amount を使用してください。
   */
  payableTotalCost: Maybe<Scalars['Int']['output']>;
  paymentDueDate: Scalars['ISO8601Date']['output'];
  paymentMethod: Maybe<PaymentMethod>;
  /** 決済を申請したことがある場合はtrueになります。 */
  paymentRequested: Scalars['Boolean']['output'];
  paymentSource: Maybe<PaymentSource>;
  paymentSourceId: Maybe<Scalars['String']['output']>;
  positionPerPrescription: Scalars['Int']['output'];
  prescription: Prescription;
  refundedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  requestsOn: Scalars['ISO8601Date']['output'];
  /** 定期便2回目以降の支払い方法 */
  restPaymentMethod: Maybe<InvoiceRestPaymentMethod>;
  reviewStartedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  shippingAddress: Maybe<UserAddress>;
  shopTransactionId: Maybe<Scalars['String']['output']>;
  smalunaForBizCompanyBillingExempted: Scalars['Boolean']['output'];
  smalunaForBizCompanyId: Maybe<Scalars['Int']['output']>;
  smalunaForBizExemptionClassificationId: Maybe<Scalars['Int']['output']>;
  state: InvoiceState;
  /** Stripe::Charge */
  stripeCharge: Maybe<StripeCharge>;
  /** StripePaymentIntent */
  stripePaymentIntents: Maybe<StripePaymentIntentConnection>;
  /** 小計(割引適用前)。定期便プラン未選択など、総額が未確定の場合 NULL */
  subtotalAmount: Maybe<Scalars['Int']['output']>;
  /** 支払い総額(割引適用後)。定期便プラン未選択など、総額が未確定の場合 NULL */
  totalAmount: Maybe<Scalars['Int']['output']>;
  /**
   * 支払い総額(割引適用後)
   * @deprecated total_amount を使用してください。
   */
  totalCost: Scalars['Int']['output'];
  /** 小計からforBiz企業負担による値引きを引いた金額。総額が未確定の場合 NULL */
  userBurdenAmount: Maybe<Scalars['Int']['output']>;
  /** 請求に適用されている UserCoupon */
  userCoupon: Maybe<UserCoupon>;
};


export type InvoiceAllAvailablePaymentMethodsArgs = {
  orderBy?: InputMaybe<PaymentMethodOrder>;
  search?: InputMaybe<PaymentMethodSearch>;
};


export type InvoiceAllDeliveryTrackingsArgs = {
  orderBy?: InputMaybe<DeliveryTrackingOrder>;
};


export type InvoiceAtoneTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvoiceAvailableUserCouponsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvoiceDeliveryTrackingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DeliveryTrackingOrder>;
};


export type InvoiceLineItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvoiceStripePaymentIntentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceBulkShipByCsvJob = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** CSVファイルのURL */
  csvFile: Maybe<ActiveStorageFile>;
  csvType: InvoiceBulkShipByCsvJobCsvType;
  id: Scalars['ID']['output'];
  result: Maybe<Scalars['JSON']['output']>;
  state: InvoiceBulkShipByCsvJobState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for InvoiceBulkShipByCsvJob. */
export type InvoiceBulkShipByCsvJobConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InvoiceBulkShipByCsvJobEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<InvoiceBulkShipByCsvJob>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum InvoiceBulkShipByCsvJobCsvType {
  KuronekoB2 = 'KURONEKO_B2',
  KuronekoSmartCat = 'KURONEKO_SMART_CAT'
}

/** An edge in a connection. */
export type InvoiceBulkShipByCsvJobEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<InvoiceBulkShipByCsvJob>;
};

export type InvoiceBulkShipByCsvJobOrder = {
  direction: OrderDirection;
  field: InvoiceBulkShipByCsvJobOrderField;
};

export enum InvoiceBulkShipByCsvJobOrderField {
  Id = 'ID'
}

export enum InvoiceBulkShipByCsvJobState {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Queued = 'QUEUED',
  Working = 'WORKING'
}

/** The connection type for Invoice. */
export type InvoiceConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Invoice>;
};

export type InvoiceOrder = {
  direction: OrderDirection;
  field: InvoiceOrderField;
};

export enum InvoiceOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  PaidAt = 'PAID_AT',
  RequestsOn = 'REQUESTS_ON'
}

export type InvoiceRestPaymentMethod = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  invoice: Invoice;
  paymentMethod: PaymentMethod;
  paymentSource: Maybe<PaymentSource>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type InvoiceSearch = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  medicineIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  medicineVariantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  npTransactionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paidAtEnd?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  paidAtStart?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  paymentMethodIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  prescriptionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  requestsOnEnd?: InputMaybe<Scalars['ISO8601Date']['input']>;
  requestsOnStart?: InputMaybe<Scalars['ISO8601Date']['input']>;
  states?: InputMaybe<Array<InvoiceState>>;
  user?: InputMaybe<UserSearch>;
};

export enum InvoiceState {
  Audited = 'AUDITED',
  Canceled = 'CANCELED',
  Denied = 'DENIED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Reviewing = 'REVIEWING',
  Shipped = 'SHIPPED'
}

/** Autogenerated input type of IssueAdminPasswordResetToken */
export type IssueAdminPasswordResetTokenInput = {
  adminId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl: Scalars['Url']['input'];
};

/** Autogenerated input type of IssueUserEmailChangeToken */
export type IssueUserEmailChangeTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** User.id */
  id: Scalars['ID']['input'];
  newEmail: Scalars['Email']['input'];
  redirectUrl: Scalars['Url']['input'];
};

/** Autogenerated return type of IssueUserEmailChangeToken. */
export type IssueUserEmailChangeTokenPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  currentEmail: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  newEmail: Scalars['Email']['output'];
  tokenExpiresAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of IssueUserEmailVerificationCode */
export type IssueUserEmailVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  /** User.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of IssueUserEmailVerificationCode. */
export type IssueUserEmailVerificationCodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  codeExpiresAt: Scalars['ISO8601DateTime']['output'];
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
};

/** Autogenerated input type of IssueUserEmailVerificationToken */
export type IssueUserEmailVerificationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  /** User.id */
  id: Scalars['ID']['input'];
  redirectUrl: Scalars['Url']['input'];
};

/** Autogenerated return type of IssueUserEmailVerificationToken. */
export type IssueUserEmailVerificationTokenPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  tokenExpiresAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of IssueUserPasswordResetToken */
export type IssueUserPasswordResetTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  redirectUrl: Scalars['Url']['input'];
};

/** Autogenerated return type of IssueUserPasswordResetToken. */
export type IssueUserPasswordResetTokenPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  redirectUrl: Scalars['Url']['output'];
};

export enum KycRegistrationPlatformType {
  Native = 'NATIVE',
  Web = 'WEB'
}

export type LineItem = {
  /** 薬剤監査情報 (ADMIN DOCTORのみ閲覧可能) */
  allLineItemAudits: Array<Audit>;
  /** 薬剤監査画像 (ADMIN, DOCTORのみ閲覧可能) */
  auditImage: Maybe<ImageUrlVariants>;
  auditedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /**
   * 小計(割引適用前)
   * @deprecated subtotalAmountを使用してください。
   */
  cost: Scalars['Int']['output'];
  /** クーポンによる割引額 */
  couponDiscountAmount: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 薬剤監査情報 (ADMIN, DOCTORのみ閲覧可能) */
  lineItemAudits: AuditConnection;
  medicineVariant: MedicineVariant;
  /** forBiz企業負担による値引き額 */
  organizationBurdenDiscountAmount: Scalars['Int']['output'];
  /** forBiz企業負担率。企業負担が適用されていない場合 NULL */
  organizationBurdenRatio: Maybe<Scalars['Int']['output']>;
  prescriptionFee: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  refundedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  state: LineItemState;
  /** 小計(割引適用前) */
  subtotalAmount: Scalars['Int']['output'];
  /** 総額(割引適用後) */
  totalAmount: Scalars['Int']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  /** 小計からforBiz企業負担による値引きを引いた金額 */
  userBurdenAmount: Scalars['Int']['output'];
  userCoupon: Maybe<UserCoupon>;
};


export type LineItemLineItemAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for LineItem. */
export type LineItemConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<LineItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<LineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LineItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<LineItem>;
};

export enum LineItemState {
  Audited = 'AUDITED',
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Refunded = 'REFUNDED'
}

export type MediaBanner = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  image: MediaImage;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  revisedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Maybe<Scalars['Url']['output']>;
};

export type MediaBannerOrder = {
  direction: OrderDirection;
  field: MediaBannerOrderField;
};

export enum MediaBannerOrderField {
  CreatedAt = 'CREATED_AT',
  PublishedAt = 'PUBLISHED_AT',
  RevisedAt = 'REVISED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type MediaBannersPayload = {
  contents: Array<MediaBanner>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MediaImage = {
  height: Scalars['Int']['output'];
  url: Scalars['Url']['output'];
  width: Scalars['Int']['output'];
};

export type MediaPost = {
  appOnly: Scalars['Boolean']['output'];
  category: Maybe<MediaPostCategory>;
  contents: Array<MediaPostContent>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: MediaImage;
  nextPost: Maybe<MediaPost>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  readTogether: Array<MediaPost>;
  revisedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  showContact: Scalars['Boolean']['output'];
  showToc: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type MediaPostCategoriesPayload = {
  contents: Array<MediaPostCategory>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MediaPostCategory = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  posts: MediaPostsPayload;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  revisedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type MediaPostCategoryPostsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MediaPostOrder>;
  search?: InputMaybe<MediaPostSearch>;
};

export type MediaPostCategoryOrder = {
  direction: OrderDirection;
  field: MediaPostCategoryOrderField;
};

export enum MediaPostCategoryOrderField {
  CreatedAt = 'CREATED_AT',
  Position = 'POSITION',
  PublishedAt = 'PUBLISHED_AT',
  RevisedAt = 'REVISED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type MediaPostContent = {
  fieldId: Scalars['String']['output'];
};

export type MediaPostOrder = {
  direction: OrderDirection;
  field: MediaPostOrderField;
};

export enum MediaPostOrderField {
  CreatedAt = 'CREATED_AT',
  PublishedAt = 'PUBLISHED_AT',
  RevisedAt = 'REVISED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type MediaPostSearch = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type MediaPostsPayload = {
  contents: Array<MediaPost>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MedicalConsultationFileMessage = MedicalConsultationMessage & {
  body: Scalars['Url']['output'];
  medicalConsultationId: Maybe<Scalars['ID']['output']>;
  sender: Userable;
  senderType: MedicalConsultationMessageSenderType;
  type: MedicalConsultationMessageType;
};

export type MedicalConsultationMessage = {
  medicalConsultationId: Maybe<Scalars['ID']['output']>;
  sender: Userable;
  senderType: MedicalConsultationMessageSenderType;
  type: MedicalConsultationMessageType;
};

export type MedicalConsultationMessageInput = {
  /** Text or URL */
  body: Scalars['String']['input'];
  type: MedicalConsultationMessageType;
};

export enum MedicalConsultationMessageSenderType {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum MedicalConsultationMessageType {
  File = 'FILE',
  Quote = 'QUOTE',
  Text = 'TEXT'
}

export type MedicalConsultationTextMessage = MedicalConsultationMessage & {
  body: Scalars['String']['output'];
  medicalConsultationId: Maybe<Scalars['ID']['output']>;
  sender: Userable;
  senderType: MedicalConsultationMessageSenderType;
  type: MedicalConsultationMessageType;
};

export type MedicationDiary = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentPillSheetRecords: Array<MedicationDiaryRecord>;
  currentPillSheetStartsOn: Scalars['ISO8601Date']['output'];
  customer: Customer;
  daysPerPillSheet: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  pillSheetsCount: Scalars['Int']['output'];
  records: MedicationDiaryRecordConnection;
  recordsCount: Scalars['Int']['output'];
  startsOn: Scalars['ISO8601Date']['output'];
  state: MedicationDiaryState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type MedicationDiaryRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<MedicationDiaryRecordSearch>;
};

/** The connection type for MedicationDiary. */
export type MedicationDiaryConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicationDiaryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicationDiary>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicationDiaryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicationDiary>;
};

export type MedicationDiaryOrder = {
  direction: OrderDirection;
  field: MedicationDiaryOrderField;
};

export enum MedicationDiaryOrderField {
  Id = 'ID',
  StartsOn = 'STARTS_ON'
}

export type MedicationDiaryRecord = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  dosedOn: Scalars['ISO8601Date']['output'];
  feeling: MedicationDiaryRecordFeeling;
  id: Scalars['ID']['output'];
  medicationDiary: MedicationDiary;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for MedicationDiaryRecord. */
export type MedicationDiaryRecordConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicationDiaryRecordEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicationDiaryRecord>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicationDiaryRecordEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicationDiaryRecord>;
};

export enum MedicationDiaryRecordFeeling {
  Bad = 'BAD',
  Good = 'GOOD',
  VeryGood = 'VERY_GOOD'
}

export type MedicationDiaryRecordSearch = {
  dosedOnFrom?: InputMaybe<Scalars['ISO8601Date']['input']>;
  dosedOnTo?: InputMaybe<Scalars['ISO8601Date']['input']>;
  medicationDiaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MedicationDiaryRecordsForCalendarSearch = {
  medicationDiaryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MedicationDiarySearch = {
  states?: InputMaybe<Array<MedicationDiaryState>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum MedicationDiaryState {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Terminated = 'TERMINATED'
}

export type Medicine = {
  allDescriptions: Array<MedicineDescription>;
  allDocuments: Array<ActiveStorageFile>;
  allImages: Array<ActiveStorageImage>;
  allVariants: Array<MedicineVariant>;
  category: Maybe<MedicineCategory>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  descriptions: MedicineDescriptionConnection;
  disease: Maybe<Disease>;
  documents: ActiveStorageFileConnection;
  hasSubscriptionSku: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageUrls: Array<Scalars['Url']['output']>;
  images: ActiveStorageImageConnection;
  introduction: Maybe<Scalars['String']['output']>;
  isSuspended: Scalars['Boolean']['output'];
  manufacturer: Maybe<Scalars['String']['output']>;
  /** 決済あたりの最高値 */
  maxPricePerOneItem: Maybe<Scalars['Int']['output']>;
  minCost: Maybe<Scalars['Int']['output']>;
  minCostForSubscription: Maybe<Scalars['Int']['output']>;
  /** 決済あたりの最安値 */
  minPricePerOneItem: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  position: Maybe<Scalars['Int']['output']>;
  /** 商品タイプ */
  productType: MedicineProductType;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  publishedToCustomer: Scalars['Boolean']['output'];
  publishedToDoctor: Scalars['Boolean']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  variants: MedicineVariantConnection;
};


export type MedicineDescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MedicineDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MedicineImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MedicineVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MedicineCategory = {
  allMedicines: Array<Medicine>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  disease: Maybe<Disease>;
  id: Scalars['ID']['output'];
  introduction: Scalars['String']['output'];
  key: MedicineCategoryKey;
  medicines: MedicineConnection;
  minCost: Maybe<Scalars['Int']['output']>;
  minCostForSubscription: Maybe<Scalars['Int']['output']>;
  minCostMedicineVariant: Maybe<MedicineVariant>;
  minCostMedicineVariantForSubscription: Maybe<MedicineVariant>;
  name: Scalars['String']['output'];
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  subtitle: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type MedicineCategoryMedicinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for MedicineCategory. */
export type MedicineCategoryConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineCategoryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineCategoryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineCategory>;
};

export enum MedicineCategoryKey {
  ColdnessSwellingKampo = 'COLDNESS_SWELLING_KAMPO',
  EmergencyContraceptive = 'EMERGENCY_CONTRACEPTIVE',
  FemaleAga = 'FEMALE_AGA',
  Laxatives = 'LAXATIVES',
  LaxativesObesityKampo = 'LAXATIVES_OBESITY_KAMPO',
  LowDoseOralContraceptive = 'LOW_DOSE_ORAL_CONTRACEPTIVE',
  MedicalDiet = 'MEDICAL_DIET',
  MedicalSkinCare = 'MEDICAL_SKIN_CARE',
  MediumDoseOralContraceptive = 'MEDIUM_DOSE_ORAL_CONTRACEPTIVE',
  MenstrualSymptomsKampo = 'MENSTRUAL_SYMPTOMS_KAMPO',
  SexuallyTransmittedInfectionTestKit = 'SEXUALLY_TRANSMITTED_INFECTION_TEST_KIT',
  SleepAidMedicines = 'SLEEP_AID_MEDICINES'
}

export type MedicineCategorySearch = {
  diseaseSuspended?: InputMaybe<Scalars['Boolean']['input']>;
  keys?: InputMaybe<Array<MedicineCategoryKey>>;
};

/** The connection type for Medicine. */
export type MedicineConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Medicine>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MedicineDescription = {
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  medicine: Medicine;
  title: Scalars['String']['output'];
};

/** The connection type for MedicineDescription. */
export type MedicineDescriptionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineDescriptionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineDescription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineDescriptionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineDescription>;
};

export enum MedicineDiscount {
  Campaign = 'CAMPAIGN',
  Nothing = 'NOTHING',
  Student = 'STUDENT'
}

/** An edge in a connection. */
export type MedicineEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Medicine>;
};

export enum MedicineProductType {
  Medicine = 'MEDICINE',
  PrescriptionFee = 'PRESCRIPTION_FEE',
  ShippingFee = 'SHIPPING_FEE'
}

export type MedicineRecommendation = {
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  medicineCategory: MedicineCategory;
  title: Scalars['String']['output'];
};

/** The connection type for MedicineRecommendation. */
export type MedicineRecommendationConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineRecommendationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineRecommendation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineRecommendationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineRecommendation>;
};

export type MedicineRecommendationSurvey = {
  allQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  medicineRecommendation: Maybe<MedicineRecommendation>;
  problemType: MedicineRecommendationSurveyProblemType;
  questions: QuestionnaireQuestionConnection;
  responder: Customer;
  state: QuestionnaireRespondentState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type MedicineRecommendationSurveyAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MedicineRecommendationSurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for MedicineRecommendationSurvey. */
export type MedicineRecommendationSurveyConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineRecommendationSurveyEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineRecommendationSurvey>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineRecommendationSurveyEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineRecommendationSurvey>;
};

export enum MedicineRecommendationSurveyProblemType {
  Contraception = 'CONTRACEPTION',
  Menstrual = 'MENSTRUAL'
}

export type MedicineSearch = {
  diseaseSuspended?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MedicineSubscription = {
  allInvoices: Array<Invoice>;
  allRenewalRecommendations: Array<MedicineSubscriptionRenewalRecommendation>;
  allRoutineCheckupSchedules: Array<RoutineCheckupSchedule>;
  allRoutineCheckups: Array<RoutineCheckup>;
  allSelectablePaymentPlans: Array<PaymentPlan>;
  canceledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelerType: Maybe<MedicineSubscriptionCancelerType>;
  continuableLimitsAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 担当医(現在定期便の担当となっている医師)を返却します */
  currentChargeDoctor: Doctor;
  customer: Customer;
  /**
   * 担当医(現在定期便の担当となっている医師)を返却します
   * @deprecated 処方医を参照したい場合は prescriber, 担当医を参照したい場合は current_charge_doctor を利用してください
   */
  doctor: Doctor;
  expiredAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  expiresOn: Maybe<Scalars['ISO8601Date']['output']>;
  firstInvoice: Invoice;
  id: Scalars['ID']['output'];
  invoices: InvoiceConnection;
  lastInvoice: Invoice;
  medicineCategory: Maybe<MedicineCategory>;
  medicineVariant: MedicineVariant;
  /** @deprecated nextUnpaidInvoice を利用してください */
  nextInvoice: Maybe<Invoice>;
  nextUnpaidInvoice: Maybe<Invoice>;
  nextUnshippedInvoice: Maybe<Invoice>;
  paymentPlan: Maybe<PaymentPlan>;
  paymentPlanDecided: Scalars['Boolean']['output'];
  /** 処方医(定期便を処方した医師)を返却します */
  prescriber: Doctor;
  prescription: Prescription;
  /** 低用量ピル12シート一括プランが選択可能であれば返却します */
  promotedPaymentPlan: Maybe<PaymentPlan>;
  /**
   * 担当医(現在定期便の担当となっている医師)を返却します
   * @deprecated current_charge_doctor を利用してください
   */
  renewalRecommendableChargeDoctor: Doctor;
  renewalRecommendationRequestEndsOn: Maybe<Scalars['ISO8601Date']['output']>;
  renewalRecommendationRequestStartsOn: Maybe<Scalars['ISO8601Date']['output']>;
  /** 定期便継続提案の自動送信が設定された日時 */
  renewalRecommendationScheduledSendingRequestedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  routineCheckupSchedules: RoutineCheckupScheduleConnection;
  routineCheckups: RoutineCheckupConnection;
  state: MedicineSubscriptionState;
};


export type MedicineSubscriptionAllInvoicesArgs = {
  orderBy?: InputMaybe<InvoiceOrder>;
  search?: InputMaybe<InvoiceSearch>;
};


export type MedicineSubscriptionInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceOrder>;
  search?: InputMaybe<InvoiceSearch>;
};


export type MedicineSubscriptionRoutineCheckupSchedulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MedicineSubscriptionRoutineCheckupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MedicineSubscriptionCancelSurvey = {
  /** 回答に従い分岐を考慮した質問リスト */
  allInteractiveQuestions: Array<QuestionnaireQuestion>;
  allQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  problemType: MedicineSubscriptionCancelSurveyProblemType;
  questions: QuestionnaireQuestionConnection;
  responder: Customer;
  state: QuestionnaireRespondentState;
  subscription: Maybe<MedicineSubscription>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type MedicineSubscriptionCancelSurveyAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MedicineSubscriptionCancelSurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for MedicineSubscriptionCancelSurvey. */
export type MedicineSubscriptionCancelSurveyConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineSubscriptionCancelSurveyEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineSubscriptionCancelSurvey>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineSubscriptionCancelSurveyEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineSubscriptionCancelSurvey>;
};

export enum MedicineSubscriptionCancelSurveyProblemType {
  AvoidSideEffects = 'AVOID_SIDE_EFFECTS',
  ChangeOfflineService = 'CHANGE_OFFLINE_SERVICE',
  ChangeOnlineService = 'CHANGE_ONLINE_SERVICE',
  Contraindications = 'CONTRAINDICATIONS',
  Dissatisfied = 'DISSATISFIED',
  DoctorUnsuitable = 'DOCTOR_UNSUITABLE',
  ForgetToTakeMedicine = 'FORGET_TO_TAKE_MEDICINE',
  Ineffective = 'INEFFECTIVE',
  IneffectiveMenstrualSymptoms = 'INEFFECTIVE_MENSTRUAL_SYMPTOMS',
  IneffectiveSkinProblems = 'INEFFECTIVE_SKIN_PROBLEMS',
  LeftoverMedicine = 'LEFTOVER_MEDICINE',
  MismatchBetweenSupplyAndDemand = 'MISMATCH_BETWEEN_SUPPLY_AND_DEMAND',
  MonetaryBurden = 'MONETARY_BURDEN',
  NoLongerNeed = 'NO_LONGER_NEED',
  OtherReason = 'OTHER_REASON',
  Stabilized = 'STABILIZED',
  TemporaryUse = 'TEMPORARY_USE',
  UseOfflineServiceTemporarily = 'USE_OFFLINE_SERVICE_TEMPORARILY'
}

export enum MedicineSubscriptionCancelerType {
  Admin = 'ADMIN',
  AutoCanceler = 'AUTO_CANCELER',
  Customer = 'CUSTOMER',
  Doctor = 'DOCTOR',
  RoutineCheckupResult = 'ROUTINE_CHECKUP_RESULT',
  UncompletedRoutineCheckup = 'UNCOMPLETED_ROUTINE_CHECKUP'
}

/** The connection type for MedicineSubscription. */
export type MedicineSubscriptionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineSubscriptionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineSubscriptionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineSubscription>;
};

export type MedicineSubscriptionOrder = {
  direction: OrderDirection;
  field: MedicineSubscriptionOrderField;
};

export enum MedicineSubscriptionOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID'
}

export type MedicineSubscriptionRenewalRecommendation = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  doctor: Doctor;
  id: Scalars['ID']['output'];
  medicineSubscription: MedicineSubscription;
  message: Scalars['String']['output'];
  outcomeExamination: Maybe<Examination>;
  sendingType: MedicineSubscriptionRenewalRecommendationSendingType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userRenewalType: MedicineSubscriptionRenewalRecommendationUserRenewalType;
};

export enum MedicineSubscriptionRenewalRecommendationSendingType {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

export enum MedicineSubscriptionRenewalRecommendationUserRenewalType {
  Change = 'CHANGE',
  NoChange = 'NO_CHANGE',
  Skip = 'SKIP',
  Undefined = 'UNDEFINED'
}

export type MedicineSubscriptionSearch = {
  states?: InputMaybe<Array<MedicineSubscriptionState>>;
};

export enum MedicineSubscriptionState {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED'
}

export type MedicineVariant = {
  abbreviation: Scalars['String']['output'];
  /** 保有しているプランSKUのリスト。自身が定期便SKUではない場合、空配列 */
  allChildVariants: Array<MedicineVariant>;
  /** 保有している定期便プランのリスト。自身が定期便SKUではない場合、空配列 */
  allPaymentPlans: Array<PaymentPlan>;
  /** 薬価+処方料 ユーザーが支払う価格 */
  cost: Scalars['Int']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  days: Scalars['Int']['output'];
  /** 割引タイプ */
  discountType: MedicineDiscount;
  id: Scalars['ID']['output'];
  /** ロットと紐付く定期便SKUかどうか */
  isLotItem: Scalars['Boolean']['output'];
  isSubscriptionSku: Scalars['Boolean']['output'];
  lots: Scalars['Int']['output'];
  /** 決済あたりの最高値 */
  maxPricePerOnePayment: Maybe<Scalars['Int']['output']>;
  medicine: Medicine;
  /** 薬剤名 */
  medicineName: Scalars['String']['output'];
  /** ロットあたりの最安値 */
  minPricePerOneLot: Maybe<Scalars['Int']['output']>;
  /** 決済あたりの最安値 */
  minPricePerOnePayment: Maybe<Scalars['Int']['output']>;
  parentMedicineVariant: Maybe<MedicineVariant>;
  /** 属している定期便プランの情報。自身がプランに属するSKUではない場合、NULL */
  paymentPlan: Maybe<PaymentPlan>;
  /** 処方料 */
  prescriptionFee: Scalars['Int']['output'];
  prescriptionName: Scalars['String']['output'];
  /** 薬価 */
  price: Scalars['Int']['output'];
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  publishedToCustomer: Scalars['Boolean']['output'];
  publishedToDoctor: Scalars['Boolean']['output'];
  quantityText: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for MedicineVariant. */
export type MedicineVariantConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<MedicineVariantEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<MedicineVariant>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MedicineVariantEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<MedicineVariant>;
};

/** Autogenerated input type of MergeZendeskUser */
export type MergeZendeskUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ZendeskUser.id */
  id: Scalars['ID']['input'];
  /** ZendeskUser.id or ZendeskUser.email */
  purgedZendeskUserKey: Scalars['String']['input'];
};

export type MicroCmsImage = {
  height: Scalars['Int']['output'];
  url: Scalars['Url']['output'];
  width: Scalars['Int']['output'];
};

export type MicroCmsOrder = {
  direction: OrderDirection;
  field: MicroCmsOrderField;
};

export enum MicroCmsOrderField {
  CreatedAt = 'CREATED_AT',
  PublishedAt = 'PUBLISHED_AT',
  RevisedAt = 'REVISED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type Mutation = {
  /** 対象サービスのすべての同意事項に同意します。 */
  agreeAllServiceContracts: Maybe<User>;
  /** 対象サービスの指定した同意事項に同意します。 */
  agreeServiceContract: Maybe<User>;
  /** 処方提案を承認します。 */
  approvePrescription: Maybe<Prescription>;
  /** 医師の稼働枠を医師に割り当てます。 */
  assignDoctorMonitoringTime: Maybe<Doctor>;
  /** 指定した管理者に権限を付与します。 */
  attachAdminRole: Maybe<Admin>;
  /** ユーザーに appsflyer_id を紐付けます。すでに同じ appsflyer_id が紐付けられていた場合は何もしません。 */
  attachAppsflyerIdToUser: Maybe<AttachAppsflyerIdToUserPayload>;
  /** 指定した医師にリモート診察研修修了証を登録します。 */
  attachCertificateOfTelemedTraining: Maybe<Doctor>;
  /** 指定した医師に権限を付与します。 */
  attachDoctorRole: Maybe<Doctor>;
  /** 指定した問い合わせカテゴリに画像を添付します。画像のサイズは302x320を推奨しています。 */
  attachInquiryCategoryImage: Maybe<InquiryCategory>;
  /** 薬剤に画像を添付します。 */
  attachMedicineImage: Maybe<Medicine>;
  /** プロフィールに画像を添付します。 */
  attachProfileImage: Maybe<Profile>;
  /** Zoom OAuth認証情報をユーザーに設定します。 */
  attachUserZoomAuthCredential: Maybe<AttachUserZoomAuthCredentialPayload>;
  /**
   * ZoomのAPIキー、APIシークレットをユーザーに設定します。
   * @deprecated AttachZoomAuthCredential を利用してください
   */
  attachUserZoomSecret: Maybe<User>;
  /**
   * 指定したユーザーのZendeskユーザーにタグを追加します。
   * タグの追加処理は非同期で実行されます。そのため、返り値 tags は指定したタグをそのまま返します。
   */
  attachZendeskUserTags: Maybe<AttachZendeskUserTagsPayload>;
  /** Not implemented */
  auditInvoice: Maybe<Invoice>;
  /**
   * メールアドレスとパスワードでログインを行います。
   * 必ずユーザータイプを設定する必要があります。
   */
  authenticateUserWithEmailPassword: Maybe<AuthToken>;
  /** クーポンの利用条件テキストを生成します。 */
  buildCouponConditionDescription: Maybe<BuildCouponConditionDescriptionPayload>;
  /** 禁止ワードを一括登録します。 */
  bulkCreateBlockedWord: Maybe<Scalars['Boolean']['output']>;
  /** 配送業者の出力したCSVを利用して請求を一括発送します。 */
  bulkShipInvoiceByCsv: Maybe<BulkShipInvoiceByCsvPayload>;
  /** 診察予約をキャンセルします。 */
  cancelExaminationAppointment: Maybe<CancelExaminationAppointmentPayload>;
  /** Not implemented */
  cancelInvoice: Maybe<CancelInvoicePayload>;
  /**
   * 定期便契約を解約します。
   * 同時に、定期便に紐付く未払いの請求もすべてキャンセルします。
   */
  cancelMedicineSubscription: Maybe<MedicineSubscription>;
  /** 診察予約の開始日時を変更します。合わせて診療科目を変更することもできますが、問診が再作成されるため、問診の回答をやりなおす必要があります。 */
  changeExaminationAppointmentSchedule: Maybe<ChangeExaminationAppointmentSchedulePayload>;
  /** 指定したよくある質問の位置を変更します。 */
  changeFrequentlyAskedQuestionPosition: Maybe<FrequentlyAskedQuestion>;
  /** 指定した問い合わせカテゴリの位置を変更します。 */
  changeInquiryCategoryPosition: Maybe<InquiryCategory>;
  /**
   * メールアドレスの変更手続きを完了し、新しいメールアドレスを反映します。
   * 反映でき次第、Zendeskアカウントへも同期されます。
   */
  changeUserEmail: Maybe<ChangeUserEmailPayload>;
  /** 指定したユーザーのパスワードを変更します。 */
  changeUserPassword: Maybe<ChangeUserPasswordPayload>;
  /** 服薬カレンダーを終了します。 */
  closeMedicationDiary: Maybe<MedicationDiary>;
  /** 問診の回答を完了します。 */
  completeInterview: Maybe<Interview>;
  /** 診察予約管理レコードを医師アサイン待ち(予約確認完了)状態に遷移させます。 */
  completeReviewExaminationAppointmentOperation: Maybe<CompleteReviewExaminationAppointmentOperationPayload>;
  /** 指定した定期問診の回答を完了します。 */
  completeRoutineCheckupInterview: Maybe<CompleteRoutineCheckupInterviewPayload>;
  /**
   * 退会アンケートを開始します。
   * 旧アンケートは problemType を指定してください。
   */
  createAccountDeletionSurvey: Maybe<AccountDeletionSurvey>;
  /** 退会アンケートに回答を送信します。 */
  createAccountDeletionSurveyAnswer: Maybe<CreateAccountDeletionSurveyAnswerPayload>;
  /**
   * 管理者を作成します。
   * すでに医師や顧客として登録されているメールアドレスの場合、管理者権限が付与されます。
   */
  createAdmin: Maybe<Admin>;
  /**
   * AtoneTransactionを作成します。
   * 決済モジュールの起動に必要なパラメータも返却されます。
   */
  createAtoneTransaction: Maybe<CreateAtoneTransactionPayload>;
  /** 医療機関を登録します。 */
  createClinic: Maybe<Clinic>;
  /** 医師と医療機関を紐付けます。 */
  createClinicDoctor: Maybe<CreateClinicDoctorPayload>;
  /** クーポンを作成します。 */
  createCoupon: Maybe<Coupon>;
  /** 指定したクーポンのアクティベーションコードを発行します。 */
  createCouponActivationCode: Maybe<CouponActivationCode>;
  /**
   * 顧客メモを作成します。
   * 指定する writerRole によって利用できるユーザータイプやロールが変化するので注意してください。
   * * MEDICAL_STAFF の場合：PHARMACIST, DMH_OPERATOR
   * * DOCTOR の場合：医師（DOCTORユーザータイプ）, DMH_DOCTOR
   * * SUPPORT の場合：OPERATOR
   */
  createCustomerNote: Maybe<CustomerNote>;
  /**
   * 診察室経由で顧客メモを作成します。
   * 診察予約に紐づくサイトコントローラー医師氏名がwriterNameに書き込まれます。
   */
  createCustomerNoteViaExamination: Maybe<CreateCustomerNoteViaExaminationPayload>;
  /** 旧CSチャットに画像を送信します。 */
  createCustomerSupportFileMessage: Maybe<CustomerSupportFileMessage>;
  /**
   * 旧CSチャットにメッセージを送信します。
   * @deprecated CreateCustomerSupportTextMessage, CreateCustomerSupportFileMessage を利用してください。
   */
  createCustomerSupportMessage: Maybe<CustomerSupportMessage>;
  /** 旧CSチャットにテキストメッセージを送信します。 */
  createCustomerSupportTextMessage: Maybe<CustomerSupportTextMessage>;
  /** 請求に配送追跡情報を登録します。 */
  createDeliveryTracking: Maybe<DeliveryTracking>;
  /** 疾患マスターを登録します。 */
  createDisease: Maybe<Disease>;
  /** 医師を登録します。 */
  createDoctor: Maybe<Doctor>;
  /** 指定した医師の休診設定を登録します。 */
  createDoctorHoliday: Maybe<DoctorHoliday>;
  createDoctorOperatingTime: Maybe<CreateDoctorOperatingTimePayload>;
  /** 医師と医療機関を同時に登録します。登録した医師は登録した医療機関に所属します。 */
  createDoctorWithClinic: Maybe<Doctor>;
  /** 指定した問診を提出し、診察を開始します。 */
  createExamination: Maybe<Examination>;
  /** 診察予約を作成します。予約に成功した場合、同時に問診も作成されます。 */
  createExaminationAppointment: Maybe<CreateExaminationAppointmentPayload>;
  /**
   * 診察予約に紐付くビデオ通話を作成します。
   * 処方に関する問い合わせIDを指定する場合、診察が終了している必要があります。
   */
  createExaminationAppointmentVideoCall: Maybe<CreateExaminationAppointmentVideoCallPayload>;
  /**
   * 診察チャットに画像を送信します。
   * GraphQL multipart request specification に則ったMutationです。
   */
  createExaminationFileMessage: Maybe<ExaminationFileMessage>;
  /** 指定した診察に服薬指導を登録します。 */
  createExaminationGuidance: Maybe<ExaminationGuidance>;
  /**
   * 診察チャットにメッセージを送信します。
   * @deprecated CreateExaminationTextMessage, CreateExaminationFileMessage を利用してください。
   */
  createExaminationMessage: Maybe<ExaminationMessage>;
  /** 医師評価アンケートの設問に回答を送信します。 */
  createExaminationReviewAnswer: Maybe<QuestionnaireAnswer>;
  /** 診察終了理由を登録します。 */
  createExaminationTerminationReason: Maybe<CreateExaminationTerminationReasonPayload>;
  /** 診察チャットにテキストメッセージを送信します。 */
  createExaminationTextMessage: Maybe<ExaminationTextMessage>;
  /** 指定した問診を提出し、おまかせ医師選択で診察を開始します。 */
  createExaminationWithMonitoringDoctor: Maybe<Examination>;
  /** よくある質問を登録します。 */
  createFrequentlyAskedQuestion: Maybe<FrequentlyAskedQuestion>;
  /** 問い合わせカテゴリを登録します。 */
  createInquiryCategory: Maybe<InquiryCategory>;
  /**
   * 問診を作成します。
   * @deprecated 廃止されました
   */
  createInterview: Maybe<Interview>;
  /** 問診の住所タイプの設問に回答を送信します。 */
  createInterviewAddressAnswer: Maybe<CreateInterviewAddressAnswerPayload>;
  /**
   * 問診の設問に回答を送信します。
   * @deprecated CreateInterviewTextAnswer, CreateInterviewSingleSelectAnswer, CreateInterviewMultipleSelectAnswer を利用してください。
   */
  createInterviewAnswer: Maybe<CreateInterviewAnswerPayload>;
  /** 問診のBMIタイプの設問に回答を送信します。 */
  createInterviewBmiAnswer: Maybe<CreateInterviewBmiAnswerPayload>;
  /** 問診の日付タイプの設問に回答を送信します。 */
  createInterviewDateAnswer: Maybe<CreateInterviewDateAnswerPayload>;
  /** 問診の日時タイプの設問に回答を送信します。 */
  createInterviewDateTimeAnswer: Maybe<CreateInterviewDateTimeAnswerPayload>;
  /** 問診の複数選択タイプの設問に回答を送信します。 */
  createInterviewMultipleSelectAnswer: Maybe<CreateInterviewMultipleSelectAnswerPayload>;
  /** 問診の単一選択タイプの設問に回答を送信します。 */
  createInterviewSingleSelectAnswer: Maybe<CreateInterviewSingleSelectAnswerPayload>;
  /** 問診の自由記述タイプの設問に回答を送信します。 */
  createInterviewTextAnswer: Maybe<CreateInterviewTextAnswerPayload>;
  /**
   * 請求にユーザーの持つクーポンを適用します。
   * 請求は決済待ちの状態である必要があります。
   * ユーザーの持つクーポンは期限内かつ未使用である必要があります。
   * 利用条件が設定されている場合、利用条件を満たす必要があります。
   * クーポン適用によって決済金額が0円になる場合、支払い方法が全額クーポン払いに変更され、設定済みの支払い方法は次回以降の支払い方法として設定されます。
   * クーポン変更によって決済金額が0円から0円以外になる場合、支払い方法が次回以降の支払い方法から復元されます。
   */
  createInvoiceUserCoupon: Maybe<CreateInvoiceUserCouponPayload>;
  /** 複数の請求に配送追跡情報を登録します。 */
  createManyDeliveryTracking: Maybe<Array<DeliveryTracking>>;
  /** CSVを利用してZendeskチケットを一括作成します。 */
  createManyZendeskTicketsByCsv: Maybe<ZendeskTicketBulkCreateReport>;
  /** 医療相談室に画像メッセージを送信します。 */
  createMedicalConsultationFileMessage: Maybe<MedicalConsultationFileMessage>;
  /**
   * 医療相談室にメッセージを送信します。
   * @deprecated CreateMedicalConsultationTextMessage, CreateMedicalConsultationFileMessage を利用してください。
   */
  createMedicalConsultationMessage: Maybe<MedicalConsultationMessage>;
  /** 医療相談室にテキストメッセージを送信します。 */
  createMedicalConsultationTextMessage: Maybe<MedicalConsultationTextMessage>;
  /** 服薬カレンダーを作成します。 */
  createMedicationDiary: Maybe<MedicationDiary>;
  /** 指定した服薬カレンダーに服薬記録を送信します。 */
  createMedicationDiaryRecord: Maybe<MedicationDiaryRecord>;
  /** 薬剤に説明文を追加します。 */
  createMedicineDescription: Maybe<MedicineDescription>;
  /**
   * 薬剤に資料を添付します。
   * PDFファイルのみ受け付けます。
   */
  createMedicineDocument: Maybe<Medicine>;
  /** ピルチェックを開始します。 */
  createMedicineRecommendationSurvey: Maybe<MedicineRecommendationSurvey>;
  /** ピルチェックに回答を送信します。 */
  createMedicineRecommendationSurveyAnswer: Maybe<CreateMedicineRecommendationSurveyAnswerPayload>;
  /**
   * 定期便解約アンケートを開始します。
   * 旧アンケートは problemType を指定してください。
   * 新アンケートは解約対象の定期便IDを指定してください。
   */
  createMedicineSubscriptionCancelSurvey: Maybe<MedicineSubscriptionCancelSurvey>;
  /** 定期便解約アンケートに回答を送信します。 */
  createMedicineSubscriptionCancelSurveyAnswer: Maybe<CreateMedicineSubscriptionCancelSurveyAnswerPayload>;
  /**
   * 支払い元としてクレジットカードを登録します。
   * カード情報は直接受け取らずStripeで管理します。
   */
  createPaymentSource: Maybe<PaymentSource>;
  /** 処方提案を行います。 */
  createPrescription: Maybe<Prescription>;
  /** 処方問い合わせを作成します。 */
  createPrescriptionInquiry: Maybe<CreatePrescriptionInquiryPayload>;
  /** プロフィールを作成します。 */
  createProfile: Maybe<Profile>;
  /** リマインダーを追加します。 */
  createReminder: Maybe<Reminder>;
  /** 定期問診を開始します。 */
  createRoutineCheckup: Maybe<CreateRoutineCheckupPayload>;
  /** 定期問診のBMIタイプの設問に回答を送信します。 */
  createRoutineCheckupInterviewBmiAnswer: Maybe<CreateRoutineCheckupInterviewBmiAnswerPayload>;
  /** 定期問診の複数選択タイプの設問に回答を送信します。 */
  createRoutineCheckupInterviewMultipleSelectAnswer: Maybe<CreateRoutineCheckupInterviewMultipleSelectAnswerPayload>;
  /** 定期問診の単一選択タイプの設問に回答を送信します。 */
  createRoutineCheckupInterviewSingleSelectAnswer: Maybe<CreateRoutineCheckupInterviewSingleSelectAnswerPayload>;
  /** 対象サービスに同意事項を登録します。 */
  createServiceContract: Maybe<ServiceContract>;
  /** 新規登録アンケートに回答を送信します。 */
  createSignUpSurveyAnswer: Maybe<CreateSignUpSurveyAnswerPayload>;
  /** 指定されたユーザーに対するStripeのSetupIntentを作成します。 */
  createStripeSetupIntent: Maybe<CreateStripeSetupIntentPayload>;
  /** 指定したユーザーのアクセストークンを発行します。 */
  createUserAccessToken: Maybe<UserAccessToken>;
  /** 指定したユーザーに新しい住所を登録します。 */
  createUserAddress: Maybe<UserAddress>;
  /** アクティベーションコードを利用してユーザーにクーポンを割り当てます。 */
  createUserCoupon: Maybe<UserCoupon>;
  /** 指定したユーザーに電話番号を登録します。 */
  createUserPhone: Maybe<UserPhone>;
  /** 問題のある顧客報告アンケートを開始します。 */
  createUserProblemReport: Maybe<UserProblemReport>;
  /** 問題のある顧客報告アンケートに回答を送信します。 */
  createUserProblemReportAnswer: Maybe<QuestionnaireAnswer>;
  /** 指定したユーザーをオーナーとして、Zoomミーティングを作成します。指定されたユーザーにZoomアカウントの認証情報が設定されている必要があります。 */
  createUserZoomMeeting: Maybe<CreateUserZoomMeetingPayload>;
  /** WMSに注文データを送信します。 */
  createWmsOrder: Maybe<WmsOrder>;
  /** 指定した問い合わせカテゴリのZendeskチケットを作成します。 */
  createZendeskTicket: Maybe<ZendeskTicket>;
  /** Zendeskチケットにコメントを投稿します。 */
  createZendeskTicketComment: Maybe<ZendeskTicketAudit>;
  /** 指定したユーザーのZendeskユーザーを作成します。 */
  createZendeskUser: Maybe<ZendeskUser>;
  /** 指定した請求を決済失敗ステータスへ変更します。 */
  denyInvoice: Maybe<Invoice>;
  /** 処方提案を否認します。 */
  denyPrescription: Maybe<Prescription>;
  /** 管理者を削除します。 */
  destroyAdmin: Maybe<Admin>;
  /**
   * 指定した顧客の退会処理を行います。
   * 指定した顧客に契約中の定期便がある場合、定期便を解約します。指定した顧客に紐づくZendeskチケットはすべてクローズします。
   * 指定した顧客が医師あるいは管理者権限を持つ場合、診察中の場合、未払いの請求を持つ場合は削除できません。
   */
  destroyCustomer: Maybe<Customer>;
  /** 顧客メモを削除します。 */
  destroyCustomerNote: Maybe<CustomerNote>;
  /** 指定した休診設定を削除します。 */
  destroyDoctorHoliday: Maybe<DoctorHoliday>;
  destroyDoctorOperatingTime: Maybe<DestroyDoctorOperatingTimePayload>;
  /**
   * 医師評価を削除します。
   * 本番環境以外でのみ利用可能なデバッグ用のMutationです。
   */
  destroyExaminationReview: Maybe<ExaminationReview>;
  /** 指定したよくある質問を削除します。 */
  destroyFrequentlyAskedQuestion: Maybe<FrequentlyAskedQuestion>;
  /** 指定した問い合わせカテゴリを削除します。 */
  destroyInquiryCategory: Maybe<InquiryCategory>;
  /**
   * 請求へのクーポンの適用を取り消します。
   * 請求は決済待ちの状態である必要があります。
   * クーポンは未使用である必要があります。
   * 請求に全額クーポン払いが設定されている場合、クーポン払いは解除され、支払い方法が次回以降の支払い方法から復元されます。
   */
  destroyInvoiceUserCoupon: Maybe<DestroyInvoiceUserCouponPayload>;
  /** 薬剤から指定した説明文を削除します。 */
  destroyMedicineDescription: Maybe<MedicineDescription>;
  /** 指定したクレジットカードを削除します。 */
  destroyPaymentSource: Maybe<DestroyPaymentSourcePayload>;
  /** 指定したリマインダーを削除します。 */
  destroyReminder: Maybe<Reminder>;
  /** アクセストークンを削除します。 */
  destroyUserAccessToken: Maybe<UserAccessToken>;
  /**
   * 指定した住所を削除します。
   * 未払いの請求に配送先として設定されている場合は削除できません。
   */
  destroyUserAddress: Maybe<UserAddress>;
  /** ユーザーに割り当てたクーポンを解除します。 */
  destroyUserCoupon: Maybe<UserCoupon>;
  /** 指定した管理者から権限を剥奪します。 */
  detachAdminRole: Maybe<Admin>;
  /** 指定した医師から権限を剥奪します。 */
  detachDoctorRole: Maybe<Doctor>;
  /** 薬剤から指定した添付資料を削除します。 */
  detachMedicineDocument: Maybe<Medicine>;
  /** 薬剤に添付した画像から指定した画像を削除します。 */
  detachMedicineImage: Maybe<Medicine>;
  /** 指定した顧客の友達招待を無効化します。 */
  disableCustomerInvitation: Maybe<Customer>;
  /** 決済制限機能を無効にします。 */
  disableInvoiceCustomerPaymentBlock: Maybe<DisableInvoiceCustomerPaymentBlockPayload>;
  /**
   * 診察予約に紐付くビデオ通話のイベントをエミュレートします。
   * STG環境のみ利用可能
   */
  emulateExaminationAppointmentVideoCallEvent: Maybe<EmulateExaminationAppointmentVideoCallEventPayload>;
  /** 決済制限機能を有効にします。 */
  enableInvoiceCustomerPaymentBlock: Maybe<EnableInvoiceCustomerPaymentBlockPayload>;
  /** 指定した顧客が問い合わせ元となるKARAKURIチャットボットのクライアントを取得します。 */
  fetchKarakuriChatbotClient: Maybe<FetchKarakuriChatbotClientPayload>;
  /**
   * 指定した請求の請求書面をPDFとして生成し、ダウンロードURLを返却します。
   * 支払い済みの請求のみ指定可能です。
   * スマルナforBiz決済の領収書は発行することができません。
   */
  generateReceipt: Maybe<Scalars['String']['output']>;
  /** 指定した医師を非公開にします。 */
  hideDoctor: Maybe<Doctor>;
  /** 指定したよくある質問を非公開にします。 */
  hideFrequentlyAskedQuestion: Maybe<FrequentlyAskedQuestion>;
  /** 指定した問い合わせカテゴリを非公開にします。 */
  hideInquiryCategory: Maybe<InquiryCategory>;
  /**
   * 指定した住所の住所クレンジング（Mutation.ReverifyAddress）において、住所クレンジングをスキップするように設定します。
   * 設定は24時間後に解除されます。
   */
  ignoreAddressVerificationDenied: Maybe<Address>;
  /** パスワードリセットトークンを発行し、トークンを記載したメールを指定した管理者へ送信します。 */
  issueAdminPasswordResetToken: Maybe<Admin>;
  /**
   * メールアドレスの変更手続きを開始します。
   * 新しいメールアドレス宛に確認URL記載のメールが送信されます。
   */
  issueUserEmailChangeToken: Maybe<IssueUserEmailChangeTokenPayload>;
  /**
   * メールアドレスの確認手続きを開始します。
   * メールアドレスを指定することで指定ユーザーのメールアドレスを同時に変更することができます。
   * ユーザーのメールアドレス、あるいは指定したメールアドレス宛に確認コード記載のメールが送信されます。
   */
  issueUserEmailVerificationCode: Maybe<IssueUserEmailVerificationCodePayload>;
  /**
   * メールアドレスの確認手続きを開始します。
   * メールアドレスを指定することで指定ユーザーのメールアドレスを同時に変更することができます。
   * ユーザーのメールアドレス、あるいは指定したメールアドレス宛に確認トークン記載のメールが送信されます。
   * @deprecated IssueUserEmailVerificationCode を利用してください
   */
  issueUserEmailVerificationToken: Maybe<IssueUserEmailVerificationTokenPayload>;
  /** パスワードリセットトークンを発行し、トークンが含まれたURLが記載されているメールを指定したメールアドレスのユーザーへ送信します。 */
  issueUserPasswordResetToken: Maybe<IssueUserPasswordResetTokenPayload>;
  /** Zendeskユーザーを統合します。 */
  mergeZendeskUser: Maybe<ZendeskUser>;
  /**
   * 指定した請求の決済処理を行います。NP後払いの場合、審査提出を行います。
   * 請求にクーポンがセットされている場合、クーポンを消費します。
   */
  payInvoice: Maybe<Invoice>;
  /**
   * 処方を確定し診察を終了します。
   * 同時に、処方の内容に合わせて請求を作成します。
   * 患者による実行の場合、prescriptionIdが必要です。
   */
  prescribeAndCloseExamination: Maybe<PrescribeAndCloseExaminationPayload>;
  /** 指定した医師を公開します。 */
  publishDoctor: Maybe<Doctor>;
  /** 指定したよくある質問を公開します。 */
  publishFrequentlyAskedQuestion: Maybe<FrequentlyAskedQuestion>;
  /** 指定した問い合わせカテゴリを公開します。 */
  publishInquiryCategory: Maybe<InquiryCategory>;
  /** 医師評価を行います。 */
  rateExamination: Maybe<ExaminationReview>;
  /** 未読医師メッセージの既読処理を行います。 */
  readExaminationMessages: Maybe<Examination>;
  /** 指定したZendeskチケットの既読処理を行います。 */
  readZendeskTicketComments: Maybe<ZendeskTicket>;
  /** 認証トークンをリフレッシュします。 */
  refreshAuthToken: Maybe<AuthToken>;
  /** Not implemented */
  refundInvoice: Maybe<Invoice>;
  /**
   * 指定した顧客に友達招待コードを登録します。
   * 本人確認廃止前は、本人確認済みの場合にクーポンを付与します。
   * 本人確認廃止後は、メールアドレス確認済みの場合にクーポンを付与します。
   */
  registerCustomerInvitation: Maybe<RegisterCustomerInvitationPayload>;
  /**
   * 指定したメールアドレスとパスワードで顧客を新規登録します。
   * パスワードは8文字以上、64文字以内の任意の文字列を入力してください。
   * 登録成功後、確認メールを登録メールアドレス宛に送信します。
   * オプションとして、utmParameter を指定すると流入経路の記録が可能です。
   */
  registerCustomerWithEmailPassword: Maybe<AuthToken>;
  /** ユーザーに端末情報を登録します。 */
  registerDevice: Maybe<Device>;
  /** ユーザーにPush通知トークンを登録します。 */
  registerPushNotificationToken: Maybe<PushNotification>;
  /**
   * Stripeのクレジットカード決済の手続きを開始します。
   * 指定した請求に紐づくStripeのPaymentIntentが作成されます。
   */
  requestInvoiceCreditCardPayment: Maybe<RequestInvoiceCreditCardPaymentPayload>;
  /** 診察予約管理レコードをユーザー連絡待ち状態に遷移させます。 */
  requestUserContactExaminationAppointmentOperation: Maybe<RequestUserContactExaminationAppointmentOperationPayload>;
  /** 指定した診療計画のコンテンツをすべてリセットします。 */
  resetAllExaminationPlanContent: Maybe<ExaminationPlan>;
  /** 指定したユーザーのZendeskチケット未読数をリセットします。 */
  resetCustomerZendeskTicketTotalUnreadCount: Maybe<ResetCustomerZendeskTicketTotalUnreadCountPayload>;
  /** 診療計画の対象コンテンツをリセットします。 */
  resetExaminationPlanContent: Maybe<ExaminationPlanContent>;
  /**
   * ユーザーのパスワードを指定した文字列にリセットします。
   * リセットするにはパスワードリセット手続きを進め、リセット用トークンを取得する必要があります。
   */
  resetUserPassword: Maybe<User>;
  /** 削除済みの顧客を復旧します。 */
  restoreCustomer: Maybe<Customer>;
  /**
   * 指定した住所の住所クレンジングを行います。
   * Address.verificationIgnored が設定されている場合、容認期限まで実行がスキップされます。
   */
  reverifyAddress: Maybe<ReverifyAddressPayload>;
  /** 指定した管理者から管理者権限をすべて剥奪します。 */
  revokeAdmin: Maybe<Admin>;
  /**
   * 定期便契約の決済プランを選択します。
   * すでに決済済みの請求がある、またはNP審査中の決済がある場合は選択できません。
   * 初回請求にクーポンがセットされておりプラン選択後に決済金額が0円になった場合、支払い方法は全額クーポン払いに変更されます。
   * すでに請求に設定されている支払い方法は次回以降の支払い方法として設定されます。
   */
  selectMedicineSubscriptionPaymentPlan: Maybe<SelectMedicineSubscriptionPaymentPlanPayload>;
  /** Not implemented */
  sendReceipt: Maybe<Invoice>;
  /** atoneの決済認証トークンをユーザーやatone取引レコードに紐付けて保存します */
  setAtoneAuthenticationToken: Maybe<SetAtoneAuthenticationTokenPayload>;
  /** 定期便継続提案の変更希望状態を設定します */
  setMedicineSubscriptionRenewalRecommendationUserRenewalType: Maybe<MedicineSubscriptionRenewalRecommendation>;
  /** 請求書を発送する。追跡番号は別途CSVで登録する必要がある */
  shipInvoice: Maybe<Invoice>;
  /** 指定した医師の稼働枠での稼働を開始します。 */
  startDoctorAssignedMonitoringTime: Maybe<Doctor>;
  /**
   * Stripeの銀行振込手続きを開始します。
   * 決済制限機能が有効になり、Stripeに銀行振込の請求が作成されます。
   */
  startInvoiceStripeBankTransfer: Maybe<StartInvoiceStripeBankTransferPayload>;
  /** eKYC手続きを開始します。現在利用できません。 */
  startKycRegistration: Maybe<StartKycRegistrationPayload>;
  /** 診察予約管理レコードを予約確認中状態に遷移させます。この際、リクエストした人が担当オペレータとしてアサインされます。 */
  startReviewExaminationAppointmentOperation: Maybe<StartReviewExaminationAppointmentOperationPayload>;
  /** 診察予約管理レコードをユーザー連絡対応中状態に遷移させます。この際、リクエストした人が担当オペレータとしてアサインされます。 */
  startUserContactExaminationAppointmentOperation: Maybe<StartUserContactExaminationAppointmentOperationPayload>;
  /** 指定したユーザーの本人確認書類を提出します。 */
  submitIdentification: Maybe<SubmitIdentificationPayload>;
  /** 診察予約管理レコードを保留・一時停止状態に遷移させます。 */
  suspendExaminationAppointmentOperation: Maybe<SuspendExaminationAppointmentOperationPayload>;
  /**
   * すべての問い合わせカテゴリをZendeskへ同期します。
   * ※現在、Zendeskのトリガー設定の影響で同期できません。
   */
  syncAllInquiryCategoryToZendesk: Maybe<Scalars['Boolean']['output']>;
  /** 処方せず診察を終了します。 */
  terminateExamination: Maybe<Examination>;
  /** 医師の稼働枠から医師を外します。 */
  unassignDoctorMonitoringTime: Maybe<Doctor>;
  /** 管理者の登録情報やプロフィールを更新します。 */
  updateAdmin: Maybe<Admin>;
  /** 医療機関の登録情報を更新します。 */
  updateClinic: Maybe<Clinic>;
  /** 医師が所属クリニックごとに設定する情報を更新します。 */
  updateClinicDoctor: Maybe<UpdateClinicDoctorPayload>;
  /** クーポンの登録情報を更新します。 */
  updateCoupon: Maybe<Coupon>;
  /** 指定したクーポンアクティベーションコードを更新します。 */
  updateCouponActivationCode: Maybe<UpdateCouponActivationCodePayload>;
  /**
   * 指定した顧客の登録情報を更新します。
   * 顧客自身がメールアドレスを変更するには issueUserEmailChangeToken からメールアドレス変更手続きを開始してください。
   */
  updateCustomer: Maybe<Customer>;
  /** 指定した顧客のニックネームを更新します。 */
  updateCustomerNickname: Maybe<UpdateCustomerNicknamePayload>;
  /** 顧客メモを更新します。 */
  updateCustomerNote: Maybe<CustomerNote>;
  /** 端末情報を更新します。 */
  updateDevice: Maybe<Device>;
  /** 指定した医師の登録情報を更新します。 */
  updateDoctor: Maybe<Doctor>;
  /**
   * 医師が担当可能な疾患リストを登録します。
   * 指定したリストで上書き登録されるので注意してください。
   */
  updateDoctorDiseases: Maybe<Doctor>;
  /** 診察予約管理レコードの医師からオペレーターへの申し送り事項を更新します。 */
  updateExaminationAppointmentOperationDoctorNote: Maybe<UpdateExaminationAppointmentOperationDoctorNotePayload>;
  /** 診察予約管理レコードのオペレーターから医師への申し送り事項を更新します。 */
  updateExaminationAppointmentOperationOperatorNote: Maybe<UpdateExaminationAppointmentOperationOperatorNotePayload>;
  /** 診療計画の対象コンテンツを更新します。 */
  updateExaminationPlanContent: Maybe<ExaminationPlanContent>;
  /** 診察終了理由を更新します。 */
  updateExaminationTerminationReason: Maybe<UpdateExaminationTerminationReasonPayload>;
  /** よくある質問の登録情報を更新します。 */
  updateFrequentlyAskedQuestion: Maybe<FrequentlyAskedQuestion>;
  /** 問い合わせカテゴリの登録情報を更新します。 */
  updateInquiryCategory: Maybe<InquiryCategory>;
  /**
   * 対象の問診の登録情報を更新します。
   * 医師や、やり取り方法を指定できます。
   */
  updateInterview: Maybe<Interview>;
  /**
   * 指定した請求の登録情報を更新します。
   * 支払い方法や配送先、次回以降の支払い方法の指定が可能です。
   * 管理者は決済済みの決済も更新可能です。
   * NP審査中の請求は更新できません。
   */
  updateInvoice: Maybe<Invoice>;
  /** 指定した薬剤の登録情報を更新します。 */
  updateMedicine: Maybe<Medicine>;
  /** 薬剤の説明文を更新します。 */
  updateMedicineDescription: Maybe<MedicineDescription>;
  /** 定期便契約の支払い情報や配送先情報を更新します。 */
  updateMedicineSubscription: Maybe<MedicineSubscription>;
  /** 薬剤SKUの登録情報を更新します。 */
  updateMedicineVariant: Maybe<MedicineVariant>;
  /** プロフィールを更新します。 */
  updateProfile: Maybe<Profile>;
  /** 指定したリマインダーの登録情報を更新します。 */
  updateReminder: Maybe<Reminder>;
  /** 新規登録アンケートを更新します。ニックネーム、誕生日、都道府県は必須です。 */
  updateSignUpSurvey: Maybe<UpdateSignUpSurveyPayload>;
  /**
   * 指定した住所を更新します。
   * 更新した住所には新しいIDが割り当てられます。
   */
  updateUserAddress: Maybe<UserAddress>;
  /** 指定したユーザーのメモ欄を更新します */
  updateUserAnnotation: Maybe<UpdateUserAnnotationPayload>;
  /** 指定したユーザーの電話番号を更新します。 */
  updateUserPhone: Maybe<UserPhone>;
  /** Zendeskユーザーの登録情報を更新します。 */
  updateZendeskUser: Maybe<ZendeskUser>;
  /** GraphQL multipart request specification に則ったMutationです */
  uploadTempfile: Maybe<UploadTempfilePayload>;
  /** 指定した住所文字列に対して住所クレンジングを実行します。 */
  verifyAddress: Maybe<VerifyAddressPayload>;
  /**
   * 取引登録成功/失敗時に決済モジュールのコールバックパラメータを受け取り、取引を検証します。
   * 取引登録成功し検証も成功した場合はatone取引レコードを与信OKステータスに更新し、請求を決済済みにします。
   * 取引登録失敗し検証が成功した場合はatone取引レコードを与信NGステータスに更新します。
   * 検証に失敗した場合はatone取引レコードをクローズステータスに更新します。
   */
  verifyAtoneTransaction: Maybe<VerifyAtoneTransactionPayload>;
  /**
   * メールアドレスの確認を完了します。
   * 対象ユーザーの登録メールアドレス宛に確認完了メールが送信されます。
   */
  verifyUserEmail: Maybe<VerifyUserEmailPayload>;
};


export type MutationAgreeAllServiceContractsArgs = {
  input: AgreeAllServiceContractsInput;
};


export type MutationAgreeServiceContractArgs = {
  input: AgreeServiceContractInput;
};


export type MutationApprovePrescriptionArgs = {
  input: ApprovePrescriptionInput;
};


export type MutationAssignDoctorMonitoringTimeArgs = {
  input: AssignDoctorMonitoringTimeInput;
};


export type MutationAttachAdminRoleArgs = {
  input: AttachAdminRoleInput;
};


export type MutationAttachAppsflyerIdToUserArgs = {
  input: AttachAppsflyerIdToUserInput;
};


export type MutationAttachCertificateOfTelemedTrainingArgs = {
  input: AttachCertificateOfTelemedTrainingInput;
};


export type MutationAttachDoctorRoleArgs = {
  input: AttachDoctorRoleInput;
};


export type MutationAttachInquiryCategoryImageArgs = {
  input: AttachInquiryCategoryImageInput;
};


export type MutationAttachMedicineImageArgs = {
  input: AttachMedicineImageInput;
};


export type MutationAttachProfileImageArgs = {
  input: AttachProfileImageInput;
};


export type MutationAttachUserZoomAuthCredentialArgs = {
  input: AttachUserZoomAuthCredentialInput;
};


export type MutationAttachUserZoomSecretArgs = {
  input: AttachUserZoomSecretInput;
};


export type MutationAttachZendeskUserTagsArgs = {
  input: AttachZendeskUserTagsInput;
};


export type MutationAuditInvoiceArgs = {
  input: AuditInvoiceInput;
};


export type MutationAuthenticateUserWithEmailPasswordArgs = {
  input: AuthenticateUserWithEmailPasswordInput;
};


export type MutationBuildCouponConditionDescriptionArgs = {
  input: BuildCouponConditionDescriptionInput;
};


export type MutationBulkCreateBlockedWordArgs = {
  input: BulkCreateBlockedWordInput;
};


export type MutationBulkShipInvoiceByCsvArgs = {
  input: BulkShipInvoiceByCsvInput;
};


export type MutationCancelExaminationAppointmentArgs = {
  input: CancelExaminationAppointmentInput;
};


export type MutationCancelInvoiceArgs = {
  input: CancelInvoiceInput;
};


export type MutationCancelMedicineSubscriptionArgs = {
  input: CancelMedicineSubscriptionInput;
};


export type MutationChangeExaminationAppointmentScheduleArgs = {
  input: ChangeExaminationAppointmentScheduleInput;
};


export type MutationChangeFrequentlyAskedQuestionPositionArgs = {
  input: ChangeFrequentlyAskedQuestionPositionInput;
};


export type MutationChangeInquiryCategoryPositionArgs = {
  input: ChangeInquiryCategoryPositionInput;
};


export type MutationChangeUserEmailArgs = {
  input: ChangeUserEmailInput;
};


export type MutationChangeUserPasswordArgs = {
  input: ChangeUserPasswordInput;
};


export type MutationCloseMedicationDiaryArgs = {
  input: CloseMedicationDiaryInput;
};


export type MutationCompleteInterviewArgs = {
  input: CompleteInterviewInput;
};


export type MutationCompleteReviewExaminationAppointmentOperationArgs = {
  input: CompleteReviewExaminationAppointmentOperationInput;
};


export type MutationCompleteRoutineCheckupInterviewArgs = {
  input: CompleteRoutineCheckupInterviewInput;
};


export type MutationCreateAccountDeletionSurveyArgs = {
  input: CreateAccountDeletionSurveyInput;
};


export type MutationCreateAccountDeletionSurveyAnswerArgs = {
  input: CreateAccountDeletionSurveyAnswerInput;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateAtoneTransactionArgs = {
  input: CreateAtoneTransactionInput;
};


export type MutationCreateClinicArgs = {
  input: CreateClinicInput;
};


export type MutationCreateClinicDoctorArgs = {
  input: CreateClinicDoctorInput;
};


export type MutationCreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationCreateCouponActivationCodeArgs = {
  input: CreateCouponActivationCodeInput;
};


export type MutationCreateCustomerNoteArgs = {
  input: CreateCustomerNoteInput;
};


export type MutationCreateCustomerNoteViaExaminationArgs = {
  input: CreateCustomerNoteViaExaminationInput;
};


export type MutationCreateCustomerSupportFileMessageArgs = {
  input: CreateCustomerSupportFileMessageInput;
};


export type MutationCreateCustomerSupportMessageArgs = {
  input: CreateCustomerSupportMessageInput;
};


export type MutationCreateCustomerSupportTextMessageArgs = {
  input: CreateCustomerSupportTextMessageInput;
};


export type MutationCreateDeliveryTrackingArgs = {
  input: CreateDeliveryTrackingInput;
};


export type MutationCreateDiseaseArgs = {
  input: CreateDiseaseInput;
};


export type MutationCreateDoctorArgs = {
  input: CreateDoctorInput;
};


export type MutationCreateDoctorHolidayArgs = {
  input: CreateDoctorHolidayInput;
};


export type MutationCreateDoctorOperatingTimeArgs = {
  input: CreateDoctorOperatingTimeInput;
};


export type MutationCreateDoctorWithClinicArgs = {
  input: CreateDoctorWithClinicInput;
};


export type MutationCreateExaminationArgs = {
  input: CreateExaminationInput;
};


export type MutationCreateExaminationAppointmentArgs = {
  input: CreateExaminationAppointmentInput;
};


export type MutationCreateExaminationAppointmentVideoCallArgs = {
  input: CreateExaminationAppointmentVideoCallInput;
};


export type MutationCreateExaminationFileMessageArgs = {
  input: CreateExaminationFileMessageInput;
};


export type MutationCreateExaminationGuidanceArgs = {
  input: CreateExaminationGuidanceInput;
};


export type MutationCreateExaminationMessageArgs = {
  input: CreateExaminationMessageInput;
};


export type MutationCreateExaminationReviewAnswerArgs = {
  input: CreateExaminationReviewAnswerInput;
};


export type MutationCreateExaminationTerminationReasonArgs = {
  input: CreateExaminationTerminationReasonInput;
};


export type MutationCreateExaminationTextMessageArgs = {
  input: CreateExaminationTextMessageInput;
};


export type MutationCreateExaminationWithMonitoringDoctorArgs = {
  input: CreateExaminationWithMonitoringDoctorInput;
};


export type MutationCreateFrequentlyAskedQuestionArgs = {
  input: CreateFrequentlyAskedQuestionInput;
};


export type MutationCreateInquiryCategoryArgs = {
  input: CreateInquiryCategoryInput;
};


export type MutationCreateInterviewArgs = {
  input: CreateInterviewInput;
};


export type MutationCreateInterviewAddressAnswerArgs = {
  input: CreateInterviewAddressAnswerInput;
};


export type MutationCreateInterviewAnswerArgs = {
  input: CreateInterviewAnswerInput;
};


export type MutationCreateInterviewBmiAnswerArgs = {
  input: CreateInterviewBmiAnswerInput;
};


export type MutationCreateInterviewDateAnswerArgs = {
  input: CreateInterviewDateAnswerInput;
};


export type MutationCreateInterviewDateTimeAnswerArgs = {
  input: CreateInterviewDateTimeAnswerInput;
};


export type MutationCreateInterviewMultipleSelectAnswerArgs = {
  input: CreateInterviewMultipleSelectAnswerInput;
};


export type MutationCreateInterviewSingleSelectAnswerArgs = {
  input: CreateInterviewSingleSelectAnswerInput;
};


export type MutationCreateInterviewTextAnswerArgs = {
  input: CreateInterviewTextAnswerInput;
};


export type MutationCreateInvoiceUserCouponArgs = {
  input: CreateInvoiceUserCouponInput;
};


export type MutationCreateManyDeliveryTrackingArgs = {
  input: CreateManyDeliveryTrackingInput;
};


export type MutationCreateManyZendeskTicketsByCsvArgs = {
  input: CreateManyZendeskTicketsByCsvInput;
};


export type MutationCreateMedicalConsultationFileMessageArgs = {
  input: CreateMedicalConsultationFileMessageInput;
};


export type MutationCreateMedicalConsultationMessageArgs = {
  input: CreateMedicalConsultationMessageInput;
};


export type MutationCreateMedicalConsultationTextMessageArgs = {
  input: CreateMedicalConsultationTextMessageInput;
};


export type MutationCreateMedicationDiaryArgs = {
  input: CreateMedicationDiaryInput;
};


export type MutationCreateMedicationDiaryRecordArgs = {
  input: CreateMedicationDiaryRecordInput;
};


export type MutationCreateMedicineDescriptionArgs = {
  input: CreateMedicineDescriptionInput;
};


export type MutationCreateMedicineDocumentArgs = {
  input: CreateMedicineDocumentInput;
};


export type MutationCreateMedicineRecommendationSurveyArgs = {
  input: CreateMedicineRecommendationSurveyInput;
};


export type MutationCreateMedicineRecommendationSurveyAnswerArgs = {
  input: CreateMedicineRecommendationSurveyAnswerInput;
};


export type MutationCreateMedicineSubscriptionCancelSurveyArgs = {
  input: CreateMedicineSubscriptionCancelSurveyInput;
};


export type MutationCreateMedicineSubscriptionCancelSurveyAnswerArgs = {
  input: CreateMedicineSubscriptionCancelSurveyAnswerInput;
};


export type MutationCreatePaymentSourceArgs = {
  input: CreatePaymentSourceInput;
};


export type MutationCreatePrescriptionArgs = {
  input: CreatePrescriptionInput;
};


export type MutationCreatePrescriptionInquiryArgs = {
  input: CreatePrescriptionInquiryInput;
};


export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};


export type MutationCreateReminderArgs = {
  input: CreateReminderInput;
};


export type MutationCreateRoutineCheckupArgs = {
  input: CreateRoutineCheckupInput;
};


export type MutationCreateRoutineCheckupInterviewBmiAnswerArgs = {
  input: CreateRoutineCheckupInterviewBmiAnswerInput;
};


export type MutationCreateRoutineCheckupInterviewMultipleSelectAnswerArgs = {
  input: CreateRoutineCheckupInterviewMultipleSelectAnswerInput;
};


export type MutationCreateRoutineCheckupInterviewSingleSelectAnswerArgs = {
  input: CreateRoutineCheckupInterviewSingleSelectAnswerInput;
};


export type MutationCreateServiceContractArgs = {
  input: CreateServiceContractInput;
};


export type MutationCreateSignUpSurveyAnswerArgs = {
  input: CreateSignUpSurveyAnswerInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};


export type MutationCreateUserAccessTokenArgs = {
  input: CreateUserAccessTokenInput;
};


export type MutationCreateUserAddressArgs = {
  input: CreateUserAddressInput;
};


export type MutationCreateUserCouponArgs = {
  input: CreateUserCouponInput;
};


export type MutationCreateUserPhoneArgs = {
  input: CreateUserPhoneInput;
};


export type MutationCreateUserProblemReportArgs = {
  input: CreateUserProblemReportInput;
};


export type MutationCreateUserProblemReportAnswerArgs = {
  input: CreateUserProblemReportAnswerInput;
};


export type MutationCreateUserZoomMeetingArgs = {
  input: CreateUserZoomMeetingInput;
};


export type MutationCreateWmsOrderArgs = {
  input: CreateWmsOrderInput;
};


export type MutationCreateZendeskTicketArgs = {
  input: CreateZendeskTicketInput;
};


export type MutationCreateZendeskTicketCommentArgs = {
  input: CreateZendeskTicketCommentInput;
};


export type MutationCreateZendeskUserArgs = {
  input: CreateZendeskUserInput;
};


export type MutationDenyInvoiceArgs = {
  input: DenyInvoiceInput;
};


export type MutationDenyPrescriptionArgs = {
  input: DenyPrescriptionInput;
};


export type MutationDestroyAdminArgs = {
  input: DestroyAdminInput;
};


export type MutationDestroyCustomerArgs = {
  input: DestroyCustomerInput;
};


export type MutationDestroyCustomerNoteArgs = {
  input: DestroyCustomerNoteInput;
};


export type MutationDestroyDoctorHolidayArgs = {
  input: DestroyDoctorHolidayInput;
};


export type MutationDestroyDoctorOperatingTimeArgs = {
  input: DestroyDoctorOperatingTimeInput;
};


export type MutationDestroyExaminationReviewArgs = {
  input: DestroyExaminationReviewInput;
};


export type MutationDestroyFrequentlyAskedQuestionArgs = {
  input: DestroyFrequentlyAskedQuestionInput;
};


export type MutationDestroyInquiryCategoryArgs = {
  input: DestroyInquiryCategoryInput;
};


export type MutationDestroyInvoiceUserCouponArgs = {
  input: DestroyInvoiceUserCouponInput;
};


export type MutationDestroyMedicineDescriptionArgs = {
  input: DestroyMedicineDescriptionInput;
};


export type MutationDestroyPaymentSourceArgs = {
  input: DestroyPaymentSourceInput;
};


export type MutationDestroyReminderArgs = {
  input: DestroyReminderInput;
};


export type MutationDestroyUserAccessTokenArgs = {
  input: DestroyUserAccessTokenInput;
};


export type MutationDestroyUserAddressArgs = {
  input: DestroyUserAddressInput;
};


export type MutationDestroyUserCouponArgs = {
  input: DestroyUserCouponInput;
};


export type MutationDetachAdminRoleArgs = {
  input: DetachAdminRoleInput;
};


export type MutationDetachDoctorRoleArgs = {
  input: DetachDoctorRoleInput;
};


export type MutationDetachMedicineDocumentArgs = {
  input: DetachMedicineDocumentInput;
};


export type MutationDetachMedicineImageArgs = {
  input: DetachMedicineImageInput;
};


export type MutationDisableCustomerInvitationArgs = {
  input: DisableCustomerInvitationInput;
};


export type MutationDisableInvoiceCustomerPaymentBlockArgs = {
  input: DisableInvoiceCustomerPaymentBlockInput;
};


export type MutationEmulateExaminationAppointmentVideoCallEventArgs = {
  input: EmulateExaminationAppointmentVideoCallEventInput;
};


export type MutationEnableInvoiceCustomerPaymentBlockArgs = {
  input: EnableInvoiceCustomerPaymentBlockInput;
};


export type MutationFetchKarakuriChatbotClientArgs = {
  input: FetchKarakuriChatbotClientInput;
};


export type MutationGenerateReceiptArgs = {
  input: GenerateReceiptInput;
};


export type MutationHideDoctorArgs = {
  input: HideDoctorInput;
};


export type MutationHideFrequentlyAskedQuestionArgs = {
  input: HideFrequentlyAskedQuestionInput;
};


export type MutationHideInquiryCategoryArgs = {
  input: HideInquiryCategoryInput;
};


export type MutationIgnoreAddressVerificationDeniedArgs = {
  input: IgnoreAddressVerificationDeniedInput;
};


export type MutationIssueAdminPasswordResetTokenArgs = {
  input: IssueAdminPasswordResetTokenInput;
};


export type MutationIssueUserEmailChangeTokenArgs = {
  input: IssueUserEmailChangeTokenInput;
};


export type MutationIssueUserEmailVerificationCodeArgs = {
  input: IssueUserEmailVerificationCodeInput;
};


export type MutationIssueUserEmailVerificationTokenArgs = {
  input: IssueUserEmailVerificationTokenInput;
};


export type MutationIssueUserPasswordResetTokenArgs = {
  input: IssueUserPasswordResetTokenInput;
};


export type MutationMergeZendeskUserArgs = {
  input: MergeZendeskUserInput;
};


export type MutationPayInvoiceArgs = {
  input: PayInvoiceInput;
};


export type MutationPrescribeAndCloseExaminationArgs = {
  input: PrescribeAndCloseExaminationInput;
};


export type MutationPublishDoctorArgs = {
  input: PublishDoctorInput;
};


export type MutationPublishFrequentlyAskedQuestionArgs = {
  input: PublishFrequentlyAskedQuestionInput;
};


export type MutationPublishInquiryCategoryArgs = {
  input: PublishInquiryCategoryInput;
};


export type MutationRateExaminationArgs = {
  input: RateExaminationInput;
};


export type MutationReadExaminationMessagesArgs = {
  input: ReadExaminationMessagesInput;
};


export type MutationReadZendeskTicketCommentsArgs = {
  input: ReadZendeskTicketCommentsInput;
};


export type MutationRefreshAuthTokenArgs = {
  input: RefreshAuthTokenInput;
};


export type MutationRefundInvoiceArgs = {
  input: RefundInvoiceInput;
};


export type MutationRegisterCustomerInvitationArgs = {
  input: RegisterCustomerInvitationInput;
};


export type MutationRegisterCustomerWithEmailPasswordArgs = {
  input: RegisterCustomerWithEmailPasswordInput;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};


export type MutationRegisterPushNotificationTokenArgs = {
  input: RegisterPushNotificationTokenInput;
};


export type MutationRequestInvoiceCreditCardPaymentArgs = {
  input: RequestInvoiceCreditCardPaymentInput;
};


export type MutationRequestUserContactExaminationAppointmentOperationArgs = {
  input: RequestUserContactExaminationAppointmentOperationInput;
};


export type MutationResetAllExaminationPlanContentArgs = {
  input: ResetAllExaminationPlanContentInput;
};


export type MutationResetCustomerZendeskTicketTotalUnreadCountArgs = {
  input: ResetCustomerZendeskTicketTotalUnreadCountInput;
};


export type MutationResetExaminationPlanContentArgs = {
  input: ResetExaminationPlanContentInput;
};


export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};


export type MutationRestoreCustomerArgs = {
  input: RestoreCustomerInput;
};


export type MutationReverifyAddressArgs = {
  input: ReverifyAddressInput;
};


export type MutationRevokeAdminArgs = {
  input: RevokeAdminInput;
};


export type MutationSelectMedicineSubscriptionPaymentPlanArgs = {
  input: SelectMedicineSubscriptionPaymentPlanInput;
};


export type MutationSendReceiptArgs = {
  input: SendReceiptInput;
};


export type MutationSetAtoneAuthenticationTokenArgs = {
  input: SetAtoneAuthenticationTokenInput;
};


export type MutationSetMedicineSubscriptionRenewalRecommendationUserRenewalTypeArgs = {
  input: SetMedicineSubscriptionRenewalRecommendationUserRenewalTypeInput;
};


export type MutationShipInvoiceArgs = {
  input: ShipInvoiceInput;
};


export type MutationStartDoctorAssignedMonitoringTimeArgs = {
  input: StartDoctorAssignedMonitoringTimeInput;
};


export type MutationStartInvoiceStripeBankTransferArgs = {
  input: StartInvoiceStripeBankTransferInput;
};


export type MutationStartKycRegistrationArgs = {
  input: StartKycRegistrationInput;
};


export type MutationStartReviewExaminationAppointmentOperationArgs = {
  input: StartReviewExaminationAppointmentOperationInput;
};


export type MutationStartUserContactExaminationAppointmentOperationArgs = {
  input: StartUserContactExaminationAppointmentOperationInput;
};


export type MutationSubmitIdentificationArgs = {
  input: SubmitIdentificationInput;
};


export type MutationSuspendExaminationAppointmentOperationArgs = {
  input: SuspendExaminationAppointmentOperationInput;
};


export type MutationSyncAllInquiryCategoryToZendeskArgs = {
  input: SyncAllInquiryCategoryToZendeskInput;
};


export type MutationTerminateExaminationArgs = {
  input: TerminateExaminationInput;
};


export type MutationUnassignDoctorMonitoringTimeArgs = {
  input: UnassignDoctorMonitoringTimeInput;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};


export type MutationUpdateClinicArgs = {
  input: UpdateClinicInput;
};


export type MutationUpdateClinicDoctorArgs = {
  input: UpdateClinicDoctorInput;
};


export type MutationUpdateCouponArgs = {
  input: UpdateCouponInput;
};


export type MutationUpdateCouponActivationCodeArgs = {
  input: UpdateCouponActivationCodeInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerNicknameArgs = {
  input: UpdateCustomerNicknameInput;
};


export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput;
};


export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};


export type MutationUpdateDoctorArgs = {
  input: UpdateDoctorInput;
};


export type MutationUpdateDoctorDiseasesArgs = {
  input: UpdateDoctorDiseasesInput;
};


export type MutationUpdateExaminationAppointmentOperationDoctorNoteArgs = {
  input: UpdateExaminationAppointmentOperationDoctorNoteInput;
};


export type MutationUpdateExaminationAppointmentOperationOperatorNoteArgs = {
  input: UpdateExaminationAppointmentOperationOperatorNoteInput;
};


export type MutationUpdateExaminationPlanContentArgs = {
  input: UpdateExaminationPlanContentInput;
};


export type MutationUpdateExaminationTerminationReasonArgs = {
  input: UpdateExaminationTerminationReasonInput;
};


export type MutationUpdateFrequentlyAskedQuestionArgs = {
  input: UpdateFrequentlyAskedQuestionInput;
};


export type MutationUpdateInquiryCategoryArgs = {
  input: UpdateInquiryCategoryInput;
};


export type MutationUpdateInterviewArgs = {
  input: UpdateInterviewInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateMedicineArgs = {
  input: UpdateMedicineInput;
};


export type MutationUpdateMedicineDescriptionArgs = {
  input: UpdateMedicineDescriptionInput;
};


export type MutationUpdateMedicineSubscriptionArgs = {
  input: UpdateMedicineSubscriptionInput;
};


export type MutationUpdateMedicineVariantArgs = {
  input: UpdateMedicineVariantInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateReminderArgs = {
  input: UpdateReminderInput;
};


export type MutationUpdateSignUpSurveyArgs = {
  input: UpdateSignUpSurveyInput;
};


export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput;
};


export type MutationUpdateUserAnnotationArgs = {
  input: UpdateUserAnnotationInput;
};


export type MutationUpdateUserPhoneArgs = {
  input: UpdateUserPhoneInput;
};


export type MutationUpdateZendeskUserArgs = {
  input: UpdateZendeskUserInput;
};


export type MutationUploadTempfileArgs = {
  input: UploadTempfileInput;
};


export type MutationVerifyAddressArgs = {
  input: VerifyAddressInput;
};


export type MutationVerifyAtoneTransactionArgs = {
  input: VerifyAtoneTransactionInput;
};


export type MutationVerifyUserEmailArgs = {
  input: VerifyUserEmailInput;
};

export enum NpExpectedResult {
  /** 与信NG */
  Ng = 'NG',
  /** 与信OK */
  Ok = 'OK',
  /** 審査保留の状態（審査継続なし） */
  Pd = 'PD',
  /** 審査保留の状態（審査継続あり） */
  Pg = 'PG'
}

export type OAuthApplication = {
  clientId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  redirectUri: Scalars['String']['output'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PayInvoice */
export type PayInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
  /** [開発・検証環境のみ有効]NP後払いの審査結果を指定する */
  npExpectedResult?: InputMaybe<NpExpectedResult>;
};

export type PaymentMethod = {
  availableForAutoPayment: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  isSuspended: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: PaymentMethodType;
};

/** The connection type for PaymentMethod. */
export type PaymentMethodConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentMethodEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentMethodEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PaymentMethod>;
};

export type PaymentMethodOrder = {
  direction: OrderDirection;
  field: PaymentMethodOrderField;
};

export enum PaymentMethodOrderField {
  Id = 'ID'
}

export type PaymentMethodSearch = {
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PaymentMethodType {
  Atone = 'ATONE',
  AtoneLite = 'ATONE_LITE',
  BankTransfer = 'BANK_TRANSFER',
  BlackCatPostpay = 'BLACK_CAT_POSTPAY',
  CashOnDelivery = 'CASH_ON_DELIVERY',
  Coupon = 'COUPON',
  CreditCard = 'CREDIT_CARD',
  NpPostpay = 'NP_POSTPAY',
  SmalunaForBizPay = 'SMALUNA_FOR_BIZ_PAY'
}

export type PaymentPlan = {
  allLotDescriptions: Array<PaymentPlanLotDescription>;
  allLots: Array<PaymentPlanLot>;
  annotation: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  installments: Scalars['Int']['output'];
  lotDescriptions: PaymentPlanLotDescriptionConnection;
  lots: PaymentPlanLotConnection;
  /** @deprecated このフィールドは廃止されました */
  lotsPerInstallments: Maybe<Scalars['Int']['output']>;
  medicineVariant: MedicineVariant;
  /** 1ロット(≒1決済)辺りの最小金額 */
  minPricePerOneLot: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  planItem: Maybe<MedicineVariant>;
  position: Maybe<Scalars['Int']['output']>;
  /** @deprecated PaymentPlanLotの追加に伴い、このフィールドが返す値に整合性が取れなくなってしまうことが判明したため非推奨 */
  price: Maybe<Scalars['Int']['output']>;
  pricePerOneItem: Maybe<Scalars['Int']['output']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  suspendedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 支払いプランの合計金額 */
  totalPrice: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type PaymentPlanLotDescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PaymentPlanLotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentPlanLot = {
  allLotItems: Array<MedicineVariant>;
  allPaymentPlanLotItems: Array<PaymentPlanLotItem>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  /** 1つ前の支払いプランロットとの日数差 */
  intervalDays: Scalars['Int']['output'];
  lotItems: MedicineVariantConnection;
  paymentPlan: PaymentPlan;
  paymentPlanLotItems: PaymentPlanLotItemConnection;
  /** 支払いプランごとの順番 */
  positionPerPaymentPlan: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type PaymentPlanLotLotItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PaymentPlanLotPaymentPlanLotItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for PaymentPlanLot. */
export type PaymentPlanLotConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentPlanLotEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PaymentPlanLot>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PaymentPlanLotDescription = {
  /** ロット説明テキスト */
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  paymentPlan: PaymentPlan;
  /** 支払いプランごとの順番 */
  positionPerPaymentPlan: Maybe<Scalars['Int']['output']>;
  /** 金額表示テキスト */
  priceText: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for PaymentPlanLotDescription. */
export type PaymentPlanLotDescriptionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentPlanLotDescriptionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PaymentPlanLotDescription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentPlanLotDescriptionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PaymentPlanLotDescription>;
};

/** An edge in a connection. */
export type PaymentPlanLotEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PaymentPlanLot>;
};

export type PaymentPlanLotItem = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  lot: PaymentPlanLot;
  medicineVariant: MedicineVariant;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for PaymentPlanLotItem. */
export type PaymentPlanLotItemConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentPlanLotItemEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PaymentPlanLotItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentPlanLotItemEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PaymentPlanLotItem>;
};

export type PaymentSource = {
  brand: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  funding: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
};

/** The connection type for PaymentSource. */
export type PaymentSourceConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PaymentSourceEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PaymentSource>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PaymentSourceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PaymentSource>;
};

export type Phone = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['PhoneNumber']['output'];
};

export enum PositionOperator {
  Absolute = 'ABSOLUTE',
  Bottom = 'BOTTOM',
  Top = 'TOP'
}

export type PostsContent = MediaPostContent & {
  fieldId: Scalars['String']['output'];
  posts: Array<MediaPost>;
};

export type Prefecture = {
  /** 関連する都道府県にクリニックを持つ全ての医師のリストが返却されます */
  allDoctors: Array<Doctor>;
  /** 関連する都道府県にクリニックを持つ医師のリストが返却されます */
  doctors: DoctorConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Prefecture.doctors を利用してください。 */
  publishedDoctors: DoctorConnection;
};


export type PrefectureAllDoctorsArgs = {
  orderBy?: InputMaybe<DoctorOrder>;
  search?: InputMaybe<DoctorSearch>;
};


export type PrefectureDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DoctorOrder>;
  search?: InputMaybe<DoctorSearch>;
};


export type PrefecturePublishedDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of PrescribeAndCloseExamination */
export type PrescribeAndCloseExaminationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Examination.id */
  id: Scalars['ID']['input'];
  prescriptionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PrescribeAndCloseExamination. */
export type PrescribeAndCloseExaminationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examination: Examination;
  invoice: Invoice;
  prescription: Prescription;
};

export type Prescription = {
  allInvoices: Array<Invoice>;
  allMedicineVariants: Array<MedicineVariant>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  examination: Examination;
  id: Scalars['ID']['output'];
  invoices: InvoiceConnection;
  latestPrescriptionInquiry: Maybe<PrescriptionInquiry>;
  medicineSubscription: Maybe<MedicineSubscription>;
  medicineVariants: MedicineVariantConnection;
  prescriptionInquiries: PrescriptionInquiryConnection;
  /** @deprecated latestPrescriptionInquiry を使ってください */
  prescriptionInquiry: Maybe<PrescriptionInquiry>;
  state: PrescriptionState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type PrescriptionInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceOrder>;
  search?: InputMaybe<InvoiceSearch>;
};


export type PrescriptionMedicineVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PrescriptionPrescriptionInquiriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Prescription. */
export type PrescriptionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrescriptionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Prescription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PrescriptionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Prescription>;
};

export type PrescriptionInquiry = {
  body: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  examinationAppointment: ExaminationAppointment;
  id: Scalars['ID']['output'];
  prescription: Prescription;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  videoCall: Maybe<ExaminationAppointmentVideoCall>;
};

/** The connection type for PrescriptionInquiry. */
export type PrescriptionInquiryConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PrescriptionInquiryEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PrescriptionInquiry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PrescriptionInquiryEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PrescriptionInquiry>;
};

export enum PrescriptionState {
  Approved = 'APPROVED',
  Created = 'CREATED',
  Decided = 'DECIDED',
  Denied = 'DENIED'
}

export type Profile = {
  age: Maybe<Scalars['Int']['output']>;
  birthday: Maybe<Scalars['ISO8601Date']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 表示名 */
  displayName: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  familyNameKana: Scalars['String']['output'];
  gender: Gender;
  givenName: Scalars['String']['output'];
  givenNameKana: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Maybe<ActiveStorageImage>;
  imageUrl: Maybe<Scalars['Url']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: Maybe<Userable>;
};


export type ProfileUserArgs = {
  type?: InputMaybe<UserType>;
};

/** Autogenerated input type of PublishDoctor */
export type PublishDoctorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of PublishFrequentlyAskedQuestion */
export type PublishFrequentlyAskedQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of PublishInquiryCategory */
export type PublishInquiryCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type PushNotification = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  token: Scalars['String']['output'];
};

/** The connection type for PushNotification. */
export type PushNotificationConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<PushNotificationEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<PushNotification>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PushNotificationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<PushNotification>;
};

export type Query = {
  accountDeletionSurvey: Maybe<AccountDeletionSurvey>;
  admin: Maybe<Admin>;
  admins: AdminConnection;
  allClinics: Array<Clinic>;
  allCommunitySelectedQuestions: Array<CommunityQuestion>;
  allCouponConditionDefinitions: Array<CouponConditionDefinition>;
  allCurrentMonitoringDoctors: Array<Doctor>;
  allDiseases: Array<Disease>;
  allDoctorAppointmentSlots: Array<DoctorAppointmentSlot>;
  /** @deprecated Deprecated */
  allDoctorMonitoringTimes: Array<DoctorMonitoringTime>;
  allDoctors: Array<Doctor>;
  allExaminationTerminationReasons: Array<ExaminationTerminationReason>;
  allIdentificationDocument: Array<Document>;
  allInquiryCategories: Array<InquiryCategory>;
  allMedicineCategories: Array<MedicineCategory>;
  allMedicines: Array<Medicine>;
  allPaymentMethods: Array<PaymentMethod>;
  allPrefectures: Array<Prefecture>;
  allQuestionnaires: Array<Questionnaire>;
  allRoles: Array<Role>;
  allZendeskGroups: Array<ZendeskGroup>;
  /** お知らせバナーリスト */
  announcementBanners: AnnouncementBannersPayload;
  /** CSV一括発送ジョブのリスト */
  bulkShipByCsvJobs: InvoiceBulkShipByCsvJobConnection;
  clinic: Maybe<Clinic>;
  clinics: ClinicConnection;
  communitySelectedQuestions: CommunityQuestionConnection;
  coupon: Maybe<Coupon>;
  couponActivationCode: Maybe<CouponActivationCode>;
  /** コードを指定して公開状態のクーポンコードを取得することができる。管理者は限定公開のものも取得可能。 */
  couponActivationCodeByCode: Maybe<CouponActivationCode>;
  couponConditionDefinitions: CouponConditionDefinitionConnection;
  coupons: CouponConnection;
  currentMonitoringDoctors: DoctorConnection;
  customer: Maybe<Customer>;
  customerNote: Maybe<CustomerNote>;
  customerNotes: CustomerNoteConnection;
  customerPaymentSource: Maybe<PaymentSource>;
  customerPaymentSources: PaymentSourceConnection;
  customers: CustomerConnection;
  disease: Maybe<Disease>;
  diseases: DiseaseConnection;
  /** DOC WMSで取り込みエラーとなったCSVのリストを取得します */
  docWmsOrderErrorCsvFiles: Array<DocWmsOrderCsvFile>;
  doctor: Maybe<Doctor>;
  doctorHolidays: DoctorHolidayConnection;
  doctors: DoctorConnection;
  examination: Maybe<Examination>;
  examinationAppointment: Maybe<ExaminationAppointment>;
  examinationAppointmentOperation: Maybe<ExaminationAppointmentOperation>;
  examinationAppointmentOperations: ExaminationAppointmentOperationConnection;
  examinationAppointments: ExaminationAppointmentConnection;
  examinationPlan: Maybe<ExaminationPlan>;
  examinationReview: Maybe<ExaminationReview>;
  examinationReviews: ExaminationReviewConnection;
  examinationTerminationReasons: ExaminationTerminationReasonConnection;
  examinations: ExaminationConnection;
  frequentlyAskedQuestion: Maybe<FrequentlyAskedQuestion>;
  inquiryCategories: InquiryCategoryConnection;
  inquiryCategory: Maybe<InquiryCategory>;
  interview: Maybe<Interview>;
  interviewQuestion: Maybe<InterviewQuestion>;
  interviewSheet: Maybe<InterviewSheet>;
  interviewSheets: InterviewSheetConnection;
  interviews: InterviewConnection;
  invoice: Maybe<Invoice>;
  invoices: InvoiceConnection;
  /** @deprecated Query.announcementBanners, Query.updateBannersが追加され、移行も完了したため不要となりました。現在はリクエストしても常に空のリストが返却されます。 */
  mediaBanners: MediaBannersPayload;
  mediaPost: Maybe<MediaPost>;
  mediaPostCategories: MediaPostCategoriesPayload;
  mediaPostCategory: Maybe<MediaPostCategory>;
  mediaPosts: MediaPostsPayload;
  medicationDiaries: MedicationDiaryConnection;
  medicationDiary: Maybe<MedicationDiary>;
  medicationDiaryRecords: MedicationDiaryRecordConnection;
  medicine: Maybe<Medicine>;
  medicineCategories: MedicineCategoryConnection;
  medicineCategory: Maybe<MedicineCategory>;
  medicineRecommendation: MedicineRecommendation;
  medicineRecommendationSurvey: Maybe<MedicineRecommendationSurvey>;
  medicineRecommendationSurveys: MedicineRecommendationSurveyConnection;
  medicineRecommendations: MedicineRecommendationConnection;
  medicineSubscription: Maybe<MedicineSubscription>;
  medicineSubscriptionCancelSurvey: Maybe<MedicineSubscriptionCancelSurvey>;
  medicineSubscriptionCancelSurveys: MedicineSubscriptionCancelSurveyConnection;
  medicineSubscriptionRenewalRecommendation: Maybe<MedicineSubscriptionRenewalRecommendation>;
  medicineSubscriptions: MedicineSubscriptionConnection;
  medicineVariant: Maybe<MedicineVariant>;
  medicineVariants: MedicineVariantConnection;
  medicines: MedicineConnection;
  oauthApplication: Maybe<OAuthApplication>;
  paymentMethod: Maybe<PaymentMethod>;
  paymentMethods: PaymentMethodConnection;
  prescription: Maybe<Prescription>;
  /** @deprecated 廃止されました */
  prescriptionFee: Maybe<Scalars['Int']['output']>;
  prescriptions: PrescriptionConnection;
  profile: Maybe<Profile>;
  questionnaires: QuestionnaireConnection;
  reminder: Maybe<Reminder>;
  roles: RoleConnection;
  routineCheckupInterview: Maybe<RoutineCheckupInterview>;
  routineCheckupSchedule: Maybe<RoutineCheckupSchedule>;
  /** 新着バナーリスト */
  updateBanners: UpdateBannersPayload;
  userAddress: Maybe<UserAddress>;
  userAddresses: UserAddressConnection;
  userProblemReport: Maybe<UserProblemReport>;
  userRoles: UserRoleConnection;
  userZendeskTickets: ZendeskTicketsPayload;
  users: UserConnection;
  viewer: Userable;
  zendeskTicket: Maybe<ZendeskTicket>;
  zendeskTicketComments: ZendeskTicketCommentsPayload;
  zendeskUser: Maybe<ZendeskUser>;
  zendeskUserTickets: ZendeskTicketsPayload;
  zendeskUsers: ZendeskUsersPayload;
};


export type QueryAccountDeletionSurveyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAllCurrentMonitoringDoctorsArgs = {
  diseaseId: Scalars['ID']['input'];
  onlyCarefulAdministration?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAllDoctorAppointmentSlotsArgs = {
  endDate: Scalars['ISO8601Date']['input'];
  first?: InputMaybe<Scalars['Boolean']['input']>;
  medicineCategoryKey: MedicineCategoryKey;
  startDate: Scalars['ISO8601Date']['input'];
};


export type QueryAllDoctorsArgs = {
  orderBy?: InputMaybe<DoctorOrder>;
  search?: InputMaybe<DoctorSearch>;
};


export type QueryAllInquiryCategoriesArgs = {
  search?: InputMaybe<InquiryCategorySearch>;
};


export type QueryAllMedicineCategoriesArgs = {
  keys?: InputMaybe<Array<MedicineCategoryKey>>;
  search?: InputMaybe<MedicineCategorySearch>;
};


export type QueryAllMedicinesArgs = {
  search?: InputMaybe<MedicineSearch>;
};


export type QueryAllPaymentMethodsArgs = {
  orderBy?: InputMaybe<PaymentMethodOrder>;
  search?: InputMaybe<PaymentMethodSearch>;
};


export type QueryAllQuestionnairesArgs = {
  search?: InputMaybe<QuestionnaireSearch>;
};


export type QueryAllRolesArgs = {
  search?: InputMaybe<RoleSearch>;
};


export type QueryAllZendeskGroupsArgs = {
  assignable?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAnnouncementBannersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MicroCmsOrder>;
};


export type QueryBulkShipByCsvJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<InvoiceBulkShipByCsvJobOrder>;
};


export type QueryClinicArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClinicsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCommunitySelectedQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCouponArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCouponActivationCodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCouponActivationCodeByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryCouponConditionDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCouponsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<CouponSearch>;
};


export type QueryCurrentMonitoringDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  diseaseId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyCarefulAdministration?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomerNoteOrder>;
  search?: InputMaybe<CustomerNoteSearch>;
};


export type QueryCustomerPaymentSourceArgs = {
  customerId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type QueryCustomerPaymentSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CustomerOrder>;
  search?: InputMaybe<CustomerSearch>;
};


export type QueryDiseaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDiseasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDoctorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDoctorHolidaysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DoctorHolidayOrder>;
  search?: InputMaybe<DoctorHolidaySearch>;
};


export type QueryDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<DoctorOrder>;
  search?: InputMaybe<DoctorSearch>;
};


export type QueryExaminationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExaminationAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExaminationAppointmentOperationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExaminationAppointmentOperationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationAppointmentOperationOrder>;
  search?: InputMaybe<ExaminationAppointmentOperationSearch>;
};


export type QueryExaminationAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationAppointmentOrder>;
  search?: InputMaybe<ExaminationAppointmentSearch>;
};


export type QueryExaminationPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExaminationReviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExaminationReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExaminationTerminationReasonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExaminationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ExaminationOrder>;
  search?: InputMaybe<ExaminationSearch>;
};


export type QueryFrequentlyAskedQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInquiryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<InquiryCategorySearch>;
};


export type QueryInquiryCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInterviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInterviewQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInterviewSheetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInterviewSheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<InterviewSheetSearch>;
};


export type QueryInterviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InterviewOrder>;
  search?: InputMaybe<InterviewSearch>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<InvoiceOrder>;
  search?: InputMaybe<InvoiceSearch>;
};


export type QueryMediaBannersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MediaBannerOrder>;
};


export type QueryMediaPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaPostCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MediaPostCategoryOrder>;
};


export type QueryMediaPostCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaPostsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MediaPostOrder>;
  search?: InputMaybe<MediaPostSearch>;
};


export type QueryMedicationDiariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MedicationDiaryOrder>;
  search?: InputMaybe<MedicationDiarySearch>;
};


export type QueryMedicationDiaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicationDiaryRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<MedicationDiaryRecordSearch>;
};


export type QueryMedicineArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<MedicineCategoryKey>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<MedicineCategorySearch>;
};


export type QueryMedicineCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<MedicineCategoryKey>;
};


export type QueryMedicineRecommendationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineRecommendationSurveyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineRecommendationSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMedicineRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMedicineSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineSubscriptionCancelSurveyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineSubscriptionCancelSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMedicineSubscriptionRenewalRecommendationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MedicineSubscriptionOrder>;
  search?: InputMaybe<MedicineSubscriptionSearch>;
};


export type QueryMedicineVariantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicineVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMedicinesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<MedicineSearch>;
};


export type QueryOauthApplicationArgs = {
  clientId: Scalars['ID']['input'];
};


export type QueryPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PaymentMethodOrder>;
  search?: InputMaybe<PaymentMethodSearch>;
};


export type QueryPrescriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuestionnairesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<QuestionnaireSearch>;
};


export type QueryReminderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<RoleSearch>;
};


export type QueryRoutineCheckupInterviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRoutineCheckupScheduleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUpdateBannersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MicroCmsOrder>;
};


export type QueryUserAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryUserProblemReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryUserZendeskTicketsArgs = {
  orderBy?: InputMaybe<ZendeskTicketOrder>;
  page?: InputMaybe<ZendeskCursorPagination>;
  relType?: InputMaybe<ZendeskUserTicketRelType>;
  userId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<UserOrder>;
  search?: InputMaybe<UserSearch>;
};


export type QueryZendeskTicketArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZendeskTicketCommentsArgs = {
  orderBy?: InputMaybe<ZendeskTicketCommentOrder>;
  page?: InputMaybe<ZendeskCursorPagination>;
  ticketId: Scalars['ID']['input'];
};


export type QueryZendeskUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZendeskUserTicketsArgs = {
  orderBy?: InputMaybe<ZendeskTicketOrder>;
  page?: InputMaybe<ZendeskCursorPagination>;
  relType?: InputMaybe<ZendeskUserTicketRelType>;
  zendeskUserId: Scalars['ID']['input'];
};


export type QueryZendeskUsersArgs = {
  orderBy?: InputMaybe<ZendeskUserOrder>;
  page?: InputMaybe<ZendeskCursorPagination>;
};

export enum QuestionAnswerType {
  AddressAnswer = 'ADDRESS_ANSWER',
  BmiAnswer = 'BMI_ANSWER',
  DateAnswer = 'DATE_ANSWER',
  DateTimeAnswer = 'DATE_TIME_ANSWER',
  MultipleSelectAnswer = 'MULTIPLE_SELECT_ANSWER',
  NonAnswer = 'NON_ANSWER',
  SingleSelectAnswer = 'SINGLE_SELECT_ANSWER',
  TextAnswer = 'TEXT_ANSWER'
}

export type Questionnaire = {
  body: Maybe<Scalars['String']['output']>;
  context: Maybe<QuestionnaireContext>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  position: Maybe<Scalars['Int']['output']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  questions: QuestionnaireQuestionConnection;
  title: Scalars['String']['output'];
  type: QuestionnaireType;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  version: Scalars['Int']['output'];
};


export type QuestionnaireQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuestionnaireAccountDeletionSurveyContext = {
  type: AccountDeletionSurveyProblemType;
};

export type QuestionnaireAnswer = {
  body: Maybe<Scalars['String']['output']>;
  choices: Array<QuestionnaireAnswerChoice>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  question: QuestionnaireQuestion;
  respondent: QuestionnaireRespondent;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type QuestionnaireAnswerChoice = {
  answer: QuestionnaireAnswer;
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  option: QuestionnaireQuestionOption;
};

/** The connection type for QuestionnaireAnswer. */
export type QuestionnaireAnswerConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<QuestionnaireAnswerEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<QuestionnaireAnswer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuestionnaireAnswerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<QuestionnaireAnswer>;
};

/** The connection type for Questionnaire. */
export type QuestionnaireConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<QuestionnaireEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Questionnaire>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** アンケートにかかわる情報 */
export type QuestionnaireContext = QuestionnaireAccountDeletionSurveyContext | QuestionnaireMedicineSubscriptionCancelSurveyContext;

/** An edge in a connection. */
export type QuestionnaireEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Questionnaire>;
};

export enum QuestionnaireGroup {
  AccountDeletionSurvey = 'ACCOUNT_DELETION_SURVEY',
  MedicineSubscriptionCancelSurvey = 'MEDICINE_SUBSCRIPTION_CANCEL_SURVEY'
}

export type QuestionnaireMedicineSubscriptionCancelSurveyContext = {
  type: MedicineSubscriptionCancelSurveyProblemType;
};

export type QuestionnaireQuestion = {
  annotation: Maybe<Scalars['String']['output']>;
  answerType: QuestionnaireQuestionAnswerType;
  body: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  options: Array<QuestionnaireQuestionOption>;
  position: Scalars['Int']['output'];
  questionnaire: Questionnaire;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum QuestionnaireQuestionAnswerType {
  MultipleSelect = 'MULTIPLE_SELECT',
  SingleSelect = 'SINGLE_SELECT',
  Text = 'TEXT'
}

/** The connection type for QuestionnaireQuestion. */
export type QuestionnaireQuestionConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<QuestionnaireQuestionEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<QuestionnaireQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuestionnaireQuestionEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<QuestionnaireQuestion>;
};

export type QuestionnaireQuestionOption = {
  body: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['JSON']['output']>;
  position: Scalars['Int']['output'];
  question: QuestionnaireQuestion;
  updatedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type QuestionnaireRespondent = {
  /** 回答に従い分岐を考慮した質問リスト */
  allInteractiveQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  id: Scalars['ID']['output'];
  lastRespondedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  questionnaire: Questionnaire;
  state: QuestionnaireRespondentState;
};


export type QuestionnaireRespondentAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum QuestionnaireRespondentState {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type QuestionnaireSearch = {
  group?: InputMaybe<QuestionnaireGroup>;
};

export enum QuestionnaireType {
  CancelMedicineSubscriptionFemaleAga = 'CANCEL_MEDICINE_SUBSCRIPTION_FEMALE_AGA',
  CancelMedicineSubscriptionMedicalDiet = 'CANCEL_MEDICINE_SUBSCRIPTION_MEDICAL_DIET',
  CancelMedicineSubscriptionMedicalSkinCare = 'CANCEL_MEDICINE_SUBSCRIPTION_MEDICAL_SKIN_CARE',
  CancelMedicineSubscriptionOralContraceptive = 'CANCEL_MEDICINE_SUBSCRIPTION_ORAL_CONTRACEPTIVE',
  CancelMedicineSubscriptionToAvoidSideEffects = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_AVOID_SIDE_EFFECTS',
  CancelMedicineSubscriptionToChangeOfflineService = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_CHANGE_OFFLINE_SERVICE',
  CancelMedicineSubscriptionToChangeOnlineService = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_CHANGE_ONLINE_SERVICE',
  CancelMedicineSubscriptionToContraindications = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_CONTRAINDICATIONS',
  CancelMedicineSubscriptionToDissatisfied = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_DISSATISFIED',
  CancelMedicineSubscriptionToDoctorUnsuitable = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_DOCTOR_UNSUITABLE',
  CancelMedicineSubscriptionToForgetToTakeMedicine = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_FORGET_TO_TAKE_MEDICINE',
  CancelMedicineSubscriptionToIneffective = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_INEFFECTIVE',
  CancelMedicineSubscriptionToIneffectiveMenstrualSymptoms = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_INEFFECTIVE_MENSTRUAL_SYMPTOMS',
  CancelMedicineSubscriptionToIneffectiveSkinProblems = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_INEFFECTIVE_SKIN_PROBLEMS',
  CancelMedicineSubscriptionToLeftoverMedicine = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_LEFTOVER_MEDICINE',
  CancelMedicineSubscriptionToMismatchBetweenSupplyAndDemand = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_MISMATCH_BETWEEN_SUPPLY_AND_DEMAND',
  CancelMedicineSubscriptionToMonetaryBurden = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_MONETARY_BURDEN',
  CancelMedicineSubscriptionToNoLongerNeed = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_NO_LONGER_NEED',
  CancelMedicineSubscriptionToOtherReason = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_OTHER_REASON',
  CancelMedicineSubscriptionToStabilized = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_STABILIZED',
  CancelMedicineSubscriptionToTemporaryUse = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_TEMPORARY_USE',
  CancelMedicineSubscriptionToUseOfflineServiceTemporarily = 'CANCEL_MEDICINE_SUBSCRIPTION_TO_USE_OFFLINE_SERVICE_TEMPORARILY',
  ContraceptionProblems = 'CONTRACEPTION_PROBLEMS',
  CustomerProblemReport = 'CUSTOMER_PROBLEM_REPORT',
  DeleteAccount = 'DELETE_ACCOUNT',
  DeleteAccountToAvoidSideEffects = 'DELETE_ACCOUNT_TO_AVOID_SIDE_EFFECTS',
  DeleteAccountToChangeOfflineService = 'DELETE_ACCOUNT_TO_CHANGE_OFFLINE_SERVICE',
  DeleteAccountToChangeOnlineService = 'DELETE_ACCOUNT_TO_CHANGE_ONLINE_SERVICE',
  DeleteAccountToContraindications = 'DELETE_ACCOUNT_TO_CONTRAINDICATIONS',
  DeleteAccountToDissatisfied = 'DELETE_ACCOUNT_TO_DISSATISFIED',
  DeleteAccountToDoctorUnsuitable = 'DELETE_ACCOUNT_TO_DOCTOR_UNSUITABLE',
  DeleteAccountToForgetToTakeMedicine = 'DELETE_ACCOUNT_TO_FORGET_TO_TAKE_MEDICINE',
  DeleteAccountToIneffective = 'DELETE_ACCOUNT_TO_INEFFECTIVE',
  DeleteAccountToIneffectiveMenstrualSymptoms = 'DELETE_ACCOUNT_TO_INEFFECTIVE_MENSTRUAL_SYMPTOMS',
  DeleteAccountToIneffectiveSkinProblems = 'DELETE_ACCOUNT_TO_INEFFECTIVE_SKIN_PROBLEMS',
  DeleteAccountToLeftoverMedicine = 'DELETE_ACCOUNT_TO_LEFTOVER_MEDICINE',
  DeleteAccountToMismatchBetweenSupplyAndDemand = 'DELETE_ACCOUNT_TO_MISMATCH_BETWEEN_SUPPLY_AND_DEMAND',
  DeleteAccountToMonetaryBurden = 'DELETE_ACCOUNT_TO_MONETARY_BURDEN',
  DeleteAccountToNoLongerNeed = 'DELETE_ACCOUNT_TO_NO_LONGER_NEED',
  DeleteAccountToOtherReason = 'DELETE_ACCOUNT_TO_OTHER_REASON',
  DeleteAccountToStabilized = 'DELETE_ACCOUNT_TO_STABILIZED',
  DeleteAccountToTemporaryUse = 'DELETE_ACCOUNT_TO_TEMPORARY_USE',
  DeleteAccountToUseOfflineServiceTemporarily = 'DELETE_ACCOUNT_TO_USE_OFFLINE_SERVICE_TEMPORARILY',
  DoctorProblemReport = 'DOCTOR_PROBLEM_REPORT',
  ExaminationBad = 'EXAMINATION_BAD',
  ExaminationGood = 'EXAMINATION_GOOD',
  MenstrualProblems = 'MENSTRUAL_PROBLEMS',
  SignUpSurvey = 'SIGN_UP_SURVEY'
}

/** Autogenerated input type of RateExamination */
export type RateExaminationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Examination.id */
  id: Scalars['ID']['input'];
  rate: Scalars['Int']['input'];
};

/** Autogenerated input type of ReadExaminationMessages */
export type ReadExaminationMessagesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationId: Scalars['ID']['input'];
};

/** Autogenerated input type of ReadZendeskTicketComments */
export type ReadZendeskTicketCommentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ZendeskTicket.id */
  ticketId: Scalars['ID']['input'];
};

/** Autogenerated input type of RefreshAuthToken */
export type RefreshAuthTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refreshToken: Scalars['String']['input'];
  refreshTokenExp?: InputMaybe<Scalars['Int']['input']>;
  tokenExp?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of RefundInvoice */
export type RefundInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of RegisterCustomerInvitation */
export type RegisterCustomerInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Customer.id */
  id: Scalars['ID']['input'];
  invitationCode: Scalars['String']['input'];
};

/** Autogenerated return type of RegisterCustomerInvitation. */
export type RegisterCustomerInvitationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Customer;
};

/** Autogenerated input type of RegisterCustomerWithEmailPassword */
export type RegisterCustomerWithEmailPasswordInput = {
  clientIdentificationKey?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  refreshTokenExp?: InputMaybe<Scalars['Int']['input']>;
  tokenExp?: InputMaybe<Scalars['Int']['input']>;
  utmParameter?: InputMaybe<UserUtmParameterInput>;
};

/** Autogenerated input type of RegisterDevice */
export type RegisterDeviceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  pushNotificationToken?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of RegisterPushNotificationToken */
export type RegisterPushNotificationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type Reminder = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  remindsAt: Scalars['TimeString']['output'];
  type: ReminderType;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export enum ReminderType {
  Medication = 'MEDICATION'
}

/** Autogenerated input type of RequestInvoiceCreditCardPayment */
export type RequestInvoiceCreditCardPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RequestInvoiceCreditCardPayment. */
export type RequestInvoiceCreditCardPaymentPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
  stripePaymentIntent: Maybe<StripePaymentIntent>;
};

/** Autogenerated input type of RequestUserContactExaminationAppointmentOperation */
export type RequestUserContactExaminationAppointmentOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of RequestUserContactExaminationAppointmentOperation. */
export type RequestUserContactExaminationAppointmentOperationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

/** Autogenerated input type of ResetAllExaminationPlanContent */
export type ResetAllExaminationPlanContentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationPlanId: Scalars['ID']['input'];
};

/** Autogenerated input type of ResetCustomerZendeskTicketTotalUnreadCount */
export type ResetCustomerZendeskTicketTotalUnreadCountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Customer.id */
  customerId: Scalars['ID']['input'];
};

/** Autogenerated return type of ResetCustomerZendeskTicketTotalUnreadCount. */
export type ResetCustomerZendeskTicketTotalUnreadCountPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Customer;
};

/** Autogenerated input type of ResetExaminationPlanContent */
export type ResetExaminationPlanContentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ExaminationPlanContent.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of ResetUserPassword */
export type ResetUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated input type of RestoreCustomer */
export type RestoreCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of ReverifyAddress */
export type ReverifyAddressInput = {
  addressId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ReverifyAddress. */
export type ReverifyAddressPayload = {
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** 住所クレンジング結果（クレンジングしなかった場合null） */
  features: Maybe<Array<AddressVerificationFeature>>;
};

/** Autogenerated input type of RevokeAdmin */
export type RevokeAdminInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type Role = {
  description: Scalars['String']['output'];
  group: RoleGroup;
  name: UserRoleName;
  nameJa: Scalars['String']['output'];
};

/** The connection type for Role. */
export type RoleConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RoleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<Role>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoleEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<Role>;
};

export enum RoleGroup {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Doctor = 'DOCTOR'
}

export type RoleSearch = {
  group?: InputMaybe<RoleGroup>;
};

export type RoutineCheckup = {
  closedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  id: Scalars['ID']['output'];
  interview: Maybe<RoutineCheckupInterview>;
  medicineCategory: MedicineCategory;
  schedule: Maybe<RoutineCheckupSchedule>;
  state: RoutineCheckupState;
};

/** The connection type for RoutineCheckup. */
export type RoutineCheckupConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RoutineCheckupEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<RoutineCheckup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoutineCheckupEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<RoutineCheckup>;
};

export type RoutineCheckupInterview = {
  allAnswers: Maybe<Array<RoutineCheckupInterviewAnswer>>;
  allQuestions: Array<InterviewQuestion>;
  answers: Maybe<RoutineCheckupInterviewAnswerConnection>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  disease: Disease;
  id: Scalars['ID']['output'];
  position: Maybe<Scalars['Int']['output']>;
  questions: InterviewQuestionConnection;
  result: Maybe<RoutineCheckupInterviewResult>;
  routineCheckup: RoutineCheckup;
  state: RoutineCheckupInterviewState;
};


export type RoutineCheckupInterviewAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RoutineCheckupInterviewQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RoutineCheckupInterviewAnswer = RoutineCheckupInterviewAnswerable & {
  contentType: InterviewAnswerContentType;
  contents: Array<InterviewAnswerContent>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  question: InterviewQuestion;
  routineCheckupInterview: RoutineCheckupInterview;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for RoutineCheckupInterviewAnswer. */
export type RoutineCheckupInterviewAnswerConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RoutineCheckupInterviewAnswerEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<RoutineCheckupInterviewAnswer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoutineCheckupInterviewAnswerEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<RoutineCheckupInterviewAnswer>;
};

export type RoutineCheckupInterviewAnswerable = {
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  question: InterviewQuestion;
  routineCheckupInterview: RoutineCheckupInterview;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type RoutineCheckupInterviewBmiAnswer = RoutineCheckupInterviewAnswerable & {
  bmi: Scalars['String']['output'];
  contentType: InterviewAnswerContentType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  height: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: InterviewQuestion;
  routineCheckupInterview: RoutineCheckupInterview;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  weight: Scalars['String']['output'];
};

export enum RoutineCheckupInterviewResult {
  CarefulAdministration = 'CAREFUL_ADMINISTRATION',
  Danger = 'DANGER',
  GoToExamination = 'GO_TO_EXAMINATION',
  GoToMedicalConsultation = 'GO_TO_MEDICAL_CONSULTATION',
  GoToOfflineClinic = 'GO_TO_OFFLINE_CLINIC',
  NoProblem = 'NO_PROBLEM'
}

export type RoutineCheckupInterviewSelectAnswer = RoutineCheckupInterviewAnswerable & {
  contentType: InterviewAnswerContentType;
  contents: Array<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  question: InterviewQuestion;
  routineCheckupInterview: RoutineCheckupInterview;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type RoutineCheckupInterviewSelectAnswerSelection = {
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum RoutineCheckupInterviewState {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type RoutineCheckupSchedule = {
  closedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  /** 定期問診未完了による定期便自動解約処理が実行される日時 */
  expiresAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  medicineCategory: MedicineCategory;
  medicineSubscription: MedicineSubscription;
  requestsOn: Scalars['ISO8601Date']['output'];
  routineCheckup: Maybe<RoutineCheckup>;
  state: RoutineCheckupScheduleState;
};

/** The connection type for RoutineCheckupSchedule. */
export type RoutineCheckupScheduleConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<RoutineCheckupScheduleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<RoutineCheckupSchedule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoutineCheckupScheduleEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<RoutineCheckupSchedule>;
};

export enum RoutineCheckupScheduleState {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export enum RoutineCheckupState {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

/** Autogenerated input type of SelectMedicineSubscriptionPaymentPlan */
export type SelectMedicineSubscriptionPaymentPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** MedicineSubscription.id */
  id: Scalars['ID']['input'];
  /** PaymentPlan.id */
  paymentPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of SelectMedicineSubscriptionPaymentPlan. */
export type SelectMedicineSubscriptionPaymentPlanPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Maybe<Invoice>;
  medicineSubscription: Maybe<MedicineSubscription>;
};

/** Autogenerated input type of SendReceipt */
export type SendReceiptInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

export type ServiceContract = {
  contractType: ServiceContractType;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  revision: Scalars['ISO8601Date']['output'];
  service: ServiceContractService;
  url: Scalars['Url']['output'];
};

/** The connection type for ServiceContract. */
export type ServiceContractConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ServiceContractEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<ServiceContract>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ServiceContractEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<ServiceContract>;
};

export enum ServiceContractService {
  Smaluna = 'SMALUNA'
}

export enum ServiceContractType {
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsOfService = 'TERMS_OF_SERVICE'
}

/** Autogenerated input type of SetAtoneAuthenticationToken */
export type SetAtoneAuthenticationTokenInput = {
  atoneTransactionId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  serviceType: Scalars['String']['input'];
  token: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of SetAtoneAuthenticationToken. */
export type SetAtoneAuthenticationTokenPayload = {
  atoneCustomer: AtoneCustomer;
  atoneTransaction: Maybe<AtoneTransaction>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SetMedicineSubscriptionRenewalRecommendationUserRenewalType */
export type SetMedicineSubscriptionRenewalRecommendationUserRenewalTypeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  medicineSubscriptionRenewalRecommendationId: Scalars['ID']['input'];
  userRenewalType: MedicineSubscriptionRenewalRecommendationUserRenewalType;
};

/** Autogenerated input type of ShipInvoice */
export type ShipInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

export type ShippingCompany = {
  allDeliveryServices: Array<DeliveryService>;
  deliveryServices: DeliveryServiceConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type ShippingCompanyDeliveryServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SignUpSurvey = {
  /** 回答に従い分岐を考慮した質問リスト */
  allInteractiveQuestions: Array<QuestionnaireQuestion>;
  allQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  /** 誕生日 */
  birthday: Maybe<Scalars['ISO8601Date']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 遷移先 */
  dest: SignUpSurveyDest;
  /** 性別 */
  gender: Maybe<Gender>;
  id: Scalars['ID']['output'];
  /** ニックネーム */
  nickname: Maybe<Scalars['String']['output']>;
  /** 都道府県 */
  prefecture: Maybe<Scalars['String']['output']>;
  questions: QuestionnaireQuestionConnection;
  responder: Customer;
  state: QuestionnaireRespondentState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type SignUpSurveyAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SignUpSurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum SignUpSurveyDest {
  Identification = 'IDENTIFICATION',
  Media = 'MEDIA',
  MedicalConsultation = 'MEDICAL_CONSULTATION',
  MedicineRecommendationSurvey = 'MEDICINE_RECOMMENDATION_SURVEY',
  Unknown = 'UNKNOWN'
}

export type SmalunaForBizUser = {
  /** forBiz決済を利用できる状態であるかどうか */
  availableForBizPayment: Maybe<Scalars['Boolean']['output']>;
  /** 所属企業 */
  company: Maybe<Company>;
  /** 企業負担割合 */
  companyBurdenRatio: Maybe<Scalars['Int']['output']>;
  customer: Maybe<Customer>;
  /** 免除区分ID Invoice.smalunaForBizExemptionClassificationId への書き込みに使用されるもので、会計システム用の値 */
  exemptionClassificationId: Maybe<Scalars['ID']['output']>;
  /** 企業に対する請求を免除するかどうか Invoice.smalunaForBizCompanyBillingExempted への書き込みに使用されるもので、会計システム用の値 */
  isInvoiceExempted: Maybe<Scalars['Boolean']['output']>;
  /** 最終利用日 */
  lastDateOfUsage: Maybe<Scalars['ISO8601Date']['output']>;
  /** 最終勤務日 relationDisabledAt と同値(らしい) */
  lastWorkingDate: Maybe<Scalars['ISO8601Date']['output']>;
  /** forBiz決済を利用できなくなった日時 */
  relationDisabledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** forBiz決済を利用できるようになった日時 */
  relationEnabledAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** スマルナ側のユーザーID Customer.id と同値 */
  smalunaUserId: Maybe<Scalars['ID']['output']>;
  uid: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated input type of StartDoctorAssignedMonitoringTime */
export type StartDoctorAssignedMonitoringTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorAssignedMonitoringTimeId: Scalars['ID']['input'];
};

/** Autogenerated input type of StartInvoiceStripeBankTransfer */
export type StartInvoiceStripeBankTransferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of StartInvoiceStripeBankTransfer. */
export type StartInvoiceStripeBankTransferPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
  stripePaymentIntent: StripePaymentIntent;
};

/** Autogenerated input type of StartKycRegistration */
export type StartKycRegistrationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** default NATIVE */
  platformType?: InputMaybe<KycRegistrationPlatformType>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartKycRegistration. */
export type StartKycRegistrationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  uid: Scalars['ID']['output'];
  url: Scalars['Url']['output'];
};

/** Autogenerated input type of StartReviewExaminationAppointmentOperation */
export type StartReviewExaminationAppointmentOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartReviewExaminationAppointmentOperation. */
export type StartReviewExaminationAppointmentOperationPayload = {
  chargeOperator: Admin;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

/** Autogenerated input type of StartUserContactExaminationAppointmentOperation */
export type StartUserContactExaminationAppointmentOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartUserContactExaminationAppointmentOperation. */
export type StartUserContactExaminationAppointmentOperationPayload = {
  chargeOperator: Admin;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

export type StripeCharge = {
  /** 支払い金額 */
  amount: Scalars['Int']['output'];
  /** キャプチャー済み金額 */
  amountCaptured: Scalars['Int']['output'];
  /** 返金済み金額 */
  amountRefunded: Scalars['Int']['output'];
  /** キャプチャー済みかどうか */
  captured: Scalars['Boolean']['output'];
  /** 作成日時 */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** Stripe::Charge.id */
  id: Scalars['ID']['output'];
  /** 支払い済みかどうか */
  paid: Scalars['Boolean']['output'];
  /** 支払い方法ID */
  paymentMethod: Maybe<Scalars['String']['output']>;
  /** 支払い方法詳細 */
  paymentMethodDetails: Maybe<StripeChargePaymentMethodDetails>;
  /** 支払いステータス（succeeded / pending / failed） */
  status: StripeChargeStatus;
};

export type StripeChargePaymentMethodDetails = {
  /** カード情報 */
  card: Maybe<StripeChargePaymentMethodDetailsCard>;
  /** card のみに対応 */
  type: Scalars['String']['output'];
};

export type StripeChargePaymentMethodDetailsCard = {
  /** ブランド名 */
  brand: Maybe<Scalars['String']['output']>;
  /** 有効期限（月） */
  expMonth: Scalars['Int']['output'];
  /** 有効期限（年） */
  expYear: Scalars['Int']['output'];
  /** 下4桁 */
  last4: Maybe<Scalars['String']['output']>;
};

export enum StripeChargeStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type StripePaymentIntent = {
  /** 支払金額 */
  amount: Scalars['Int']['output'];
  /** 支払残額 */
  amountRemaining: Maybe<Scalars['Int']['output']>;
  /** 振込先銀行口座の口座名義人 */
  bankAccountHolderName: Maybe<Scalars['String']['output']>;
  /** 振込先銀行口座の口座番号 */
  bankAccountNumber: Maybe<Scalars['String']['output']>;
  /** 振込先銀行口座の口座種別 */
  bankAccountType: Maybe<Scalars['String']['output']>;
  /** 振込先銀行口座の支店コード */
  bankBranchCode: Maybe<Scalars['String']['output']>;
  /** 振込先銀行口座の支店名 */
  bankBranchName: Maybe<Scalars['String']['output']>;
  /** 振込先銀行口座の銀行コード */
  bankCode: Maybe<Scalars['String']['output']>;
  /** 振込先銀行口座の銀行名 */
  bankName: Maybe<Scalars['String']['output']>;
  /** 送金案内ページのURL */
  bankTransferInstructionsUrl: Maybe<Scalars['String']['output']>;
  /** Stripe PaymentIntent client_secret */
  clientSecret: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 遅延請求かどうか */
  delayed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Stripe PaymentIntent payment_method */
  paymentMethod: Maybe<Scalars['String']['output']>;
  /** Stripe PaymentIntent ID */
  rawDataId: Scalars['String']['output'];
  state: StripePaymentIntentState;
  /** Stripe Customer ID */
  stripeCustomerId: Scalars['String']['output'];
  /** クレジットカードの3Dセキュア認証ページのURL */
  threeDSecureNextActionUrl: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for StripePaymentIntent. */
export type StripePaymentIntentConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<StripePaymentIntentEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<StripePaymentIntent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type StripePaymentIntentEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<StripePaymentIntent>;
};

export enum StripePaymentIntentState {
  Canceled = 'CANCELED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED'
}

/** Autogenerated input type of SubmitIdentification */
export type SubmitIdentificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** IdentificationDocument.id */
  documentId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated documentId に移行のため廃止 */
  documentType?: InputMaybe<IdentificationDocumentType>;
  photos: IdentificationPhotos;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitIdentification. */
export type SubmitIdentificationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Customer;
  userId: Scalars['ID']['output'];
};

export type Subscription = {
  /** @deprecated CustomerUpdatedを利用してください */
  customerEmailVerificationUpdated: Customer;
  /** @deprecated CustomerUpdatedを利用してください */
  customerIdentificationStateUpdated: Customer;
  customerUpdated: Customer;
  examinationMessageAdded: Maybe<ExaminationMessage>;
  examinationUpdated: Examination;
  watchMonitoringDoctors: DoctorConnection;
  zendeskTicketCommentAdded: Maybe<ZendeskTicketComment>;
  zendeskUserTicketUpdated: Maybe<ZendeskTicket>;
};


export type SubscriptionCustomerEmailVerificationUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCustomerIdentificationStateUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionCustomerUpdatedArgs = {
  id: Scalars['ID']['input'];
};


export type SubscriptionExaminationMessageAddedArgs = {
  examinationId: Scalars['ID']['input'];
};


export type SubscriptionExaminationUpdatedArgs = {
  examinationId: Scalars['ID']['input'];
};


export type SubscriptionWatchMonitoringDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  diseaseId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyCarefulAdministration?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SubscriptionZendeskTicketCommentAddedArgs = {
  ticketId: Scalars['ID']['input'];
};


export type SubscriptionZendeskUserTicketUpdatedArgs = {
  ticketId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of SuspendExaminationAppointmentOperation */
export type SuspendExaminationAppointmentOperationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of SuspendExaminationAppointmentOperation. */
export type SuspendExaminationAppointmentOperationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointment: ExaminationAppointment;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

/** Autogenerated input type of SyncAllInquiryCategoryToZendesk */
export type SyncAllInquiryCategoryToZendeskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  type: InquiryCategoryConsultantType;
};

/** Autogenerated input type of TerminateExamination */
export type TerminateExaminationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Examination.id */
  id: Scalars['ID']['input'];
  /** 診察終了理由ID（ExaminationTerminationReason.id) */
  terminationReasonId?: InputMaybe<Scalars['ID']['input']>;
};

export type TextContent = MediaPostContent & {
  fieldId: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

/** Autogenerated input type of UnassignDoctorMonitoringTime */
export type UnassignDoctorMonitoringTimeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorAssignedMonitoringTimeId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateAdmin */
export type UpdateAdminInput = {
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  familyNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  givenNameKana?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateBanner = {
  alt: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  image: MicroCmsImage;
  publishedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  revisedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['Url']['output'];
};

export type UpdateBannersPayload = {
  contents: Array<UpdateBanner>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Autogenerated input type of UpdateClinicDoctor */
export type UpdateClinicDoctorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  clinicId: Scalars['ID']['input'];
  doctorId: Scalars['ID']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateClinicDoctor. */
export type UpdateClinicDoctorPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
};

/** Autogenerated input type of UpdateClinic */
export type UpdateClinicInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  corporateName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  prefecture?: InputMaybe<Scalars['String']['input']>;
  representativeName?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateCouponActivationCode */
export type UpdateCouponActivationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  publicity?: InputMaybe<CouponActivationCodePublicity>;
};

/** Autogenerated return type of UpdateCouponActivationCode. */
export type UpdateCouponActivationCodePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  couponActivationCode: Maybe<CouponActivationCode>;
};

/** Autogenerated input type of UpdateCoupon */
export type UpdateCouponInput = {
  availableFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  conditionDescription?: InputMaybe<Scalars['String']['input']>;
  couponConditions?: InputMaybe<Array<CouponConditionInput>>;
  discountType?: InputMaybe<CouponDiscountType>;
  discountValue?: InputMaybe<Scalars['Int']['input']>;
  expiresAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id: Scalars['ID']['input'];
  isUnlimitedPerUser?: InputMaybe<Scalars['Boolean']['input']>;
  limitPerUser?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateCustomer */
export type UpdateCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateCustomerNickname */
export type UpdateCustomerNicknameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  nickname: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateCustomerNickname. */
export type UpdateCustomerNicknamePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  customer: Maybe<Customer>;
};

/** Autogenerated input type of UpdateCustomerNote */
export type UpdateCustomerNoteInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateDevice */
export type UpdateDeviceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  pushNotificationToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateDoctorDiseases */
export type UpdateDoctorDiseasesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  diseaseDoctors?: InputMaybe<Array<DoctorDiseaseDoctorInput>>;
  /** @deprecated doctorDiseases を使用してください */
  diseaseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  doctorId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateDoctor */
export type UpdateDoctorInput = {
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  familyNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  givenNameKana?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateExaminationAppointmentOperationDoctorNote */
export type UpdateExaminationAppointmentOperationDoctorNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  doctorNote: Scalars['String']['input'];
  examinationAppointmentOperationId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateExaminationAppointmentOperationDoctorNote. */
export type UpdateExaminationAppointmentOperationDoctorNotePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

/** Autogenerated input type of UpdateExaminationAppointmentOperationOperatorNote */
export type UpdateExaminationAppointmentOperationOperatorNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  examinationAppointmentOperationId: Scalars['ID']['input'];
  operatorNote: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateExaminationAppointmentOperationOperatorNote. */
export type UpdateExaminationAppointmentOperationOperatorNotePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationAppointmentOperation: ExaminationAppointmentOperation;
};

/** Autogenerated input type of UpdateExaminationPlanContent */
export type UpdateExaminationPlanContentInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ExaminationPlanContent.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateExaminationTerminationReason */
export type UpdateExaminationTerminationReasonInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  published?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateExaminationTerminationReason. */
export type UpdateExaminationTerminationReasonPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  examinationTerminationReason: ExaminationTerminationReason;
};

/** Autogenerated input type of UpdateFrequentlyAskedQuestion */
export type UpdateFrequentlyAskedQuestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['Url']['input']>;
};

/** Autogenerated input type of UpdateInquiryCategory */
export type UpdateInquiryCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateInterview */
export type UpdateInterviewInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationMethod?: InputMaybe<InterviewCommunicationMethod>;
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  /** Interview.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateInvoice */
export type UpdateInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Invoice.id */
  id: Scalars['ID']['input'];
  npTransactionId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  paymentSourceId?: InputMaybe<Scalars['ID']['input']>;
  /** 次回以降の支払い方法ID */
  restPaymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  restPaymentSourceId?: InputMaybe<Scalars['ID']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
  shopTransactionId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateMedicineDescription */
export type UpdateMedicineDescriptionInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** MedicineDescription.id */
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateMedicine */
export type UpdateMedicineInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  introduction?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  publishedToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  publishedToDoctor?: InputMaybe<Scalars['Boolean']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateMedicineSubscription */
export type UpdateMedicineSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** MedicineSubscription.id */
  id: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  paymentSourceId?: InputMaybe<Scalars['ID']['input']>;
  shippingAddressId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdateMedicineVariant */
export type UpdateMedicineVariantInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  days?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  lots?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  prescriptionFee?: InputMaybe<Scalars['Int']['input']>;
  prescriptionName?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  publishedToCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  publishedToDoctor?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  birthday?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  familyNameKana?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  givenNameKana?: InputMaybe<Scalars['String']['input']>;
  /** Profile.id */
  id: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateReminder */
export type UpdateReminderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Reminder.id */
  id: Scalars['ID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  remindsAt?: InputMaybe<Scalars['TimeString']['input']>;
};

/** Autogenerated input type of UpdateSignUpSurvey */
export type UpdateSignUpSurveyInput = {
  /** 誕生日 */
  birthday: Scalars['ISO8601Date']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** 性別 */
  gender?: InputMaybe<Gender>;
  /** 新規登録アンケートID */
  id: Scalars['ID']['input'];
  /** ニックネーム */
  nickname: Scalars['String']['input'];
  /** 都道府県 */
  prefecture: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateSignUpSurvey. */
export type UpdateSignUpSurveyPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  signUpSurvey: Maybe<SignUpSurvey>;
};

/** Autogenerated input type of UpdateUserAddress */
export type UpdateUserAddressInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** UserAddress.id */
  id: Scalars['ID']['input'];
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  prefecture?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateUserAnnotation */
export type UpdateUserAnnotationInput = {
  annotation: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateUserAnnotation. */
export type UpdateUserAnnotationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  user: Maybe<User>;
};

/** Autogenerated input type of UpdateUserPhone */
export type UpdateUserPhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of UpdateZendeskUser */
export type UpdateZendeskUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  /** ZendeskUser.id */
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<ZendeskUserRole>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  /** ZendeskUser.tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of UploadTempfile */
export type UploadTempfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
};

/** Autogenerated return type of UploadTempfile. */
export type UploadTempfilePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type User = Userable & {
  accessTokens: UserAccessTokenConnection;
  agreedServiceContracts: ServiceContractConnection;
  allRoles: Array<UserRole>;
  annotation: Maybe<Scalars['String']['output']>;
  appsflyerIds: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentIdentification: Maybe<Identification>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['Email']['output'];
  emailVerifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 認証情報としてのパスワードを保持している場合 true */
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identificationState: UserIdentificationState;
  /** 招待コード */
  invitationCode: Maybe<Scalars['String']['output']>;
  isAgreedLatestServiceContracts: UserLatestServiceContractAgreementMap;
  kyc: Maybe<UserKyc>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  profile: Maybe<Profile>;
  pushNotifications: PushNotificationConnection;
  roles: UserRoleConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  utmParameter: Maybe<UserUtmParameter>;
  zendeskUser: Maybe<ZendeskUser>;
  zoomAuthCredential: Maybe<UserZoomAuthCredential>;
  zoomSecret: Maybe<UserZoomSecret>;
};


export type UserAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserAgreedServiceContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserIsAgreedLatestServiceContractsArgs = {
  service: ServiceContractService;
};


export type UserPushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAccessToken = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  lastUsedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  revokedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  token: Scalars['String']['output'];
};

/** The connection type for UserAccessToken. */
export type UserAccessTokenConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserAccessTokenEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserAccessToken>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserAccessTokenEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserAccessToken>;
};

export type UserAddress = Address & {
  /** 2回目以降の未払いの請求に配送先として設定されている定期便リスト */
  allMedicineSubscriptionsWithDelayedNextUnpaidInvoice: Array<MedicineSubscription>;
  building: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** 住所クレンジング結果レベル */
  geocodingLevel: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** 2回目以降の未払いの請求に配送先として設定されている定期便リスト */
  medicineSubscriptionsWithDelayedNextUnpaidInvoice: MedicineSubscriptionConnection;
  postalCode: Scalars['PostalCode']['output'];
  prefecture: Scalars['String']['output'];
  town: Scalars['String']['output'];
  /** 住所クレンジング容認期限 */
  verificationIgnoreUntilAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 住所クレンジングをスキップするかどうか */
  verificationIgnored: Scalars['Boolean']['output'];
};


export type UserAddressAllMedicineSubscriptionsWithDelayedNextUnpaidInvoiceArgs = {
  orderBy?: InputMaybe<MedicineSubscriptionOrder>;
  search?: InputMaybe<MedicineSubscriptionSearch>;
};


export type UserAddressMedicineSubscriptionsWithDelayedNextUnpaidInvoiceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MedicineSubscriptionOrder>;
  search?: InputMaybe<MedicineSubscriptionSearch>;
};

/** The connection type for UserAddress. */
export type UserAddressConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserAddressEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserAddress>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserAddressEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserAddress>;
};

/** The connection type for User. */
export type UserConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserCoupon = {
  activationCode: Maybe<CouponActivationCode>;
  coupon: Coupon;
  createdAt: Scalars['ISO8601DateTime']['output'];
  customer: Customer;
  id: Scalars['ID']['output'];
  invoice: Maybe<Invoice>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  usedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** The connection type for UserCoupon. */
export type UserCouponConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserCouponEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserCoupon>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserCouponEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserCoupon>;
};

/** An edge in a connection. */
export type UserEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<User>;
};

export type UserField = {
  id: Scalars['ID']['output'];
};

export enum UserIdentificationState {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Unsubmitted = 'UNSUBMITTED',
  Waiting = 'WAITING'
}

export type UserKyc = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  state: UserKycState;
  /** user_id for KYC system. */
  uid: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: Userable;
};

export enum UserKycState {
  Approved = 'APPROVED',
  Created = 'CREATED',
  Denied = 'DENIED',
  Pending = 'PENDING'
}

export type UserLatestServiceContractAgreementMap = {
  privacyPolicy: Scalars['Boolean']['output'];
  termsOfService: Scalars['Boolean']['output'];
};

export enum UserNoteWriterRole {
  Doctor = 'DOCTOR',
  MedicalStaff = 'MEDICAL_STAFF',
  Support = 'SUPPORT'
}

export type UserOrder = {
  direction: OrderDirection;
  field: UserOrderField;
};

export enum UserOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
  LastActivityAt = 'LAST_ACTIVITY_AT'
}

export type UserPhone = Phone & {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['PhoneNumber']['output'];
};

export type UserProblemReport = {
  allAnswers: Array<QuestionnaireAnswer>;
  allQuestions: Array<QuestionnaireQuestion>;
  answers: QuestionnaireAnswerConnection;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  questions: QuestionnaireQuestionConnection;
  reportedUser: Userable;
  reportedUserType: UserType;
  reporter: Userable;
  reporterType: UserType;
  state: QuestionnaireRespondentState;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};


export type UserProblemReportAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserProblemReportQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum UserProblemReportUserType {
  Customer = 'CUSTOMER',
  Doctor = 'DOCTOR'
}

export type UserRole = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name: UserRoleName;
  role: Role;
  userId: Scalars['ID']['output'];
};

/** The connection type for UserRole. */
export type UserRoleConnection = {
  /** A list of edges. */
  edges: Maybe<Array<Maybe<UserRoleEdge>>>;
  /** A list of nodes. */
  nodes: Maybe<Array<Maybe<UserRole>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserRoleEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Maybe<UserRole>;
};

export enum UserRoleName {
  Consignor = 'CONSIGNOR',
  CouponManager = 'COUPON_MANAGER',
  Customer = 'CUSTOMER',
  Developer = 'DEVELOPER',
  DmhDoctor = 'DMH_DOCTOR',
  DmhOperator = 'DMH_OPERATOR',
  DmmOnlineClinicCustomer = 'DMM_ONLINE_CLINIC_CUSTOMER',
  DmmOnlineClinicOperator = 'DMM_ONLINE_CLINIC_OPERATOR',
  Doctor = 'DOCTOR',
  DoctorManager = 'DOCTOR_MANAGER',
  KarakuriBotOperator = 'KARAKURI_BOT_OPERATOR',
  KycOperator = 'KYC_OPERATOR',
  MonitoringDoctor = 'MONITORING_DOCTOR',
  MunicipalityConsultationCustomer = 'MUNICIPALITY_CONSULTATION_CUSTOMER',
  Operator = 'OPERATOR',
  Pharmacist = 'PHARMACIST',
  PowerDoctor = 'POWER_DOCTOR',
  PublishedDoctor = 'PUBLISHED_DOCTOR',
  SexuallyTransmittedInfectionTestKitPrescriber = 'SEXUALLY_TRANSMITTED_INFECTION_TEST_KIT_PRESCRIBER',
  SexuallyTransmittedInfectionTestKitTester = 'SEXUALLY_TRANSMITTED_INFECTION_TEST_KIT_TESTER',
  StudentDiscountPrescriber = 'STUDENT_DISCOUNT_PRESCRIBER',
  SystemOperator = 'SYSTEM_OPERATOR',
  VideoCallReservableDoctor = 'VIDEO_CALL_RESERVABLE_DOCTOR'
}

export type UserSchedule = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  endsAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  onTime: Scalars['Boolean']['output'];
  startsAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type UserSearch = {
  /** Prefix match. */
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  familyNameKanas?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  familyNames?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  givenNameKanas?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Exact match. */
  givenNames?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleGroup?: InputMaybe<RoleGroup>;
  withDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserType {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Doctor = 'DOCTOR'
}

export type UserUtmParameter = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  user: Userable;
  /** utm_campaign */
  utmCampaign: Maybe<Scalars['String']['output']>;
  /** utm_content */
  utmContent: Maybe<Scalars['String']['output']>;
  /** utm_medium */
  utmMedium: Maybe<Scalars['String']['output']>;
  /** utm_source */
  utmSource: Maybe<Scalars['String']['output']>;
  /** utm_term */
  utmTerm: Maybe<Scalars['String']['output']>;
};

export type UserUtmParameterInput = {
  /** utm_campaign */
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  /** utm_content */
  utmContent?: InputMaybe<Scalars['String']['input']>;
  /** utm_medium */
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  /** utm_source */
  utmSource?: InputMaybe<Scalars['String']['input']>;
  /** utm_term */
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type UserZoomAuthCredential = {
  accountId: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  clientSecretLast2: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type UserZoomSecret = {
  apiKey: Scalars['String']['output'];
  apiSecret: Maybe<Scalars['String']['output']>;
  apiSecretLast2: Scalars['String']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type Userable = {
  accessTokens: UserAccessTokenConnection;
  agreedServiceContracts: ServiceContractConnection;
  allRoles: Array<UserRole>;
  annotation: Maybe<Scalars['String']['output']>;
  appsflyerIds: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentIdentification: Maybe<Identification>;
  deletedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  email: Scalars['Email']['output'];
  emailVerifiedAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** 認証情報としてのパスワードを保持している場合 true */
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  identificationState: UserIdentificationState;
  /** 招待コード */
  invitationCode: Maybe<Scalars['String']['output']>;
  isAgreedLatestServiceContracts: UserLatestServiceContractAgreementMap;
  kyc: Maybe<UserKyc>;
  lastActivityAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  nickname: Maybe<Scalars['String']['output']>;
  profile: Maybe<Profile>;
  pushNotifications: PushNotificationConnection;
  roles: UserRoleConnection;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  utmParameter: Maybe<UserUtmParameter>;
  zendeskUser: Maybe<ZendeskUser>;
  zoomAuthCredential: Maybe<UserZoomAuthCredential>;
  zoomSecret: Maybe<UserZoomSecret>;
};


export type UserableAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserableAgreedServiceContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserableIsAgreedLatestServiceContractsArgs = {
  service: ServiceContractService;
};


export type UserablePushNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserableRolesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated input type of VerifyAddress */
export type VerifyAddressInput = {
  address: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of VerifyAddress. */
export type VerifyAddressPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  /** 住所クレンジング結果 */
  features: Array<AddressVerificationFeature>;
  /** 住所が確実に正しいかどうか */
  verified: Scalars['Boolean']['output'];
};

/** Autogenerated input type of VerifyAtoneTransaction */
export type VerifyAtoneTransactionInput = {
  authorizationResult: Scalars['String']['input'];
  authorizationResultNgReason?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shopTransactionNo: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyAtoneTransaction. */
export type VerifyAtoneTransactionPayload = {
  atoneTransaction: AtoneTransaction;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  invoice: Invoice;
};

/** Autogenerated input type of VerifyUserEmail */
export type VerifyUserEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** メールアドレス確認コード */
  code: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyUserEmail. */
export type VerifyUserEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']['output']>;
  email: Scalars['Email']['output'];
  emailVerifiedAt: Scalars['ISO8601DateTime']['output'];
  /** User.id */
  id: Scalars['ID']['output'];
  user: User;
};

export type WmsOrder = {
  clientBuilding: Maybe<Scalars['String']['output']>;
  clientCd: Scalars['String']['output'];
  clientCity: Scalars['String']['output'];
  clientName: Scalars['String']['output'];
  clientPrefecture: Scalars['String']['output'];
  clientTel: Scalars['String']['output'];
  clientTown: Scalars['String']['output'];
  clientZipcode: Scalars['String']['output'];
  clinicApp: Scalars['String']['output'];
  clinicCorporateName: Scalars['String']['output'];
  clinicLegalName: Scalars['String']['output'];
  clinicPrint: Scalars['String']['output'];
  collect: Maybe<Scalars['Int']['output']>;
  deliverMailAddress: Scalars['String']['output'];
  destinationBuilding: Maybe<Scalars['String']['output']>;
  destinationCd: Scalars['String']['output'];
  destinationCity: Scalars['String']['output'];
  destinationName: Scalars['String']['output'];
  destinationPrefecture: Scalars['String']['output'];
  destinationTel: Scalars['String']['output'];
  destinationTown: Scalars['String']['output'];
  destinationZipcode: Scalars['String']['output'];
  details: Array<WmsOrderDetail>;
  invoiceId: Scalars['ID']['output'];
  invoiceKbn: Scalars['String']['output'];
  prefCd: Scalars['String']['output'];
  productName1: Scalars['String']['output'];
  shipmentDate: Scalars['ISO8601Date']['output'];
  taxInCollect: Maybe<Scalars['Int']['output']>;
};

export type WmsOrderDetail = {
  lineItemId: Scalars['ID']['output'];
  medicineVariantId: Scalars['ID']['output'];
  subscritpionCount: Maybe<Scalars['Int']['output']>;
};

export type ZendeskAttachment = {
  contentType: Scalars['String']['output'];
  contentUrl: Scalars['Url']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inline: Scalars['Boolean']['output'];
  size: Scalars['Int']['output'];
  thumbnails: Array<ZendeskAttachment>;
};

export type ZendeskCursorPagination = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<ZendeskCursorPaginationDirection>;
  /** max: 100, default: 100 */
  size?: InputMaybe<Scalars['Int']['input']>;
};

export enum ZendeskCursorPaginationDirection {
  After = 'AFTER',
  Before = 'BEFORE'
}

export type ZendeskGroup = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  default: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type ZendeskJobStatus = {
  id: Scalars['ID']['output'];
  progress: Maybe<Scalars['Int']['output']>;
  results: Maybe<Scalars['JSON']['output']>;
  status: ZendeskJobStatusStatus;
  total: Maybe<Scalars['Int']['output']>;
};

export enum ZendeskJobStatusStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Killed = 'KILLED',
  Queued = 'QUEUED',
  Working = 'WORKING'
}

export type ZendeskTicket = {
  assignee: Maybe<User>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  groupId: Maybe<Scalars['ID']['output']>;
  /** ZendeskTicket.id */
  id: Scalars['ID']['output'];
  publicCommentSentAt: Scalars['ISO8601DateTime']['output'];
  requester: Maybe<User>;
  requesterReadAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  requesterUnreadCount: Scalars['Int']['output'];
  status: ZendeskTicketStatus;
  subject: Maybe<Scalars['String']['output']>;
  submitter: Maybe<User>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  url: Scalars['String']['output'];
};

export type ZendeskTicketAudit = {
  /** ZendeskUser.id */
  authorId: Scalars['ID']['output'];
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  events: Array<ZendeskTicketAuditEventable>;
  /** ZendeskAuditEvent.id */
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  /** ZendeskTicket.id */
  ticketId: Scalars['ID']['output'];
  via: Scalars['JSON']['output'];
};

export type ZendeskTicketAuditCreateEvent = ZendeskTicketAuditEventable & {
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
};

export type ZendeskTicketAuditEvent = ZendeskTicketAuditEventable & {
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type ZendeskTicketAuditEventable = {
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type ZendeskTicketBulkCreateReport = {
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  results: Maybe<Scalars['JSON']['output']>;
  state: ZendeskJobStatusStatus;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  zendeskJobStatus: ZendeskJobStatus;
};

export type ZendeskTicketComment = ZendeskTicketAuditEventable & {
  attachments: Array<ZendeskAttachment>;
  /** ZendeskUser.id */
  authorId: Maybe<Scalars['ID']['output']>;
  body: Scalars['String']['output'];
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>;
  htmlBody: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['JSON']['output']>;
  plainBody: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  user: User;
};

export type ZendeskTicketCommentOrder = {
  direction: OrderDirection;
  field: ZendeskTicketCommentOrderField;
};

export enum ZendeskTicketCommentOrderField {
  CreatedAt = 'CREATED_AT',
  Id = 'ID'
}

export type ZendeskTicketCommentsPayload = {
  afterCursor: Maybe<Scalars['String']['output']>;
  beforeCursor: Maybe<Scalars['String']['output']>;
  comments: Array<ZendeskTicketComment>;
  hasMore: Scalars['Boolean']['output'];
};

export type ZendeskTicketOrder = {
  direction: OrderDirection;
  field: ZendeskTicketOrderField;
};

export enum ZendeskTicketOrderField {
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT'
}

export enum ZendeskTicketStatus {
  Closed = 'CLOSED',
  Hold = 'HOLD',
  New = 'NEW',
  Open = 'OPEN',
  Pending = 'PENDING',
  Solved = 'SOLVED'
}

export type ZendeskTicketsPayload = {
  afterCursor: Maybe<Scalars['String']['output']>;
  beforeCursor: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  tickets: Array<ZendeskTicket>;
};

export type ZendeskUser = {
  /** false if the user has been deleted */
  active: Scalars['Boolean']['output'];
  /** Whether or not the user is a chat-only agent */
  chatOnly: Scalars['Boolean']['output'];
  /** The time the user was created */
  createdAt: Scalars['String']['output'];
  customRoleId: Maybe<Scalars['Int']['output']>;
  defaultGroupId: Maybe<Scalars['Int']['output']>;
  details: Maybe<Scalars['String']['output']>;
  /** The user's primary email address */
  email: Maybe<Scalars['String']['output']>;
  /** Userable.id */
  externalId: Maybe<Scalars['String']['output']>;
  ianaTimeZone: Maybe<Scalars['String']['output']>;
  /** ZendeskUser.id */
  id: Scalars['ID']['output'];
  lastLoginAt: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  localeId: Maybe<Scalars['Int']['output']>;
  moderator: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  onlyPrivateComments: Scalars['Boolean']['output'];
  organizationId: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  photo: Maybe<ZendeskAttachment>;
  reportCsv: Scalars['Boolean']['output'];
  restrictedAgent: Scalars['Boolean']['output'];
  role: ZendeskUserRole;
  roleType: Scalars['Int']['output'];
  shared: Scalars['Boolean']['output'];
  sharedAgent: Scalars['Boolean']['output'];
  sharedPhoneNumber: Scalars['Boolean']['output'];
  signature: Maybe<Scalars['String']['output']>;
  suspended: Scalars['Boolean']['output'];
  tags: Array<Scalars['String']['output']>;
  ticketRestriction: Maybe<Scalars['String']['output']>;
  timeZone: Scalars['String']['output'];
  twoFactorAuthEnabled: Scalars['Boolean']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
  userFields: Maybe<UserField>;
  verified: Scalars['Boolean']['output'];
};

export type ZendeskUserOrder = {
  direction: OrderDirection;
  field: ZendeskUserOrderField;
};

export enum ZendeskUserOrderField {
  Id = 'ID'
}

export enum ZendeskUserRole {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  EndUser = 'END_USER'
}

export enum ZendeskUserTicketRelType {
  Assigned = 'ASSIGNED',
  Ccd = 'CCD',
  Followed = 'FOLLOWED',
  Requested = 'REQUESTED'
}

export type ZendeskUsersPayload = {
  afterCursor: Maybe<Scalars['String']['output']>;
  beforeCursor: Maybe<Scalars['String']['output']>;
  hasMore: Scalars['Boolean']['output'];
  users: Array<ZendeskUser>;
};

export type ZoomMeeting = {
  createdAt: Scalars['String']['output'];
  hostEmail: Scalars['String']['output'];
  hostId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  joinUrl: Scalars['String']['output'];
  password: Maybe<Scalars['String']['output']>;
  startUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  type: Scalars['Int']['output'];
  uuid: Scalars['ID']['output'];
};
