import { SectionGrant } from 'components/functional'
import { useQuery } from '../useQuery'
import { INVOICE_SHIPMENT_CSV } from 'constants/path'
import MenuItem from '../MenuItem'
import { canShowSidebarShipmentCsv } from 'policies/InvoicePolicy'

type Props = ReturnType<typeof useQuery>

export const InvoiceShipmentCsvItem: React.FC<Props> = ({ viewer }) => {
  return (
    <SectionGrant authorized={canShowSidebarShipmentCsv(viewer)}>
      <MenuItem href={INVOICE_SHIPMENT_CSV}>一括発送</MenuItem>
    </SectionGrant>
  )
}
