import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_AdminPolicy_CreateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_AdminPolicy_UpdateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_AdminPolicy_DestroyFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_AdminPolicy_DetachRoleFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_AdminPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_AdminPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type Admin_AdminPolicy_DetachRole_DataFragment = { allRoles: Array<{ id: string, role: { group: Types.RoleGroup } }> };

export const User_AdminPolicy_CreateFragmentDoc = gql`
    fragment User_AdminPolicy_Create on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_AdminPolicy_UpdateFragmentDoc = gql`
    fragment User_AdminPolicy_Update on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_AdminPolicy_DestroyFragmentDoc = gql`
    fragment User_AdminPolicy_Destroy on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_AdminPolicy_DetachRoleFragmentDoc = gql`
    fragment User_AdminPolicy_DetachRole on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_AdminPolicy_ShowFragmentDoc = gql`
    fragment User_AdminPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_AdminPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_AdminPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const Admin_AdminPolicy_DetachRole_DataFragmentDoc = gql`
    fragment Admin_AdminPolicy_DetachRole_Data on Admin {
  allRoles {
    id
    role {
      group
    }
  }
}
    `;