import { CouponKey, UserRoleName } from 'gen/graphql-type'
import {
  User_CouponPolicy_CreateFragment,
  User_CouponPolicy_CreateActivationCodeFragment,
  User_CouponPolicy_UpdateFragment,
  Coupon_CouponPolicy_CreateActivationCode_DataFragment,
  User_CouponPolicy_ShowFragment,
  User_CouponPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canCreate: Authorize<User_CouponPolicy_CreateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.CouponManager])
}

export const canCreateActivationCode: Authorize<
  User_CouponPolicy_CreateActivationCodeFragment,
  Coupon_CouponPolicy_CreateActivationCode_DataFragment
> = (user, coupon) => {
  return (
    authorize(user, [UserRoleName.SystemOperator, UserRoleName.CouponManager]) &&
    !(coupon.key !== null && [CouponKey.Inviter, CouponKey.Invitee].includes(coupon.key))
  )
}

export const canShow: Authorize<User_CouponPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
    UserRoleName.DmhOperator,
  ])
}

export const canShowSidebar: Authorize<User_CouponPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canUpdate: Authorize<User_CouponPolicy_UpdateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.CouponManager])
}
