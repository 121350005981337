import * as Types from '../../../../gen/graphql-type';

import gql from 'graphql-tag';
import { User_AdminPolicy_ShowSidebarFragmentDoc } from '../../../../policies/AdminPolicy/query.gen-graphql';
import { User_DoctorPolicy_ShowSidebarFragmentDoc } from '../../../../policies/DoctorPolicy/query.gen-graphql';
import { User_CustomerPolicy_ShowSidebarFragmentDoc } from '../../../../policies/CustomerPolicy/query.gen-graphql';
import { User_InvoicePolicy_ShowSidebarFragmentDoc, User_InvoicePolicy_ShowSidebarShipmentListFragmentDoc } from '../../../../policies/InvoicePolicy/query.gen-graphql';
import { User_ExaminationPolicy_ShowSidebarFragmentDoc } from '../../../../policies/ExaminationPolicy/query.gen-graphql';
import { User_MedicineCategoryPolicy_ShowSidebarFragmentDoc } from '../../../../policies/MedicineCategoryPolicy/fragment.gen-graphql';
import { User_InterviewSheetPolicy_ShowSidebarFragmentDoc } from '../../../../policies/InterviewSheetPolicy/fragment.gen-graphql';
import { User_CouponPolicy_ShowSidebarFragmentDoc } from '../../../../policies/CouponPolicy/query.gen-graphql';
import { User_ExaminationTerminationReasonPolicy_ShowSidebarFragmentDoc } from '../../../../policies/ExaminationTerminationReasonPolicy/query.gen-graphql';
import { User_InquiryCategoryPolicy_ShowSidebarFragmentDoc } from '../../../../policies/InquiryCategoryPolicy/query.gen-graphql';
import { User_ZendeskTicketPolicy_ShowSidebarFragmentDoc } from '../../../../policies/ZendeskTicket/query.gen-graphql';
import { User_ExaminationAppointmentPolicy_ShowSidebarFragmentDoc } from '../../../../policies/ExaminationAppointmentPolicy/fragment.gen-graphql';
import { User_DocWmsOrderCsvFilePolicy_ShowSidebarFragmentDoc } from '../../../../policies/DOCWmsOrderCsvFilePolicy/fragment.gen-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PageContainerSidebarQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PageContainerSidebarQuery = { viewer: { __typename: 'Admin', id: string, allRoles: Array<{ id: string, name: Types.UserRoleName }> } | { __typename: 'Customer', id: string } | { __typename: 'Doctor', id: string } | { __typename: 'User', id: string } };


export const PageContainerSidebarDocument = gql`
    query PageContainerSidebar {
  viewer {
    __typename
    id
    ...User_AdminPolicy_ShowSidebar
    ...User_DoctorPolicy_ShowSidebar
    ...User_CustomerPolicy_ShowSidebar
    ...User_InvoicePolicy_ShowSidebar
    ...User_InvoicePolicy_ShowSidebarShipmentList
    ...User_ExaminationPolicy_ShowSidebar
    ...User_MedicineCategoryPolicy_ShowSidebar
    ...User_InterviewSheetPolicy_ShowSidebar
    ...User_CouponPolicy_ShowSidebar
    ...User_ExaminationTerminationReasonPolicy_ShowSidebar
    ...User_InquiryCategoryPolicy_ShowSidebar
    ...User_ZendeskTicketPolicy_ShowSidebar
    ...User_ExaminationAppointmentPolicy_ShowSidebar
    ...User_DOCWmsOrderCsvFilePolicy_ShowSidebar
  }
}
    ${User_AdminPolicy_ShowSidebarFragmentDoc}
${User_DoctorPolicy_ShowSidebarFragmentDoc}
${User_CustomerPolicy_ShowSidebarFragmentDoc}
${User_InvoicePolicy_ShowSidebarFragmentDoc}
${User_InvoicePolicy_ShowSidebarShipmentListFragmentDoc}
${User_ExaminationPolicy_ShowSidebarFragmentDoc}
${User_MedicineCategoryPolicy_ShowSidebarFragmentDoc}
${User_InterviewSheetPolicy_ShowSidebarFragmentDoc}
${User_CouponPolicy_ShowSidebarFragmentDoc}
${User_ExaminationTerminationReasonPolicy_ShowSidebarFragmentDoc}
${User_InquiryCategoryPolicy_ShowSidebarFragmentDoc}
${User_ZendeskTicketPolicy_ShowSidebarFragmentDoc}
${User_ExaminationAppointmentPolicy_ShowSidebarFragmentDoc}
${User_DocWmsOrderCsvFilePolicy_ShowSidebarFragmentDoc}`;

export function usePageContainerSidebarQuery(options?: Omit<Urql.UseQueryArgs<PageContainerSidebarQueryVariables>, 'query'>) {
  return Urql.useQuery<PageContainerSidebarQuery, PageContainerSidebarQueryVariables>({ query: PageContainerSidebarDocument, ...options });
};