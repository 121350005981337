import React from 'react'
import { INQUIRY_CATEGORY_LIST } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebar } from 'policies/InquiryCategoryPolicy'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'

type Props = ReturnType<typeof useQuery>

export const InquiryCategoryMenuItem: React.FC<Props> = ({ viewer }) => {
  return (
    <SectionGrant authorized={canShowSidebar(viewer)}>
      <MenuItem href={INQUIRY_CATEGORY_LIST}>問い合わせカテゴリ一覧</MenuItem>
    </SectionGrant>
  )
}
