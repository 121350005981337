/**
 * see https://docs.getunleash.io/reference/sdks/next-js#c-client-side-only---simple-use-case-and-for-development-purposes-csr
 */
import React, { PropsWithChildren } from 'react'
import { FlagProvider } from '@unleash/nextjs'
import { IConfig as UnleashConfig } from '@unleash/proxy-client-react'

const config: UnleashConfig = {
  appName: 'adam',
  url: process.env.NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL,
  clientKey: process.env.NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN,
  refreshInterval: 15, // default: 30
}

export const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  return <FlagProvider config={config}>{children}</FlagProvider>
}
