import { UserRoleName } from 'gen/graphql-type'
import {
  User_InvoicePolicy_PayFragment,
  User_InvoicePolicy_DenyFragment,
  User_InvoicePolicy_UpdateAddressFragment,
  User_InvoicePolicy_CustomerPaymentBlockedFragment,
  User_InvoicePolicy_ShowFragment,
  User_InvoicePolicy_ShowShipmentListFragment,
  User_InvoicePolicy_ShipFragment,
  User_InvoicePolicy_ShowShipmentCsvFragment,
  User_InvoicePolicy_ShowSidebarFragment,
  User_InvoicePolicy_ShowSidebarShipmentListFragment,
  User_InvoicePolicy_ShowSidebarShipmentCsvFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canDeny: Authorize<User_InvoicePolicy_DenyFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canPay: Authorize<User_InvoicePolicy_PayFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canUpdateAddress: Authorize<User_InvoicePolicy_UpdateAddressFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canCustomerPaymentBlocked: Authorize<
  User_InvoicePolicy_CustomerPaymentBlockedFragment
> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canShow: Authorize<User_InvoicePolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
    UserRoleName.DmhOperator,
  ])
}

export const canShowSidebar: Authorize<User_InvoicePolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowShipmentList: Authorize<User_InvoicePolicy_ShowShipmentListFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Consignor])
}

export const canShowSidebarShipmentList: Authorize<
  User_InvoicePolicy_ShowSidebarShipmentListFragment
> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Consignor])
}

export const canShip: Authorize<User_InvoicePolicy_ShipFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Consignor])
}

export const canShowShipmentCsv: Authorize<User_InvoicePolicy_ShowShipmentCsvFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Consignor,
    UserRoleName.DmhOperator,
  ])
}

export const canShowSidebarShipmentCsv: Authorize<
  User_InvoicePolicy_ShowSidebarShipmentCsvFragment
> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Consignor,
    UserRoleName.DmhOperator,
  ])
}
