import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_InquiryCategoryPolicy_CreateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InquiryCategoryPolicy_UpdateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InquiryCategoryPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InquiryCategoryPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type InquiryCategory_InquiryCategoryPolicy_Update_DataFragment = { consultantType: Types.InquiryCategoryConsultantType };

export type User_InquiryCategoryPolicy_DestroyFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type InquiryCategory_InquiryCategoryPolicy_Destroy_DataFragment = { consultantType: Types.InquiryCategoryConsultantType };

export type User_InquiryCategoryPolicy_CreateFrequentlyAskedQuestionFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type InquiryCategory_InquiryCategoryPolicy_CreateFrequentlyAskedQuestion_DataFragment = { consultantType: Types.InquiryCategoryConsultantType };

export const User_InquiryCategoryPolicy_CreateFragmentDoc = gql`
    fragment User_InquiryCategoryPolicy_Create on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InquiryCategoryPolicy_UpdateFragmentDoc = gql`
    fragment User_InquiryCategoryPolicy_Update on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InquiryCategoryPolicy_ShowFragmentDoc = gql`
    fragment User_InquiryCategoryPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InquiryCategoryPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_InquiryCategoryPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const InquiryCategory_InquiryCategoryPolicy_Update_DataFragmentDoc = gql`
    fragment InquiryCategory_InquiryCategoryPolicy_Update_Data on InquiryCategory {
  consultantType
}
    `;
export const User_InquiryCategoryPolicy_DestroyFragmentDoc = gql`
    fragment User_InquiryCategoryPolicy_Destroy on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const InquiryCategory_InquiryCategoryPolicy_Destroy_DataFragmentDoc = gql`
    fragment InquiryCategory_InquiryCategoryPolicy_Destroy_Data on InquiryCategory {
  consultantType
}
    `;
export const User_InquiryCategoryPolicy_CreateFrequentlyAskedQuestionFragmentDoc = gql`
    fragment User_InquiryCategoryPolicy_CreateFrequentlyAskedQuestion on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const InquiryCategory_InquiryCategoryPolicy_CreateFrequentlyAskedQuestion_DataFragmentDoc = gql`
    fragment InquiryCategory_InquiryCategoryPolicy_CreateFrequentlyAskedQuestion_Data on InquiryCategory {
  consultantType
}
    `;