import React from 'react'
import Router from 'next/router'
import { PageSpinner } from 'components/ui'

type Props = {
  authorized: boolean
  fetching: boolean
  children: React.ReactElement
}

const Component: React.FC<Props> = ({ authorized, fetching, children }) => {
  if (fetching) return <PageSpinner />

  if (!fetching && !authorized) {
    Router.replace('/403')

    return null
  }

  return children
}

export default Component
