import * as Types from '../gen/graphql-type';

import gql from 'graphql-tag';
export type Userable_All_Roles_Admin_Fragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type Userable_All_Roles_Customer_Fragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type Userable_All_Roles_Doctor_Fragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type Userable_All_Roles_User_Fragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type Userable_All_RolesFragment = Userable_All_Roles_Admin_Fragment | Userable_All_Roles_Customer_Fragment | Userable_All_Roles_Doctor_Fragment | Userable_All_Roles_User_Fragment;

export const Userable_All_RolesFragmentDoc = gql`
    fragment Userable_All_Roles on Userable {
  allRoles {
    id
    name
  }
}
    `;