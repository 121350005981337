import React from 'react'
import { INTERVIEW_SHEET_LIST } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebar } from 'policies/InterviewSheetPolicy'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'

type Props = ReturnType<typeof useQuery>

export const InterviewSheetMenuItem: React.FC<Props> = ({ viewer }) => {
  return (
    <SectionGrant authorized={canShowSidebar(viewer)}>
      <MenuItem href={INTERVIEW_SHEET_LIST}>問診票一覧</MenuItem>
    </SectionGrant>
  )
}
