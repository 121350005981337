import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_CustomerPolicy_UpdateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CustomerPolicy_DestroyFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CustomerPolicy_RestoreFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CustomerPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CustomerPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_CustomerPolicy_UpdateFragmentDoc = gql`
    fragment User_CustomerPolicy_Update on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CustomerPolicy_DestroyFragmentDoc = gql`
    fragment User_CustomerPolicy_Destroy on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CustomerPolicy_RestoreFragmentDoc = gql`
    fragment User_CustomerPolicy_Restore on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CustomerPolicy_ShowFragmentDoc = gql`
    fragment User_CustomerPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CustomerPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_CustomerPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;