import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_DoctorPolicy_CreateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_UpdateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_UpdateDiseaseFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_UpdateCertificateOfTelemedTrainingFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_PublishAndHideFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_AttachAndDetachRoleFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_UpdateMonitoringDoctorAssignmentFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_UpdateZoomOAuthCredentialFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_DoctorPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_DoctorPolicy_CreateFragmentDoc = gql`
    fragment User_DoctorPolicy_Create on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_UpdateFragmentDoc = gql`
    fragment User_DoctorPolicy_Update on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_UpdateDiseaseFragmentDoc = gql`
    fragment User_DoctorPolicy_UpdateDisease on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_UpdateCertificateOfTelemedTrainingFragmentDoc = gql`
    fragment User_DoctorPolicy_UpdateCertificateOfTelemedTraining on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_PublishAndHideFragmentDoc = gql`
    fragment User_DoctorPolicy_PublishAndHide on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_AttachAndDetachRoleFragmentDoc = gql`
    fragment User_DoctorPolicy_AttachAndDetachRole on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_UpdateMonitoringDoctorAssignmentFragmentDoc = gql`
    fragment User_DoctorPolicy_UpdateMonitoringDoctorAssignment on Admin {
  allRoles {
    id
    name
  }
}
    `;
export const User_DoctorPolicy_UpdateZoomOAuthCredentialFragmentDoc = gql`
    fragment User_DoctorPolicy_UpdateZoomOAuthCredential on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_ShowFragmentDoc = gql`
    fragment User_DoctorPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_DoctorPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_DoctorPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;