import { UserRoleName } from 'gen/graphql-type'
import {
  User_CustomerPolicy_UpdateFragment,
  User_CustomerPolicy_DestroyFragment,
  User_CustomerPolicy_RestoreFragment,
  User_CustomerPolicy_ShowFragment,
  User_CustomerPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canUpdate: Authorize<User_CustomerPolicy_UpdateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canDestroy: Authorize<User_CustomerPolicy_DestroyFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canRestore: Authorize<User_CustomerPolicy_RestoreFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.Operator])
}

export const canShow: Authorize<User_CustomerPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
    UserRoleName.DmhOperator,
  ])
}

export const canShowSidebar: Authorize<User_CustomerPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}
