import { UserRoleName } from 'gen/graphql-type'
import {
  User_ZendeskTicketPolicy_CreateFragment,
  User_ZendeskTicketPolicy_ShowFragment,
  User_ZendeskTicketPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canCreate: Authorize<User_ZendeskTicketPolicy_CreateFragment> = user => {
  return authorize(user, [
    UserRoleName.SystemOperator,
    UserRoleName.Operator,
    UserRoleName.Pharmacist,
  ])
}

export const canShow: Authorize<User_ZendeskTicketPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowSidebar: Authorize<User_ZendeskTicketPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}
