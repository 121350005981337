import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_InvoicePolicy_DenyFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_PayFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_UpdateAddressFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_CustomerPaymentBlockedFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShowShipmentListFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShipFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShowShipmentCsvFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShowSidebarShipmentCsvFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InvoicePolicy_ShowSidebarShipmentListFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_InvoicePolicy_DenyFragmentDoc = gql`
    fragment User_InvoicePolicy_Deny on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_PayFragmentDoc = gql`
    fragment User_InvoicePolicy_Pay on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_UpdateAddressFragmentDoc = gql`
    fragment User_InvoicePolicy_UpdateAddress on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_CustomerPaymentBlockedFragmentDoc = gql`
    fragment User_InvoicePolicy_CustomerPaymentBlocked on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShowFragmentDoc = gql`
    fragment User_InvoicePolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShowShipmentListFragmentDoc = gql`
    fragment User_InvoicePolicy_ShowShipmentList on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShipFragmentDoc = gql`
    fragment User_InvoicePolicy_Ship on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShowShipmentCsvFragmentDoc = gql`
    fragment User_InvoicePolicy_ShowShipmentCsv on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShowSidebarFragmentDoc = gql`
    fragment User_InvoicePolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShowSidebarShipmentCsvFragmentDoc = gql`
    fragment User_InvoicePolicy_ShowSidebarShipmentCsv on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InvoicePolicy_ShowSidebarShipmentListFragmentDoc = gql`
    fragment User_InvoicePolicy_ShowSidebarShipmentList on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;