import { UserRoleName } from 'gen/graphql-type'
import {
  User_DoctorPolicy_UpdateFragment,
  User_DoctorPolicy_CreateFragment,
  User_DoctorPolicy_UpdateDiseaseFragment,
  User_DoctorPolicy_UpdateCertificateOfTelemedTrainingFragment,
  User_DoctorPolicy_PublishAndHideFragment,
  User_DoctorPolicy_AttachAndDetachRoleFragment,
  User_DoctorPolicy_UpdateMonitoringDoctorAssignmentFragment,
  User_DoctorPolicy_UpdateZoomOAuthCredentialFragment,
  User_DoctorPolicy_ShowFragment,
  User_DoctorPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { authorize, Authorize } from '../authorize'

export const canCreate: Authorize<User_DoctorPolicy_CreateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canUpdate: Authorize<User_DoctorPolicy_UpdateFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canUpdateDisease: Authorize<User_DoctorPolicy_UpdateDiseaseFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canUpdateCertificateOfTelemedTraining: Authorize<
  User_DoctorPolicy_UpdateCertificateOfTelemedTrainingFragment
> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canPublishAndHide: Authorize<User_DoctorPolicy_PublishAndHideFragment> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canAttachAndDetachRole: Authorize<
  User_DoctorPolicy_AttachAndDetachRoleFragment
> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canUpdateMonitoringDoctorAssignment: Authorize<
  User_DoctorPolicy_UpdateMonitoringDoctorAssignmentFragment
> = user => {
  if (!user) return false

  const roles = user.allRoles.map(item => item.name)

  const allowedRoles = [UserRoleName.SystemOperator, UserRoleName.DoctorManager]

  return allowedRoles.some(r => roles.includes(r))
}

export const canUpdateZoomOAuthCredential: Authorize<
  User_DoctorPolicy_UpdateZoomOAuthCredentialFragment
> = user => {
  return authorize(user, [UserRoleName.SystemOperator, UserRoleName.DoctorManager])
}

export const canShow: Authorize<User_DoctorPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowSidebar: Authorize<User_DoctorPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}
