import React from 'react'
import { useRouter } from 'next/router'
import { INVOICE_LIST } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebar } from 'policies/InvoicePolicy'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'

type Props = ReturnType<typeof useQuery>

export const InvoiceMenuItem: React.FC<Props> = ({ viewer }) => {
  const { asPath } = useRouter()

  // MEMO: 請求書一覧のURLは /invoices か /invoices/{id} or invoices?query=values のみマッチする
  const isActive = new RegExp(`^${INVOICE_LIST}(\\/[0-9]+)*(\\?.*)?$`).test(asPath)

  return (
    <SectionGrant authorized={canShowSidebar(viewer)}>
      <MenuItem href={INVOICE_LIST} isActive={isActive}>
        請求一覧
      </MenuItem>
    </SectionGrant>
  )
}
