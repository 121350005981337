import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_CouponPolicy_CreateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CouponPolicy_CreateActivationCodeFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CouponPolicy_UpdateFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CouponPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_CouponPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type Coupon_CouponPolicy_CreateActivationCode_DataFragment = { id: string, key: Types.CouponKey | null };

export const User_CouponPolicy_CreateFragmentDoc = gql`
    fragment User_CouponPolicy_Create on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CouponPolicy_CreateActivationCodeFragmentDoc = gql`
    fragment User_CouponPolicy_CreateActivationCode on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CouponPolicy_UpdateFragmentDoc = gql`
    fragment User_CouponPolicy_Update on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CouponPolicy_ShowFragmentDoc = gql`
    fragment User_CouponPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_CouponPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_CouponPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const Coupon_CouponPolicy_CreateActivationCode_DataFragmentDoc = gql`
    fragment Coupon_CouponPolicy_CreateActivationCode_Data on Coupon {
  id
  key
}
    `;