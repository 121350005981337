import React from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  forwardRef,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

type Props = TextareaProps &
  UseFormRegisterReturn & {
    label?: string
    errorMessage?: string
    isRequired?: boolean
    labelProps?: React.ComponentProps<typeof FormLabel>
  }

const Component = forwardRef<Props, 'textarea'>(
  ({ label, errorMessage, isRequired, labelProps = {}, ...rest }, ref) => (
    <FormControl isInvalid={errorMessage !== undefined} isRequired={isRequired}>
      {label !== undefined && (
        <Flex justify="space-between">
          <FormLabel {...labelProps}>{label}</FormLabel>
        </Flex>
      )}
      <Textarea {...rest} ref={ref} />
      {errorMessage !== undefined && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  ),
)

export default Component
