import { UserRoleName } from 'gen/graphql-type'
import { Userable_All_RolesFragment } from './query.gen-graphql'

export type Authorize<U, O extends Record<string, unknown> | undefined = undefined> = {
  (user: U | undefined, object: O extends Record<string, unknown> ? O : void): boolean
}

export const authorize = (
  user: Userable_All_RolesFragment | undefined,
  allowedRoles: UserRoleName[],
) => {
  if (!user) return false

  const userRoles = user.allRoles.map(item => item.name)

  return allowedRoles.some(r => userRoles.includes(r))
}
