import { useRouter } from 'next/router'
import Link from '../../Link'

type ContainerProps = React.PropsWithChildren<{
  href: string
  isActive?: boolean
}>

type Props = {
  isActive: boolean
} & Omit<ContainerProps, 'isActive'>

const Component: React.FC<Props> = ({ href, isActive, children }) => (
  <Link
    aria-current={isActive ? 'page' : undefined}
    href={href}
    justifyContent="flex-start"
    fontSize="md"
    py={2}
    px={3}
    borderRadius={6}
    color="white"
    bgColor="blue.600"
    _hover={{ bg: 'blue.500' }}
    _activeLink={{
      bg: 'blue.700',
      fontWeight: '600',
    }}
  >
    {children}
  </Link>
)

const Container: React.FC<ContainerProps> = ({ href, isActive: isActiveProp, children }) => {
  const { asPath } = useRouter()

  let isActive: boolean
  if (isActiveProp !== undefined) {
    isActive = isActiveProp
  } else if (href === '/') {
    isActive = asPath === '/'
  } else {
    isActive = asPath.startsWith(href)
  }

  return <Component {...{ href, isActive, children }} />
}

export default Container
