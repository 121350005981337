/*
 * Chakra UIのButtonの見た目でNext.jsのLinkとして機能するコンポーネント
 */
import { ComponentProps, PropsWithChildren } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import Link from '../Link'

type LinkProps = ComponentProps<typeof Link>

type Props = Pick<LinkProps, 'href' | 'target'> & ButtonProps

const Component: React.FC<PropsWithChildren<Props>> = ({
  children,
  variant,
  href,
  target,
  ...buttonProps
}) => {
  return (
    <Link textDecor="none" {...{ href, target }}>
      <Button as="span" variant={variant} {...buttonProps}>
        {children}
      </Button>
    </Link>
  )
}

export default Component
