import React from 'react'
import { EXAMINATION_LIST } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebar } from 'policies/ExaminationPolicy'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'

type Props = ReturnType<typeof useQuery>

export const ExaminationMenuItem: React.FC<Props> = ({ viewer }) => {
  return (
    <SectionGrant authorized={canShowSidebar(viewer)}>
      <MenuItem href={EXAMINATION_LIST}>診察一覧</MenuItem>
    </SectionGrant>
  )
}
