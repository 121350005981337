import { usePageContainerSidebarQuery } from './query.gen-graphql'

export const useQuery = () => {
  const [{ data }] = usePageContainerSidebarQuery()

  const viewer = data?.viewer.__typename === 'Admin' ? data.viewer : undefined

  return {
    viewer,
  }
}
