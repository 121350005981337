import { UserRoleName } from 'gen/graphql-type'
import { Authorize, authorize } from '../authorize'
import {
  User_ExaminationTerminationReasonPolicy_CreateFragment,
  User_ExaminationTerminationReasonPolicy_UpdateFragment,
  User_ExaminationTerminationReasonPolicy_ShowFragment,
  User_ExaminationTerminationReasonPolicy_ShowSidebarFragment,
} from './query.gen-graphql'

export const canCreate: Authorize<
  User_ExaminationTerminationReasonPolicy_CreateFragment
> = user => {
  return authorize(user, [
    UserRoleName.SystemOperator,
    UserRoleName.Developer,
    UserRoleName.Operator,
  ])
}

export const canUpdate: Authorize<
  User_ExaminationTerminationReasonPolicy_UpdateFragment
> = user => {
  return authorize(user, [
    UserRoleName.SystemOperator,
    UserRoleName.Developer,
    UserRoleName.Operator,
  ])
}

export const canShow: Authorize<User_ExaminationTerminationReasonPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowSidebar: Authorize<
  User_ExaminationTerminationReasonPolicy_ShowSidebarFragment
> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}
