import React from 'react'
import { EXAMINATION_APPOINTMENT_LIST } from 'constants/path'
import { SectionGrant } from 'components/functional'
import { canShowSidebar } from 'policies/ExaminationAppointmentPolicy'
import MenuItem from '../MenuItem'
import { useQuery } from '../useQuery'
import { useRouter } from 'next/router'

type Props = ReturnType<typeof useQuery>

export const ExaminationAppointmentMenuItem: React.FC<Props> = ({ viewer }) => {
  const { asPath } = useRouter()

  /*
    MEMO: 予約一覧は /examination_appointments か /examination_appointments/[id] か /examination_appointments/[id]/[[...path]] の場合にアクティブになる
    URLにSearchParamsが含まれている場合もアクティブになる（?だけ残る場合があるので`(\\?.*)?`としている)
   */
  const isActive = new RegExp(`^${EXAMINATION_APPOINTMENT_LIST}(\\/[0-9]+(\\/.+)?)?(\\?.*)?$`).test(
    asPath,
  )

  return (
    <SectionGrant authorized={canShowSidebar(viewer)}>
      <MenuItem href={EXAMINATION_APPOINTMENT_LIST} isActive={isActive}>
        予約一覧
      </MenuItem>
    </SectionGrant>
  )
}
