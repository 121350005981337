import { InquiryCategoryConsultantType, UserRoleName } from 'gen/graphql-type'
import {
  User_InquiryCategoryPolicy_CreateFragment,
  User_InquiryCategoryPolicy_CreateFrequentlyAskedQuestionFragment,
  User_InquiryCategoryPolicy_UpdateFragment,
  InquiryCategory_InquiryCategoryPolicy_Update_DataFragment,
  User_InquiryCategoryPolicy_DestroyFragment,
  InquiryCategory_InquiryCategoryPolicy_Destroy_DataFragment,
  InquiryCategory_InquiryCategoryPolicy_CreateFrequentlyAskedQuestion_DataFragment,
  User_InquiryCategoryPolicy_ShowFragment,
  User_InquiryCategoryPolicy_ShowSidebarFragment,
} from './query.gen-graphql'
import { Authorize, authorize } from '../authorize'

export const canCreate: Authorize<User_InquiryCategoryPolicy_CreateFragment> = user => {
  return authorize(user, [
    UserRoleName.SystemOperator,
    UserRoleName.Operator,
    UserRoleName.Pharmacist,
  ])
}

export const canUpdate: Authorize<
  User_InquiryCategoryPolicy_UpdateFragment,
  InquiryCategory_InquiryCategoryPolicy_Update_DataFragment
> = (user, data) => {
  if (user === undefined) return false

  const { consultantType } = data
  const { SystemOperator, Operator, Pharmacist } = UserRoleName
  const { CustomerSupport, MedicalConsultation } = InquiryCategoryConsultantType

  const roles = user.allRoles.map(item => item.name)

  const isUserSystemOperator = roles.includes(SystemOperator)
  const canUpdateAsOperator = consultantType === CustomerSupport && roles.includes(Operator)
  const canUpdateAsPharmacist = consultantType === MedicalConsultation && roles.includes(Pharmacist)

  if (isUserSystemOperator || canUpdateAsOperator || canUpdateAsPharmacist) {
    return true
  }

  return false
}

export const canDestroy: Authorize<
  User_InquiryCategoryPolicy_DestroyFragment,
  InquiryCategory_InquiryCategoryPolicy_Destroy_DataFragment
> = (user, data) => {
  if (user === undefined) return false

  const { consultantType } = data
  const { SystemOperator, Operator, Pharmacist } = UserRoleName
  const { CustomerSupport, MedicalConsultation } = InquiryCategoryConsultantType

  const roles = user.allRoles.map(item => item.name)

  const isUserSystemOperator = roles.includes(SystemOperator)
  const canDestroyAsOperator = consultantType === CustomerSupport && roles.includes(Operator)
  const canDestroyAsPharmacist =
    consultantType === MedicalConsultation && roles.includes(Pharmacist)

  if (isUserSystemOperator || canDestroyAsOperator || canDestroyAsPharmacist) {
    return true
  }

  return false
}

export const canCreateFrequentlyAskedQuestion: Authorize<
  User_InquiryCategoryPolicy_CreateFrequentlyAskedQuestionFragment,
  InquiryCategory_InquiryCategoryPolicy_CreateFrequentlyAskedQuestion_DataFragment
> = (user, inquiryCategory) => {
  if (user === undefined) return false

  const { consultantType } = inquiryCategory
  const { SystemOperator, Operator, Pharmacist } = UserRoleName
  const { CustomerSupport, MedicalConsultation } = InquiryCategoryConsultantType

  const roles = user.allRoles.map(item => item.name)

  const isUserSystemOperator = roles.includes(SystemOperator)
  const canCreateAsOperator = consultantType === CustomerSupport && roles.includes(Operator)
  const canCreateAsPharmacist = consultantType === MedicalConsultation && roles.includes(Pharmacist)

  if (isUserSystemOperator || canCreateAsOperator || canCreateAsPharmacist) {
    return true
  }

  return false
}

export const canShow: Authorize<User_InquiryCategoryPolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}

export const canShowSidebar: Authorize<User_InquiryCategoryPolicy_ShowSidebarFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Pharmacist,
    UserRoleName.Consignor,
    UserRoleName.DoctorManager,
    UserRoleName.Developer,
    UserRoleName.CouponManager,
  ])
}
