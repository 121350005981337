import { authorize, Authorize } from '../authorize'
import {
  User_DocWmsOrderCsvFilePolicy_ShowFragment,
  User_DocWmsOrderCsvFilePolicy_ShowSidebarFragment,
} from './fragment.gen-graphql'
import { UserRoleName } from '../../gen/graphql-type'

export const canShow: Authorize<User_DocWmsOrderCsvFilePolicy_ShowFragment> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Consignor,
    UserRoleName.DmhOperator,
  ])
}

export const canShowSidebar: Authorize<
  User_DocWmsOrderCsvFilePolicy_ShowSidebarFragment
> = user => {
  return authorize(user, [
    UserRoleName.Operator,
    UserRoleName.SystemOperator,
    UserRoleName.Consignor,
    UserRoleName.DmhOperator,
  ])
}
