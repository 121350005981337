import * as Types from '../../gen/graphql-type';

import gql from 'graphql-tag';
import { Userable_All_RolesFragmentDoc } from '../query.gen-graphql';
export type User_InterviewSheetPolicy_ShowFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export type User_InterviewSheetPolicy_ShowSidebarFragment = { allRoles: Array<{ id: string, name: Types.UserRoleName }> };

export const User_InterviewSheetPolicy_ShowFragmentDoc = gql`
    fragment User_InterviewSheetPolicy_Show on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;
export const User_InterviewSheetPolicy_ShowSidebarFragmentDoc = gql`
    fragment User_InterviewSheetPolicy_ShowSidebar on Admin {
  ...Userable_All_Roles
}
    ${Userable_All_RolesFragmentDoc}`;